import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CsvDialog from './CsvDialog';
import LoaderButton from './LoaderButton';
import DeviceContext from '../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: -2,
    },
  },
}));

export default function CsvButton({ serialNumber, isEmicon = false }: { serialNumber: string, isEmicon?: boolean }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    loading,
  } = useContext(DeviceContext);
  return (
    <>
      <div className={classes.buttonWrapper}>
        <LoaderButton
          text="↓ CSV"
          disabled={serialNumber === undefined || loading}
          onClick={() => setOpen(true)}
          noMargin
          isLoading={false}
          fullWidth
        />
      </div>
      <CsvDialog
        serialNumber={serialNumber}
        open={open}
        onClose={() => setOpen(false)}
        isEmicon={isEmicon}
      />

    </>
  );
}
