/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import API from '@aws-amplify/api';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { base64toFile } from '../../../utils/base64ToFile';
import DeleteAttachmentDialog from './DeleteAttachmentDialog';
import StatusSnackbar from './StatusSnackbar';

interface AttachmentProps {
  serialNumber: string;
  logbookTimestamp: number;
  attachment: string | null;
}
export default function Attachment({ serialNumber, logbookTimestamp, attachment }: AttachmentProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachmentButtonDisabled, setAttachmentButtonDisabled] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<any>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [attachmentFilename, setAttachmentFilename] = useState<string | null>(null);

  useEffect(() => {
    setAttachmentFilename(attachment);
  }, []);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  /* Einzigartigen Dateiname zurück zum Originalnamen konvertieren */
  function toOriginalFilename(uniqieFilename: string) {
    const filenameWithoutType = uniqieFilename.split('_')[0];
    const filetype = uniqieFilename.split('.')[1];
    return `${filenameWithoutType}.${filetype}`;
  }

  // Öffne den Datei-Browser, wenn "Hochladen / ändern" geklickt wird
  function uploadAttachmentButton() {
    /* Klick feuert nicht, wenn File schon gesetzt ist */
    if (fileInputRef.current) {
      if (fileInputRef.current?.value) {
        fileInputRef.current.value = '';
      }
      fileInputRef.current.click();
    }
    handleClose();
  }

  async function uploadAttachment(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile === null) {
      return;
    }
    if (selectedFile.size > 4000000) {
      setSnackbarStatus('error');
      setSnackbarMessage('Die Datei ist zu groß. Maximal zulässige Dateigröße: 4MB');
      setSnackbarOpen(true);
      return;
    }

    setAttachmentButtonDisabled(true);
    const fileArrayBuffer = await selectedFile.arrayBuffer();
    const fileBase64 = btoa(new Uint8Array(fileArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

    const uploadResponse = await API.post('emilog', 'uploadLogbookAttachment', {
      body: {
        serialNumber,
        logbookTimestamp,
        fileBase64,
        filename: selectedFile.name,
      },
    });

    setAttachmentButtonDisabled(false);

    if (uploadResponse.status !== 200) {
      setSnackbarStatus('error');
    } else {
      /* Hochgeladenen Dateinamen als State setzen */
      setAttachmentFilename(uploadResponse.attachmentFilename);
      setSnackbarStatus('success');
    }
    setSnackbarMessage(uploadResponse.message);
    setSnackbarOpen(true);
  }

  async function downloadAttachment() {
    setAttachmentButtonDisabled(true);
    const downloadResponse = await API.post('emilog', 'getLogbookAttachment', {
      body: {
        filename: attachmentFilename,
      },
    });

    setAttachmentButtonDisabled(false);

    if (downloadResponse.status !== 200) {
      setSnackbarStatus('error');
      setSnackbarMessage(downloadResponse.message);
      setSnackbarOpen(true);
      return;
    }

    const fileBlob = base64toFile(downloadResponse.contentString, downloadResponse.origialFilename);
    const url = URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileBlob.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    handleClose();
  }

  async function deleteAttachmentConfirm() {
    setDeleteDialogOpen(false);
    setAttachmentButtonDisabled(true);

    const deleteResponse = await API.post('emilog', 'deleteLogbookAttachment', {
      body: {
        serialNumber,
        logbookTimestamp,
        filename: attachmentFilename,
      },
    });

    setAttachmentButtonDisabled(false);

    if (deleteResponse.status !== 200) {
      setSnackbarStatus('error');
    } else {
      setSnackbarStatus('success');
      /* Kein Anhang mehr vorhanden */
      setAttachmentFilename(null);
    }
    setSnackbarMessage(deleteResponse.message);
    setSnackbarOpen(true);
  }

  return (
    <div>
      <Button
        style={{ minWidth: '200px' }}
        color="primary"
        variant={attachmentFilename !== null ? 'outlined' : 'contained'}
        onClick={handleClick}
        disabled={attachmentButtonDisabled}
      >
        {attachmentFilename !== null ? 'Anhang verwalten' : 'Anhang hinzufügen'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {attachmentFilename !== null ? ( // wenn Anhang existiert, dann hochlabar /änderbar machen
          <div key="dummy">
            <MenuItem disabled>{toOriginalFilename(attachmentFilename)}</MenuItem>
            <MenuItem onClick={downloadAttachment}>Herunterladen</MenuItem>
            <MenuItem onClick={() => setDeleteDialogOpen(true)}>Löschen</MenuItem>
          </div>
        ) : (
          <MenuItem onClick={uploadAttachmentButton}>Hochladen</MenuItem>
        )}
      </Menu>
      {/* Verstecktes Datei-Input-Element */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={uploadAttachment}
      />
      {attachmentFilename !== null ? ( // wenn Anhang existiert, dann hochlabar /änderbar machen
        <DeleteAttachmentDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          filename={attachmentFilename}
          deleteAttachmentConfirm={deleteAttachmentConfirm}
        />
      ) : (null)}

      <StatusSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        statusType={snackbarStatus}
        message={snackbarMessage}
      />

    </div>
  );
}
