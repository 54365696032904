import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';

import EmiconRoute from './emicon/components/EmiconRoute';
import EmilogRoute from './emilog/components/EmilogRoute';
import PublicRoute from './emilog/components/PublicRoute';
import { AuthProvider } from './emilog/context/AuthContext';
import { SearchProvider } from './emilog/context/SearchContext';
import theme from './theme';

import Login from './emilog/components/Login/Login';
import Dashboard from './emilog/components/Dashboard/Dashboard';
import CalUser from './emilog/components/CalUser/CalUser';
import EmiViewUser from './emilog/components/EmiViewUser/EmiViewUser';
import Setup from './emilog/components/Setup/Setup';
import Klassierung from './emilog/components/Klassierung/Klassierung';
import Einstellungen from './emilog/components/Einstellungen/Einstellungen';
import Error from './emilog/components/Error/Error';
import Visualisierung from './emilog/components/Visualisierung/Visualisierung';
import Gasarten from './emilog/components/Gasarten/Gasarten';
import Logbuch from './emilog/components/Logbuch/Logbuch';
import EmiconDashboard from './emicon/components/Dashboard/EmiconDashboard';
import EmiconSettings from './emicon/components/Setup/EmiconSettings';
import EmiconEinstellungen from './emicon/components/Einstellungen/EmiconEinstellungen';
import EmiconSupport from './emicon/components/Support/EmiconSupport';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <SearchProvider>
            <Switch>
              <PublicRoute exact path="/login" component={Login} />
              <PublicRoute exact path="/error" component={Error} />
              <EmilogRoute exact path="/viewNutzer" component={EmiViewUser} />
              <EmilogRoute exact path="/calNutzer" component={CalUser} />
              <EmilogRoute exact path="/setup" component={Setup} />
              <EmilogRoute exact path="/setup/:serialNumber" component={Setup} />
              <EmilogRoute exact path="/service" component={Einstellungen} />
              <EmilogRoute exact path="/service/:serialNumber" component={Einstellungen} />
              <EmilogRoute exact path="/visualisierung" component={Visualisierung} />
              <EmilogRoute exact path="/visualisierung/:serialNumber" component={Visualisierung} />
              <EmilogRoute exact path="/klassierung" component={Klassierung} />
              <EmilogRoute exact path="/klassierung/:serialNumber" component={Klassierung} />
              <EmilogRoute exact path="/gasarten" component={Gasarten} />
              <EmilogRoute exact path="/logbuch" component={Logbuch} />
              <EmilogRoute exact path="/logbuch/:serialNumber" component={Logbuch} />
              <EmilogRoute exact path="/" component={Dashboard} />
              <EmiconRoute exact path="/emicon" component={EmiconDashboard} />
              <EmiconRoute exact path="/emicon/visualisierung" component={Visualisierung} />
              <EmiconRoute exact path="/emicon/visualisierung/:serialNumber" component={Visualisierung} />
              <EmiconRoute exact path="/emicon/uebersicht" component={EmiconSettings} />
              <EmiconRoute exact path="/emicon/uebersicht/:serialNumber" component={EmiconSettings} />
              <EmiconRoute exact path="/emicon/service" component={EmiconEinstellungen} />
              <EmiconRoute exact path="/emicon/service/:serialNumber" component={EmiconEinstellungen} />
              <EmiconRoute exact path="/emicon/support" component={EmiconSupport} />
              <EmiconRoute exact path="/emicon/support/:serialNumber" component={EmiconSupport} />
              <EmiconRoute exact path="/emicon/logbuch" component={Logbuch} />
              <EmiconRoute exact path="/emicon/logbuch/:serialNumber" component={Logbuch} />
              <Redirect to="/" />
            </Switch>
          </SearchProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
