import { EmiconShadow } from "../../types/Shadow";

function returnDefaultEmiconShadow() {
  const defaultEmiconShadow: EmiconShadow = {
    NOx_Out_In_c: 0,
    NOx_Out_ppm_c: 0,
    Var_NOx_Out_ppmc: 0,
    Var_NOx_In_ppm: 0,
    status_co_sensor: '',
    status_nox_sensor: '',
    status_temp_sensor: '',
    status_druck_sensor: '',
    betriebsstunden: 0,
    betriebsstunden_aktuell: 0,
    ausfallsstunden: 0,
    ausfallstunden_aktuell: 0,
    gas_art: '',
    co_warn: 0,
    co_alarm: 0,
    nox_warn: 0,
    nox_alarm: 0,
    T_min: 0,
    T_max: 0,
    T_alarm: 0,
    o2_bezug: 0,
    status: '',
    Var_p_Air: 0,
    Var_p_UWS: 0,
    Var_V_UWS: 0,
    Var_Level: 0,
    Var_EnLoad: 0,
    Var_NOx_In_mgO2: 0,
    Var_O2_In_c: 0,
    Var_NOx_Out_mgO2: 0,
    Var_NOx_Out_ppm: 0,
    Var_O2_Out_c: 0,
    Var_p_DiffCat: 0,
    Var_T_Exhaust: 0,
    Var_NOx_Target_mgO2: 0,
    sSeriennummer: '',
    sVersion: '',
    MW_NOx_30Min_alt: 0,
    MW_Abgastemp_30Min_alt: 0,
    MW_CO_30Min_alt: 0,
    MW_NOx_24Std_alt: 0,
    MW_Abgastemp_24Std_alt: 0,
    MW_CO_24Std_alt: 0,
    rBetriebsstunden_Motor_Jahr: 0,
    iAusfallstunden_Jahr: 0,
    Var_EnPel: 0,
    Var_EnLoad_Min: 0,
    Var_V_UWS_Max: 0,
    Var_MassFrac_Urea: 0,
    sGasArt: 'Biogas',
    Var_co_warn: 0,
    Var_co_alarm: 0,
    Var_nox_warn: 0,
    Var_NoxIn_warn: 0,
    Var_nox_alarm: 0,
    Var_t_min: 0,
    Var_t_max: 0,
    Var_t_alarm: 0,
    Var_M_NOx_In: 0,
    Var_B_NOx_In: 0,
    Var_M_NOx_Out: 0,
    Var_B_NOx_Out: 0,
    Var_M_O2_In: 0,
    Var_B_O2_In: 0,
    Var_M_O2_Out: 0,
    Var_B_O2_Out: 0,
    Var_M_CO: 0,
    Var_PI_k: 0,
    Var_PI_t: 0,
    rBetriebsstunden_Dosierpumpe: 0,
    rBetriebsstunden_Motor: 0,
    rBetriebsstunden_Kompressor: 0,
    rBetriebsstunden_Motor_Monat: 0,
    rBetriebsstunden_Fuellstand: 0,
    rBetriebsstunden_Temperatur: 0,
    rBetriebsstunden_Diferenzdruck: 0,
    rBetriebsstunden_Druckluft: 0,
    rBetriebsstunden_HWL_Druck: 0,
    rBetriebsstunden_CO: 0,
    pLuftdruckMax: 0,
    pLuftdruckMin: 0,
    pHWLDruckMax: 0,
    pHWLDruckMin: 0,
    pTempAbgasMin: 0,
    pTempAbgasMax: 0,
    pNiveauMin: 0,
    pNiveauWarn: 0,
    pDiffdruckMax: 0,
    Var_NOxIn_alarm: 0,
    Var_RelO2: 0,
    sStatus: '',
    Dosierpumpe_An_Aus: false,
    Kompressor_An_Aus: false,
    Ventil_An_Aus: false,
    Aktiv_Passiv: false,
    status_CO_sensor: '',
    status_AdBlue_Drucksensor: '',
    status_Druckluftsensor: '',
    status_Fuellstandssensor: '',
    status_Motorlast: '',
    Var_NOx_In_ppmc: 0,
    NOxOutppmc: 0,
    Parametrierung_Modbus_aktivieren: false,
    LevelAdBlueMinCurrent: 0,
    LevelAdBlueDes: 0,
    LevelAdBlueMaxCurrent: 0,
    LevelAdBlueActCurrent: 0,
    EnginePowerMin: 0,
    EnginePowerMinCurrent: 0,
    EnginePowerMax: 0,
    EnginePowerMaxCurrent: 0,
    EnginePowerActCurrent: 0,
    Var_O2_In: 0,
    Var_O2_Out: 0,
    pLuftdruckMinDef: 0,
    pLuftdruckMaxDef: 0,
    pHWLDruckMinDef: 0,
    pHWLDruckMaxDef: 0,
    pTempAbgasMinDef: 0,
    pTempAbgasMaxDef: 0,
    pNiveauMinDef: 0,
    pNiveauWarnDef: 0,
    pDiffdruckMaxDef: 0,
    Var_NoxIn_warnDef: 0,
    NoxInPpmcSim: 0,
    NoxInPpmcSimSwitch: false,
    NoxOutPpmcSim: 0,
    NoxOutPpmcSimSwitch: false,
    O2InCSim: 0,
    O2InPpmCSimSwitch: false,
    O2OutCSim: 0,
    O2OutPpmCSimSwitch: false,
    Var_CO_ppmc: 0,
    COPpmCSim: 0,
    COPpmCSimSwitch: false,
    LevelAdBlueSim: 0,
    LevelAdBlueSimSwitch: false,
    CatDiffPSim: 0,
    CatDiffPSimSwitch: false,
    SuperchargPSim: 0,
    SuperchargPSimSwitch: false,
    AdBluePSim: 0,
    AdBluePSimSwitch: false,
    EngineLoadSim: 0,
    EngineLoadSimSwitch: false,
    CatTempSim: 0,
    CatTempSimSwitch: false,
    Pumpentest_Leistung: 0,
    Steuer_AdBlue_Pumpe: false,
    Steuer_Kompressor: false,
    Steuer_Spuelventil: false,
    CAN_BUS_Reset_Man: false,
    sSimOnOff: false,
    LevelAdBlueSaveMin: false,
    LevelAdBlueSaveMax: false,
    EnginePowerSaveMin: false,
    EnginePowerSaveMax: false,
    Motor_An_Aus: false,
  };

  return defaultEmiconShadow;
}

export default returnDefaultEmiconShadow;
