import React, { useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { blue } from '@material-ui/core/colors';

import { Emicon } from '../../../../types/Device';
import EmiconContext from '../../context/EmiconContext';
import convertDate from '../../../utils/convertDate';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { AvailableColumns } from '../../../../types/Interfaces';
import { roundShadowValue } from '../../../utils/roundShadowValue';

interface EmiconRowProps {
  data: Emicon
  columns: AvailableColumns
}

export default function EmiconRow({ data, columns }: EmiconRowProps) {
  const { setCurrentDevice, } = useContext(EmiconContext);

  function checkVals(ausfall: number | undefined, betrieb: number | undefined) {
    if (ausfall === null || ausfall === undefined) {
      ausfall = 0;
    }
    if (betrieb === null || betrieb === undefined) {
      betrieb = 0;
    }
    if (ausfall === 0 && betrieb === 0) {
      return '-';
    }
    return `${roundShadowValue((ausfall / betrieb) * 100, 2)} %`;
  }

  function handleLinkClick() {
    setCurrentDevice(data);
  }

  const useStyles = makeStyles(() => ({
    cellWidth: {
      minWidth: '20vh',
    },
    shorten: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '20vh',
      display: 'inline-block',
      minWidth: '20vh',
    },
    link: {
      textDecoration: 'inherit',
      color: blue[900],
    },

  }));

  const classes = useStyles();

  return (
    <TableRow
      tabIndex={-1}
      key={data.serialNumber}
    >
      <TableCell>
        <span style={{ position: 'relative' }}>
          <Link
            to={{ pathname: `/emicon/uebersicht/${data.serialNumber}` }}
            className={classes.link}
            onClick={handleLinkClick}
          >
            {data.serialNumber}
          </Link>
        </span>
      </TableCell>


      {columns.Motorennummer && (
        <TableCell>
          {data.Motorennummer || '-'}
        </TableCell>
      )}

      {/* <TableCell>
        {data.Betreiber || '-'}
          </TableCell> */}

      {columns.Betreiber && (
        <TableCell
          align="left"
          className={classes.cellWidth}
        >
          <Tooltip title={data.Betreiber || ''}>
            <span className={classes.shorten}>
              {data.Betreiber || 'unbekannt'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {/* <TableCell>
        {data.Adresse || '-'}
      </TableCell> */}

      {columns.Adresse && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={data.Adresse || ''}>
            <span className={classes.shorten}>
              {data.Adresse || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {/* <TableCell>
        {data.Auftrag || '-'}
      </TableCell> */}

      {columns.Auftrag && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={data.Auftrag || ''}>
            <span className={classes.shorten}>
              {data.Auftrag || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.Gasart && (
        <TableCell align="left">
          {data.Gasart || 'unbekannt'}
        </TableCell>
      )}

      {columns.status && (
        <TableCell align="left">
          {/* @ts-ignore */}
          {data.status === '---' ? '-' : data.status || '-'}
        </TableCell>
      )}

      {columns.Servicevertrag
        && (
          <TableCell>
            {data.Servicevertrag ? data.Servicevertrag : 'Kein Vertrag'}
          </TableCell>
        )}

      {columns.ServiceVertragsende && (
        <TableCell>
          {data.ServiceVertragsende ? convertDate(data.ServiceVertragsende) : '-'}
        </TableCell>
      )}

      {columns.AnlagenStatus && (
        data.AnlagenStatus === 'In_Bearbeitung' ? (
          <TableCell>
            In Bearbeitung
          </TableCell>
        ) : (
          <TableCell>
            -
          </TableCell>
        )
      )}

      {columns.ServiceGeplant && (
        <TableCell>
          {data.ServiceGeplant && data.ServiceGeplant > 0 ? convertDate(data.ServiceGeplant) : '-'}
        </TableCell>
      )}

      {columns.WartungGeplant && (
        <TableCell>
          {data.WartungGeplant && data.WartungGeplant > 0 ? convertDate(data.WartungGeplant) : '-'}
        </TableCell>
      )}

      {columns.letzterService && (
        <TableCell>
          {convertDate(data.letzterService) || '-'}
        </TableCell>
      )}

      {columns.letzteWartung && (
        <TableCell>
          {convertDate(data.letzteWartung) || '-'}
        </TableCell>
      )}

      {columns.Messtermin && (
        <TableCell>
          {convertDate(data.Messtermin) || '-'}
        </TableCell>
      )}

      {columns.Messtermin_Alt && (
        <TableCell>
          {convertDate(data.Messtermin_Alt) || '-'}
        </TableCell>
      )}

      {columns.ausfalltagePercent && (
        <TableCell>
          {checkVals(data.AusfalltageYear, data.BetriebstageYear)}
          {/* Funktion schreiben und bedingung auslagern um true false und wert zu rendern */}
          {/* {data.AusfallstundenYear && data.BetriebsstundenYear ? roundShadowValue((data.AusfallstundenYear / data.BetriebsstundenYear) * 100, 2) : '-'} */}
        </TableCell>
      )}

      {columns.ausfallstundenPercent && (
        <TableCell>
          {checkVals(data.AusfallstundenYear, data.BetriebsstundenYear)}
          {/* Funktion schreiben und bedingung auslagern um true false und wert zu rendern */}
          {/* {data.AusfallstundenYear && data.BetriebsstundenYear ? roundShadowValue((data.AusfallstundenYear / data.BetriebsstundenYear) * 100, 2) : '-'} */}
        </TableCell>
      )}

      {columns.ausfallstunden && (
        <TableCell>
          {data.AusfallstundenYear}
        </TableCell>
      )}

      {columns.DaysWithoutData && (
        <TableCell>
          {data.DaysWithoutData}
        </TableCell>
      )}

      {columns.SoftwareVersion && (
        <TableCell>
          {data.SoftwareVersion}
        </TableCell>
      )}
    </TableRow>
  );
}
