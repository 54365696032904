import { useState } from 'react';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SetupHeader from '../../../emilog/components/SetupHeader';
import { useRouting } from '../../../utils/useRouting';
import EmiconLostDataUpload from './EmiconLostDataUpload';
import { EmiconShadow } from '../../../../types/Shadow';
import DataCardEmiconSupport from './DataCardEmiconSupport';
import ShadowUpdateButton from '../Setup/ShadowUpdateButton';
import ShadowGraphic from '../Visual/ShadowGraphic';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
  },
  container: {
    width: '85vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },

}));

export default function EmiconSupport() {
  const { currentDevice } = useRouting('Support', true);

  const [reported, setReported] = useState<Partial<EmiconShadow> | null>(null);
  const [updateReportedShadow, setUpdateReportedShadow] = useState<Partial<EmiconShadow> | null>(null);
  const [reportedTimestamp, setReportedTimestamp] = useState<number | null>(null);
  const [loadingShadow, setLoadingShadow] = useState(false);
  const [liveShadow, setLiveShadow] = useState<boolean>(false); // Livewerte Switch -> Wenn true reported in Edit Felder (+ Disabled)
  const [shouldFetchShadow, setShouldFetchShadow] = useState(false);
  const [useBlocker, setUseBlocker] = useState<boolean>(true); // Blockt useEffekte beim ersten Rendern

  const classes = useStyles();

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader isEmicon hasBearbeitung={true} />

      <Grid container spacing={4} style={{ marginTop: 0 }} alignContent="flex-start">
        <Grid item xs={12} lg={5} className={classes.expandingCard}>
          <DataCardEmiconSupport
            serialNumber={currentDevice?.serialNumber}
            active={typeof currentDevice?.serialNumber !== 'undefined'}
            isEmicon={true}
            device={currentDevice}
            updateReportedShadow={liveShadow ? reported : updateReportedShadow}
            setUpdateReportedShadow={setUpdateReportedShadow}
            liveShadow={liveShadow}
            setShouldFetchShadow={setShouldFetchShadow}
            useBlocker={useBlocker}
            setUseBlocker={setUseBlocker}
          />
        </Grid>
        <Grid item xs={12} lg={7}>
          <ShadowUpdateButton
            serialNumber={currentDevice?.serialNumber}
            active={typeof currentDevice?.serialNumber !== 'undefined'}
            setReported={setReported}
            setReportedTimestamp={setReportedTimestamp}
            setLoadingShadow={setLoadingShadow}
            isSupportPage={true}
            setUpdateReportedShadow={setUpdateReportedShadow}
            liveShadow={liveShadow}
            setLiveShadow={setLiveShadow}
            shouldFetchShadow={shouldFetchShadow}
            setShouldFetchShadow={setShouldFetchShadow}
            useBlocker={useBlocker}
          />
          <ShadowGraphic reported={reported} reportedTimestamp={reportedTimestamp} />
          <EmiconLostDataUpload />
        </Grid>
      </Grid>

    </Container>
  );
}
