import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import API from '@aws-amplify/api';
import Container from '@material-ui/core/Container';
import {
  AddCircleOutline, Search as SearchIcon,
} from '@material-ui/icons';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/styles/makeStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Chip,
  InputAdornment, TableContainer, Typography, useMediaQuery,
} from '@material-ui/core';
import AddLogbookEntry from './AddLogbookEntry';
import LogbuchTableHead from './LogbuchTableHead';
import convertTimestamp from '../../../utils/convertTimestamp';
import { useRouting } from '../../../utils/useRouting';
import SetupHeader from '../SetupHeader';
import { sortData, getSorting } from '../../../utils/sortTable';
import Attachment from './Attachment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles(() => ({

}));

interface LogbookEntry {
  author: string
  timestamp: number
  von: number;
  bis: number;
  message: string
  attachment: any
  WartungAbgeschlossen?: boolean
}

function renderMultilineString(multilineString: string): JSX.Element {
  const lines = multilineString.split('\n');
  if (lines.length < 1) {
    return (
      <div>
        {multilineString}
      </div>
    );
  }
  return (
    <div>
      {lines.map((line) => (
        <div key={line}>{line}</div>
      ))}
    </div>
  );
}

export default function Logbuch({ isEmicon = false }: { isEmicon?: boolean }) {
  // const classes = useStyles();
  const mobile = useMediaQuery('(max-width:600px)');
  const { currentDevice, loading } = useRouting('Logbuch', isEmicon);
  const active = currentDevice.serialNumber !== undefined;

  const [fetchingData, setFetchingData] = useState(true);

  const [tableData, setTableData] = useState<LogbookEntry[]>([]);
  const [addingNewEntry, setAddingNewEntry] = useState(false);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('date');

  const [filter, setFilter] = useState('');
  const [filteredTableData, setFilteredTableData] = useState<LogbookEntry[]>([]);

  // const [shouldSendMail, setShouldSendMail] = useState(false);

  const fetch = useCallback(async () => {
    setFetchingData(true);
    const res = await API.get('emilog', 'getLogbookEntries', {
      queryStringParameters: {
        serialNumber: currentDevice.serialNumber,
      },
    }) as LogbookEntry[];
    setFetchingData(false);
    setTableData(res);
    // setFilteredTableData(
    //   res.filter((entry) => entry.message.includes('Test')),
    // );
    setFilteredTableData(res);
  }, [currentDevice.serialNumber]);

  useEffect(() => {
    setFilter('');
    setAddingNewEntry(false);
    if (currentDevice.serialNumber) {
      fetch();
    } else {
      setFetchingData(false);
      setTableData([]);
      setFilteredTableData([]);
    }
  }, [currentDevice, fetch]);

  function handleAddClick() {
    setAddingNewEntry(true);
  }

  const handleRequestSort = (_event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    setFilter(event.target.value);
    if (event.target.value === '') {
      setFilteredTableData(tableData);
    } else {
      setFilteredTableData(
        tableData.filter(
          (entry) => entry.message.toLowerCase().includes(event.target.value.toLowerCase())
            || entry.author.toLowerCase().includes(event.target.value.toLowerCase()),
        ),
      );
    }
  }

  return (
    <Container maxWidth="xl">
      <SetupHeader hideButtons showBetreiber isEmicon={isEmicon} />
      <Paper style={{ marginTop: 16 }}>
        <div style={{
          display: mobile ? 'block' : 'flex', justifyContent: 'space-between', alignItems: 'flex-end', padding: 16, paddingTop: 16,
        }}
        >
          <Button
            variant="text"
            startIcon={<AddCircleOutline style={{ marginLeft: 4 }} />}
            onClick={handleAddClick}
            disabled={addingNewEntry || loading || fetchingData || !active}
          >
            Eintrag hinzufügen
          </Button>
          <TextField
            style={{ marginLeft: mobile ? 8 : 16 }}
            placeholder="Suchen"
            disabled={loading || fetchingData || !active}
            onChange={handleSearch}
            value={filter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TableContainer style={{ overflowY: 'auto', height: '75vh' }}>
          <Table
            stickyHeader
          >
            <colgroup>
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '45%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <LogbuchTableHead
              orderBy={orderBy}
              order={order}
              handleRequestSort={handleRequestSort}
              // shouldSendMail={shouldSendMail}
              // setShouldSendMail={setShouldSendMail}
              addingNewEntry={addingNewEntry}
            />
            <TableBody>
              {addingNewEntry ? (
                <AddLogbookEntry
                  fetch={fetch}
                  serialNumber={currentDevice?.serialNumber}
                  setAddingNewEntry={setAddingNewEntry}
                />
              ) : (
                null
              )}
              {(fetchingData || loading) ? (
                [...Array(3)].map((_i, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={`placeholder-${index}`}>
                    <TableCell colSpan={10}>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                (tableData.length < 1 && currentDevice.serialNumber) ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography>Noch keine Einträge vorhanden</Typography>
                    </TableCell>
                  </TableRow>
                ) : (

                  sortData(filteredTableData, getSorting(order, orderBy))?.map((entry: LogbookEntry) => (
                    <TableRow key={entry.timestamp}>
                      {entry.von && entry.bis > Math.round(Date.now() / 1000) ? ( // Eintrag aus Service-Tabelle mit Von, Bis Zeitangaben
                        <TableCell style={{ verticalAlign: 'baseline' }}>
                          <Chip
                            size="small"
                            style={{ marginRight: '8px', color: 'white', background: 'green' }}
                            label="geplant"
                          />
                          {`${convertTimestamp(entry.von).split(',')[0]} bis ${convertTimestamp(entry.bis).split(',')[0]}`}
                        </TableCell>
                      ) : entry.von && entry.bis ? (
                        <TableCell style={{ verticalAlign: 'baseline' }}>
                          {`${convertTimestamp(entry.von).split(',')[0]} bis ${convertTimestamp(entry.bis).split(',')[0]}`}
                        </TableCell>
                      ) : entry.WartungAbgeschlossen ? ( // Statt hasServiceChecked
                        <TableCell style={{ verticalAlign: 'baseline' }}>
                          <Chip
                            size="small"
                            style={{ marginRight: '8px', color: 'white', background: 'blue' }}
                            label="abgeschlossen"
                          />
                          {convertTimestamp(entry.timestamp)}
                        </TableCell>
                      ) : (
                        <TableCell style={{ verticalAlign: 'baseline' }}>
                          {convertTimestamp(entry.timestamp)}
                        </TableCell>
                      )}

                      {/* <TableCell style={{ verticalAlign: 'baseline' }}>
                        {entry.von && entry.bis ? (
                          `${convertTimestamp(entry.von).split(',')[0]} bis ${convertTimestamp(entry.bis).split(',')[0]}`
                        ) : (
                          convertTimestamp(entry.timestamp)
                          
                        )
                        }
                      </TableCell> */}
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        {entry.author}
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        {renderMultilineString(entry.message)}
                        {/* {entry.bis < Math.round(Date.now() / 1000) ? `(${entry.von} bis ${entry.bis}` : null} - Klappt nicht so gut */}
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'baseline' }}>
                        <Attachment serialNumber={currentDevice.serialNumber} logbookTimestamp={entry.timestamp} attachment={entry.attachment} />
                      </TableCell>
                    </TableRow>
                  ))
                )
              )}
            </TableBody>

          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
