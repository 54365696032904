import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import makeStyles from '@material-ui/styles/makeStyles';
// import createStyles from '@material-ui/styles/createStyles';

const tableHeaders = [
  {
    id: 'date',
    name: 'Datum',
    numeric: false,
  },
  {
    id: 'user',
    name: 'Nutzer',
    numeric: false,
  },
  {
    id: 'message',
    name: 'Notiz',
    numeric: false,
  },
  {
    id: 'attachment',
    name: 'Anhang',
    numeric: false,
  },
];

interface LogbuchTableHeadProps {
  order: SortingOrder
  orderBy: string
  handleRequestSort: (event: any, property: string) => void
  // setShouldSendMail: React.Dispatch<React.SetStateAction<boolean>>
  // shouldSendMail: boolean
  addingNewEntry: boolean
}

// const useStyles = makeStyles(() => createStyles({
//   checkboxLabel: {
//     fontSize: '1rem',
//   },
// }));

export default function LogbuchTableHead({
  orderBy,
  order,
  handleRequestSort,
  // shouldSendMail,
  // setShouldSendMail,
  addingNewEntry,
}: LogbuchTableHeadProps) {
  // const styles = useStyles();

  const createSortHandler = (property: string) => (event: any) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map((header) => (
          <TableCell key={header.id}>
            {header.id === 'message' && addingNewEntry ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <TableSortLabel active={orderBy === header.id} direction={order} onClick={createSortHandler(header.id)}>
                  {header.name}
                </TableSortLabel>
                {/* <FormControlLabel
                  style={{ fontSize: '1rem' }}
                  control={(
                    <Checkbox
                      name="checked"
                      color="primary"
                      value={shouldSendMail}
                      onChange={(event) => setShouldSendMail(event.target.checked)}
                      style={{ padding: '0px 4px' }}
                    />
                  )}
                  classes={{
                    label: styles.checkboxLabel,
                  }}
                  label="Service benachrichtigen"
                /> */}
              </div>
            ) : (
              <TableSortLabel active={orderBy === header.id} direction={order} onClick={createSortHandler(header.id)}>
                {header.name}
              </TableSortLabel>
            )}
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}
