/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import EmiconContext from '../../context/EmiconContext';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, ButtonBase, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DeckblattInfo, Emicon } from '../../../../types/Device';


const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
  cardContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  datePicker: {
    width: '100%',
    marginTop: '0px',
  },
  mb: {
    marginBottom: '8px',
  },
  mt: {
    marginTop: '8px',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  servicePlanned: {
    background: 'green',
    color: 'white',
  },
  serviceNotPlanned: {
    background: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  content: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  removePaddingLeft: {
    paddingLeft: '0px',
  }

}));

interface EmiconBerichtDeckblattInfoProps {
  isViewOnly?: boolean
  handleMenuOpen?: Function
  device: Emicon
  isEmicon?: boolean
}

export default function EmiconBerichtDeckblattInfo({ isViewOnly = false, handleMenuOpen, device, isEmicon = false }: EmiconBerichtDeckblattInfoProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deckblattInfo, setDeckblattInfo] = useState<DeckblattInfo>({
    BetreiberName: '',
    BetreiberStraße: '',
    BetreiberPostleitzahl: '',
    BetreiberOrt: '',
    AnlagenName: '',
    AnlagenStraße: '',
    AnlagenPostleitzahl: '',
    AnlagenOrt: '',
    BezeichnungAnlage: '',
    // BezeichnungAnlage1: '',
    // BezeichnungAnlage2: '',
    // BezeichnungAnlage3: '',
    Genehmigungsbehoerde: '',
    // Genehmigungsbehoerde1: '',
    // Genehmigungsbehoerde2: '',
    // Genehmigungsbehoerde3: '',
  });

  const active = device.serialNumber !== undefined;

  const { setCurrentDevice, fetchDevices } = useContext(EmiconContext);

  async function handleSubmit() {
    try {
      setError(false);
      setSuccess(false);
      setLoading(true);

      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber: device.serialNumber,
          isEmicon, // ACHTUNG
          fields: {
            DeckblattInfo: deckblattInfo,
          },
        },
      });

      setCurrentDevice((prevState: Emicon) => ({
        ...prevState,
        DeckblattInfo: deckblattInfo
      }));

      fetchDevices();

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (device.serialNumber === null || device.serialNumber === undefined) return;
    if (device.DeckblattInfo && device.DeckblattInfo !== null || device.DeckblattInfo !== undefined) {
      setDeckblattInfo(device?.DeckblattInfo);
    }
    // getDeckblattInfo()
    // setSuccess(false);
  }, [device.serialNumber]);

  return (
    <Card
      style={{ minHeight: '100%', minWidth: !isEmicon ? '100%' : undefined }}
    >
      {handleMenuOpen ? (
        <CardHeader
          title={
            <div className={classes.cardHeader}>
              {/* @ts-ignore */}
              <ButtonBase onClick={handleMenuOpen}>
                <Typography variant="h5">Deckblatt Informationen</Typography>
                <KeyboardArrowDownIcon />
              </ButtonBase>
            </div>
          }
          style={{ position: 'relative' }}
        />
      ) : (
        <CardHeader
          title="Deckblatt Informationen"
        />
      )}
      <Divider />
      <CardContent className={classes.cardContent}>
        {active ? (
          <Box
            maxHeight={isViewOnly ? '660px' : null}
          // minHeight={'100%'}
          >

            {/* Betreiberinfos */}

            <Typography variant="h5" className={classes.mt}>Betreiber Informationen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6} >
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberName || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberName: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Straße"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberStraße || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberStraße: e.target.value })}
                  className={classes.mb}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Postleitzahl"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={deckblattInfo.BetreiberPostleitzahl || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberPostleitzahl: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Ort"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.BetreiberOrt || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BetreiberOrt: e.target.value })}
                  className={classes.mb}
                />
              </Grid>
            </Grid>

            {/* Anlageninfos */}

            <Typography variant="h5" className={classes.mt}>Anlagen Informationen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6}>
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenName || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenName: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Straße"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenStraße || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenStraße: e.target.value })}
                  className={classes.mb}
                />
              </Grid>

              <Grid item lg={6}>
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Postleitzahl"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={deckblattInfo.AnlagenPostleitzahl || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenPostleitzahl: e.target.value })}
                  className={classes.mb}
                />
                <TextField
                  disabled={isViewOnly}
                  size="small"
                  label="Ort"
                  variant="outlined"
                  fullWidth
                  type="text"
                  value={deckblattInfo.AnlagenOrt || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, AnlagenOrt: e.target.value })}
                  className={classes.mb}
                />
              </Grid>
            </Grid>

            {/* Anlagenbezeichnung */}

            <Typography variant="h5" className={classes.mt}>Anlagen Bezeichnungen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Bezeichnung"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={3}
                  variant="outlined"
                  value={deckblattInfo.BezeichnungAnlage || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, BezeichnungAnlage: e.target.value })}
                />
              </Grid>
            </Grid>

            {/* Genehmigungsbehöre */}

            <Typography variant="h5" className={classes.mt}>Genehmigungsbehörde</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12} >
                <TextField
                  id="outlined-multiline-static"
                  label="Info"
                  multiline
                  fullWidth
                  minRows={3}
                  maxRows={3}
                  variant="outlined"
                  value={deckblattInfo.Genehmigungsbehoerde || ''}
                  onChange={(e) => setDeckblattInfo({ ...deckblattInfo, Genehmigungsbehoerde: e.target.value })}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box maxHeight={isViewOnly ? '660px' : null}>

            {/* Anlageninfos Skeleton */}

            <Typography variant="h5" className={classes.mt}>Betreiber Informationen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6} >
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>

              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Anlageninfos Skeleton */}

            <Typography variant="h5" className={classes.mt}>Anlagen Informationen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>

              <Grid item lg={6}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Anlagenbezeichnung Skeleton */}

            <Typography variant="h5" className={classes.mt}>Anlagenbezeichnungen</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>

            {/* Genehmigungsbehöre Skeleton */}

            <Typography variant="h5" className={classes.mt}>Genehmigungsbehörde</Typography>

            <Grid container spacing={2} className={classes.content} justifyContent="center">
              <Grid item lg={12}>
                <Skeleton variant="text" />
              </Grid>
            </Grid>
          </Box>
        )
        }
      </CardContent >
      {isViewOnly ? (
        null
      ) : (
        <>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Feedback error={error} success={success} />
            <LoaderButton
              isLoading={loading}
              text="Absenden"
              onClick={handleSubmit}
              disabled={!active}
            />
          </CardActions>
        </>
      )}
    </Card >
  );
}
