/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';

import convertDate from '../../../utils/convertDate';
import AuthContext from '../../context/AuthContext';
import capitalize from '../../../utils/capitalize';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    flex: 1,
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const timeframe = 320 * 24 * 60 * 60; // Timeframe in seconds

interface WartungProps {
  hasWartungPlanned: boolean | null
  lastWartungPlannedDate: number | null
  serialNumber: string;
  active: boolean;
}

// Ersetz Wartung.js

export default function WartungAbgeschlossen({ serialNumber, active, hasWartungPlanned, lastWartungPlannedDate }: WartungProps) {
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);
  const { fetchDevices } = useContext(DeviceContext);
  const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  const author = `${capitalize(firstName)} ${capitalize(lastName)}`;

  const [date, setDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [onlyOneEntry, setOnlyOneEntry] = useState(false);
  const [letzteWartung, setLetzteWartung] = useState<number | undefined>();
  const [notificationDate, setNotificationDate] = useState<number | null>(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);

      const timestamp = date ? Math.round(date.getTime() / 1000) : undefined;
      await API.post('emilog', 'addWartung', {
        body: {
          serialNumber,
          timestamp: timestamp ?? null,
        },
      });

      /* Schließt Wartung ab, indem der Timestamp auf 0 gesetzt wird */
      if (hasWartungPlanned) {
        await API.post('emilog', 'updateInfos', {
          body: {
            serialNumber,
            isEmicon: false, // ACHTUNG
            fields: {
              WartungGeplant: 0, // Aktuelle Wartung
              WartungGeplant_Alt: lastWartungPlannedDate, // Vorherige Wartung
              letzteWartung: timestamp, // Abschluss der vorherigen bzw. noch aktuellen wartung Wartung
            },
          },
        });

        let message = '';

        if (lastWartungPlannedDate) {
          message = `Wartung abgeschlossen (${timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin der Wartung: ${convertDate(lastWartungPlannedDate)}`;
        } else {
          message = `Wartung abgeschlossen (${timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin nicht verfügbar`;
        }

        await API.post('emilog', 'addLogbookEntry', {
          body: {
            serialNumber: serialNumber,
            author,
            messageContent: message,
            WartungAbgeschlossen: true,
          },
        });
      }
      fetchDevices();
      setOnlyOneEntry(false);
      setLetzteWartung(timestamp);
      setNotificationDate(null);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await API.get('emilog', 'getWartungen', { queryStringParameters: { serialNumber } });
        setOnlyOneEntry(res.singleEntry ?? false);
        setLetzteWartung(res.Zeit);
        setNotificationDate(res.Meldung);
      } catch (err) {
        console.error(err);
      }
    };
    if (active) {
      fetch();
    }
  }, [active, serialNumber]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.card}>
        <CardHeader title="Wartung abschließen" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <KeyboardDatePicker
                value={date}
                className={classes.datePicker}
                disabled={!active}
                disableToolbar
                autoOk
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Durchgeführte Wartung"
                onChange={(e) => setDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Letzte Wartung:
                {letzteWartung && (
                  <>
                    {' '}
                    {convertDate(letzteWartung)}
                    {' '}
                    {onlyOneEntry && '(Instandsetzung)'}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Benachrichtigung versendet:
                {notificationDate ? (
                  ` Ja (am ${convertDate(notificationDate)})`
                ) : notificationDate === null ? (
                  ''
                ) : (
                  ` Nein (fällig am ${convertDate((letzteWartung ?? 0) + timeframe)})`
                )}
              </Typography>
            </Grid>
            {(hasWartungPlanned && hasWartungPlanned === true) && (
              <Grid item xs={12}>
                <Typography>
                  Aktuell geplante Wartung bis zum: {lastWartungPlannedDate !== null ? convertDate(lastWartungPlannedDate) : ''}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Feedback error={error} success={success} />
          <LoaderButton
            isLoading={loading}
            text="Wartung eintragen"
            onClick={handleSubmit}
            disabled={!active || !date || !hasWartungPlanned }
          />
        </CardActions>
      </Card>
    </MuiPickersUtilsProvider>
  );
};
