import { roundShadowValue } from "./roundShadowValue";

function checkVals(ausfallstunden: number | undefined, betriebsstunden: number | undefined): string {
  if (ausfallstunden === null || ausfallstunden === undefined) {
    ausfallstunden = 0;
  }
  if (betriebsstunden === null || betriebsstunden === undefined) {
    betriebsstunden = 0;
  }
  if (ausfallstunden === 0 && betriebsstunden === 0) {
    return '-';
  }
  return roundShadowValue((ausfallstunden / betriebsstunden) * 100, 2).toString();
}

function desc(a: any, b: any, orderBy: string) {
  /* Hässlicher Workaround um nach ausfallstundenPercent (Number bzw. String) filtern zu können */
  if (orderBy === 'ausfallstundenPercent' || orderBy === 'ausfalltagePercent') {
    let aValue = '-';
    let bValue = '-';
    if (orderBy === 'ausfallstundenPercent') {
      aValue = checkVals(a.AusfallstundenYear, a.BetriebsstundenYear)
      bValue = checkVals(b.AusfallstundenYear, b.BetriebsstundenYear)
    } else if (orderBy === 'ausfalltagePercent') {
      aValue = checkVals(a.AusfalltageYear, a.BetriebstageYear)
      bValue = checkVals(b.AusfalltageYear, b.BetriebstageYear)
    }

    // Parse values as numbers before comparison
    const aValueParsed = aValue === '-' ? -1 : parseFloat(aValue);
    const bValueParsed = bValue === '-' ? -1 : parseFloat(bValue);

    if (bValueParsed < aValueParsed || isNaN(bValueParsed)) {
      return -1;
    }
    if (bValueParsed > aValueParsed || isNaN(aValueParsed)) {
      return 1;
    }
  }
  else {
    if (b[orderBy] < a[orderBy] || b[orderBy] === undefined) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] === undefined) {
      return 1;
    }
  }
  return 0;
}

function sortData(array: Array<any> | undefined, cmp: Function) {
  if (array === null || array === undefined) return [];
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function simpleSort(array: Array<any>, cmp: Function) {
  if (array === null) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order: SortingOrder, orderBy: string) {
  return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
}

// GPT

function getSortingAlphabetically<T>(order: SortingOrder, orderBy: keyof T): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const valA = String(a[orderBy]).toLowerCase();
  const valB = String(b[orderBy]).toLowerCase();

  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}

function sortDataAlphabetically<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export {
  desc, sortData, simpleSort, getSorting, getSortingAlphabetically, sortDataAlphabetically
};
