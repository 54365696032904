/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
// import Collapse from '@material-ui/core/Collapse';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import {
  green, grey, red, orange, yellow,
} from '@material-ui/core/colors';
import Popover from '@material-ui/core/Popover';
import ButtonBase from '@material-ui/core/ButtonBase';

import { Theme } from '@material-ui/core';
import convertTimestamp from '../../../utils/convertTimestamp';
import capitalize from '../../../utils/capitalize';

const useStyles = makeStyles((theme: Theme) => ({
  statusChip: {
    display: 'inline-flex',
    borderRadius: 20,
    padding: '5px 14px',
  },
  statusText: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 3,
    fontSize: '0.9rem',
  },
  popoverText: {
    padding: theme.spacing(1),
  },
}));

interface StatusChipProps {
  status?: string | null
  timestamp?: number | null
  loading: boolean
}

function StatusChip({ status = 'unbekannt', timestamp = null, loading } : StatusChipProps) {
  const classes = useStyles();

  const [timeAnchor, setTimeAnchor] = useState(null);

  const timeOpen = Boolean(timeAnchor);

  const handleTimeOpen = (event: any) => {
    setTimeAnchor(event.currentTarget);
  };

  const handleTimeClose = () => {
    setTimeAnchor(null);
  };

  function renderChip() {
    if (!loading === true) {
      switch (status) {
        case 'offline':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: grey[600], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <CloudOffIcon />
              <Typography className={classes.statusText}>
                {capitalize(status)}
              </Typography>
            </ButtonBase>
          );
        case 'aktiv':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: green[900], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <CloudDoneIcon />
              <Typography className={classes.statusText}>
                {capitalize(status)}
              </Typography>
            </ButtonBase>
          );
        case 'alarm':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: red[900], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <ErrorOutlineIcon />
              <Typography className={classes.statusText}>
                {capitalize(status)}
              </Typography>
            </ButtonBase>
          );
        case 'warnung':
        case 'warnung_1':
        case 'warnung_2':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: orange[900], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <WarningIcon />
              <Typography className={classes.statusText}>
                {capitalize(status)}
              </Typography>
            </ButtonBase>
          );
        case 'sensor_defekt':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: yellow[900], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <CancelIcon />
              <Typography className={classes.statusText}>
                Sensorstörung
              </Typography>
            </ButtonBase>
          );
        case 'passiv':
          return (
            <ButtonBase
              className={classes.statusChip}
              style={{ backgroundColor: grey[400], color: 'white' }}
              onClick={handleTimeOpen}
            >
              <PauseCircleOutlineIcon />
              <Typography className={classes.statusText}>
                {capitalize(status)}
              </Typography>
            </ButtonBase>
          );
        default:
          return null;
      }
    }
    return null;
  }
  return (
    <>
      {renderChip()}
      <Popover
        // id={id}
        open={timeOpen}
        anchorEl={timeAnchor}
        onClose={handleTimeClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={4}
      >
        <Typography className={classes.popoverText}>
          {convertTimestamp(timestamp)}
        </Typography>
      </Popover>

    </>
  );
}

export default React.memo(StatusChip);
