import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import API from '@aws-amplify/api';

import SetupHeader from '../SetupHeader';
import Chart from './Chart';
import { useRouting } from '../../../utils/useRouting';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '95vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90vw',
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  toggle: {
    padding: '6px 16px',
  },
  toggleGroup: {
    marginTop: -2,
    // marginBottom: 8,
    marginLeft: theme.spacing(10),
    backGroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: '80%',
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  selectField: {
    background: 'rgba(0, 0, 0, 0.09)',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingLeft: 10,
    width: 120,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

export default function Visualisierung({ isEmicon = false }: { isEmicon?: boolean }) {
  const classes = useStyles();

  const { currentDevice } = useRouting('Visualisierung', isEmicon);

  const [chartData, setChartData] = useState({
    coTMW: [],
    coHMW: [],
    noxTMW: [],
    noxHMW: [],
    tempTMW: [],
    tempHMW: [],
    noxAlarm: [],
    coAlarm: [],
    tempAlarm: [],
    tempMin: [],
    tempMax: [],
  });

  const [chartMode, setChartMode] = useState('all');
  const [noData, setNoData] = useState(false);

  const [days, setDays] = useState(30);
  const [valueMode, setValueMode] = useState('hmw');

  useEffect(() => {
    async function fetch() {
      try {
        setChartData({
          coTMW: [],
          coHMW: [],
          noxTMW: [],
          noxHMW: [],
          tempTMW: [],
          tempHMW: [],
          noxAlarm: [],
          coAlarm: [],
          tempAlarm: [],
          tempMin: [],
          tempMax: [],
        });
        if (typeof currentDevice?.serialNumber !== 'undefined') {
          setNoData(false);
          const res = await API.get('emilog', 'chartData', { queryStringParameters: { serialNumber: currentDevice.serialNumber, amountOfDays: days, isEmicon } });
          setChartData(res);
        }
      } catch (err) {
        console.log(err);
        setNoData(true);
      }
    }
    fetch();
  }, [currentDevice, days, isEmicon]);

  function handleChartToggle(event: any, newValue: string) {
    event.preventDefault();
    setChartMode(newValue);
  }

  function handleValueToggle(event: any, newValue: string) {
    event.preventDefault();
    setValueMode(newValue);
  }

  const renderToggle = () => (
    <ToggleButtonGroup
      exclusive
      value={chartMode}
      onChange={handleChartToggle}
      className={classes.toggleGroup}

    >
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="all"
      >
        Übersicht TMW
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="co"
      >
        CO
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="nox"
      >
        NOx
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="temp"
      >
        Temperatur
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const renderDaySelect = () => (
    <Select
      labelId="day-select-label"
      id="day-select"
      value={days}
      // variant="filled"
      onChange={(event) => setDays(event.target.value as number)}
      className={classes.selectField}
      renderValue={(value) => `${value} Tage`}
    >
      <MenuItem value={30}>30</MenuItem>
      <MenuItem value={60}>60</MenuItem>
      <MenuItem value={90}>90</MenuItem>
      <MenuItem value={120}>120</MenuItem>
      <MenuItem value={150}>150</MenuItem>
      <MenuItem value={180}>180</MenuItem>
      <MenuItem value={210}>210</MenuItem>
      <MenuItem value={240}>240</MenuItem>
      <MenuItem value={270}>270</MenuItem>
      <MenuItem value={300}>300</MenuItem>
      <MenuItem value={330}>330</MenuItem>
      <MenuItem value={360}>360</MenuItem>
    </Select>
  );

  const renderValueToggle = () => (
    <ToggleButtonGroup
      exclusive
      value={valueMode}
      onChange={handleValueToggle}
      className={classes.toggleGroup}
    >
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="hmw"
      >
        HMW
      </ToggleButton>
      <ToggleButton
        classes={{ root: classes.toggle }}
        value="tmw"
      >
        TMW
      </ToggleButton>

    </ToggleButtonGroup>
  );

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader hideButtons isEmicon={isEmicon}>

        <Grid item xs={12} md="auto">
          {renderToggle()}
        </Grid>
        <Grid item md="auto">
          {renderDaySelect()}
        </Grid>
        {chartMode !== 'all' ? (

          <Grid item md="auto">
            {renderValueToggle()}
          </Grid>
        ) : (
          <> </>
        )}

      </SetupHeader>
      <Grid container spacing={2} className={classes.content} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {noData ? (
              <Typography component="h6">Keine Messdaten in den letzten 30 Tagen aufgezeichnet</Typography>
            ) : (

              chartMode === 'all' ? (
                <Grid container spacing={2}>
                  <Grid item sm={12} lg={4}>
                    <Chart type="co" chartData={chartData?.coTMW} days={days} limits={chartData?.coAlarm} />
                  </Grid>
                  <Grid item sm={12} lg={4}>
                    <Chart type="nox" chartData={chartData?.noxTMW} days={days} limits={chartData?.noxAlarm} />
                  </Grid>
                  <Grid item sm={12} lg={4}>
                    <Chart type="temp" chartData={chartData?.tempTMW} days={days} limits={chartData?.tempAlarm} />
                  </Grid>
                </Grid>
              ) : chartMode === 'co' ? (
                <Chart type="co" chartData={valueMode === 'hmw' ? chartData.coHMW : chartData.coTMW} days={days} showHmw={valueMode === 'hmw'} limits={chartData?.coAlarm} fullSize />
              ) : chartMode === 'nox' ? (
                <Chart type="nox" chartData={valueMode === 'hmw' ? chartData.noxHMW : chartData.noxTMW} days={days} showHmw={valueMode === 'hmw'} limits={chartData?.noxAlarm} fullSize />
              ) : chartMode === 'temp' && (
                <Chart type="temp" chartData={valueMode === 'hmw' ? chartData.tempHMW : chartData.tempTMW} days={days} showHmw={valueMode === 'hmw'} limits={chartData?.tempAlarm} fullSize />
              )

            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
