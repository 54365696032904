import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CardContent, FormControl, InputLabel, LinearProgress, MenuItem, Select, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { API } from '@aws-amplify/api';
import useStateRef from 'react-usestateref';
import LoaderButton from '../../../emilog/components/LoaderButton';
import { EmiconShadow } from '../../../../types/Shadow';

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  categoryHeader: {
    fontWeight: 900,
  },

  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },

  shadowInput: {
    fontSize: '13px',
    margin: '2px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputForm: {
    fontSize: '13px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputSm: {
    width: '100px',
  },

  shadowInputXSm: {
    width: '115px',
  },

  shadowInputMd: {
    width: '150px',
  },

  shadowInputLg: {
    width: '175px',
  },

  shadowInputXl: {
    width: '200px',
  },

  shadowInputXxl: {
    width: '250px',
  },

  shadowInputDark: {
    background: '#014a69',
    color: 'white !important'
  },

  dropdown: {
    width: 150,
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -8,
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      fontSize: '13px',
      margin: '2px',
      height: '30px',
      paddingLeft: '8px !important',
      '&.Mui-disabled': {
        color: 'black',
      },
    },
  },

  select: {
    paddingLeft: 8,
  },

  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
    paddingLeft: '0px', // Linker Text unter Input Elementen
  },

  cardContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
  },

  boxCenter: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
  },

  marginTB: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  adornmentText: {
    '& .MuiTypography-root': {
      color: 'white',
    },
  },

}));

interface EmiconParameterwerteProps {
  serialNumber: string
  active: boolean
  handleMenuOpen: Function
  updateReportedShadow: Partial<EmiconShadow> | null
  updateShadow: Function
  updateReported: Function
  loading: boolean
  notification: string
  notificationSuccess: string
  handleBlurForceZero: Function
  liveShadow?: boolean
}

export default function EmiconParameterwerte({
  serialNumber, active, handleMenuOpen, updateReportedShadow, updateShadow, updateReported, loading, notification, notificationSuccess, handleBlurForceZero, liveShadow

}: EmiconParameterwerteProps) {

  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ maxHeight: 1000, minHeight: "100%" }}>

      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Parameterwerte</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      {serialNumber && active && updateReportedShadow && Object.keys(updateReportedShadow).length > 0 ? (
        <Box margin="16px 8px 16px 8px" maxHeight={660}>

          {/* Druckluft-Werte */}
          <Box>
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Parameter Werte"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Standard Werte"
              aria-describedby="outlined-weight-helper-text"
            />
          </Box>

          {/* Druckluft-Minimum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Druckluft Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pLuftdruckMin}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pLuftdruckMin')}
              onBlur={() => handleBlurForceZero('pLuftdruckMin')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pLuftdruckMinDef}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pLuftdruckMinDef')}
              onBlur={() => handleBlurForceZero('pLuftdruckMinDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* Druckluft-Maximum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Druckluft Maximum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pLuftdruckMax}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pLuftdruckMax')}
              onBlur={() => handleBlurForceZero('pLuftdruckMax')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pLuftdruckMaxDef}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pLuftdruckMaxDef')}
              onBlur={() => handleBlurForceZero('pLuftdruckMaxDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* HWL Druck Minimum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="HWL Druck Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pHWLDruckMin}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pHWLDruckMin')}
              onBlur={() => handleBlurForceZero('pHWLDruckMin')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pHWLDruckMinDef}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pHWLDruckMinDef')}
              onBlur={() => handleBlurForceZero('pHWLDruckMinDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* HWL Druck Maximum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="HWL Druck Maximum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pHWLDruckMax}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pHWLDruckMax')}
              onBlur={() => handleBlurForceZero('pHWLDruckMax')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pHWLDruckMaxDef}
              endAdornment={<InputAdornment position="end">bar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pHWLDruckMaxDef')}
              onBlur={() => handleBlurForceZero('pHWLDruckMaxDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* Abgas Temperatur Minimum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Abgas Temperatur Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pTempAbgasMin}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pTempAbgasMin')}
              onBlur={() => handleBlurForceZero('pTempAbgasMin')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pTempAbgasMinDef}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pTempAbgasMinDef')}
              onBlur={() => handleBlurForceZero('pTempAbgasMinDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* Abgas Temperatur Maximum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Abgas Temperatur Maximum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pTempAbgasMax}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pTempAbgasMax')}
              onBlur={() => handleBlurForceZero('pTempAbgasMax')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pTempAbgasMaxDef}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pTempAbgasMaxDef')}
              onBlur={() => handleBlurForceZero('pTempAbgasMaxDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* HWL Füllstand Minimum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="HWL Füllstand Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pNiveauMin}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pNiveauMin')}
              onBlur={() => handleBlurForceZero('pNiveauMin')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pNiveauMinDef}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pNiveauMinDef')}
              onBlur={() => handleBlurForceZero('pNiveauMinDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* HWL Füllstand Warnung */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="HWL Füllstand Warnung"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pNiveauWarn}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pNiveauWarn')}
              onBlur={() => handleBlurForceZero('pNiveauWarn')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pNiveauWarnDef}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pNiveauWarnDef')}
              onBlur={() => handleBlurForceZero('pNiveauWarnDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* Differenzdruck Maximum */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Differenzdruck Maximum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pDiffdruckMax}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pDiffdruckMax')}
              onBlur={() => handleBlurForceZero('pDiffdruckMax')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.pDiffdruckMaxDef}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'pDiffdruckMaxDef')}
              onBlur={() => handleBlurForceZero('pDiffdruckMaxDef')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* NOx In Warnung*/}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="NOx In Warnung"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_NoxIn_warn}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_NoxIn_warn')}
              onBlur={() => handleBlurForceZero('Var_NoxIn_warn')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_NoxIn_warnDef}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_NoxIn_warnDef')}
              onBlur={() => handleBlurForceZero('Var_NoxIn_warnDef')} // Use the handleBlurForceZero function
            />
          </Box>


          {/* UPDATE-BUTTON */}
          <CardContent className={notification === '' && notificationSuccess === '' ? classes.cardContentRight : classes.cardContent}>
            {notification && <Typography style={{ fontSize: '16px' }} color="error">{notification}</Typography>}
            {notificationSuccess && <Typography style={{ fontSize: '16px', color: "green" }}>{notificationSuccess}</Typography>}
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={!active || !serialNumber || liveShadow}
              onClick={() => updateShadow()}
            >
              Absenden
            </Button>
          </CardContent>
          {loading ? (
            <LinearProgress />
          ) : (
            null
          )}
        </Box>
      ) : (
        <Box margin="16px 8px 16px 8px">
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={660} />
        </Box>
      )
      }
    </Card >
  );
}


