import React, { ReactElement } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';

import emilogBackground from '../../assets/emilog-hintergrund.jpg';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      background: `url(${emilogBackground}) no-repeat center center fixed`,
      backgroundSize: 'cover',
      backgroundPositionY: '100%',
    },
    body: {
      background: 'none',
    },
  },
  paper: {
    padding: 20,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    marginTop: theme.spacing(8),
    borderRadius: 8,
  },
}));

export default function PublicLayout({ children }: { children: ReactElement }) {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card className={classes.card}>
        <div className={classes.paper}>
          {children}
        </div>
      </Card>
    </Container>
  );
}
