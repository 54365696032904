import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { API } from '@aws-amplify/api';
import LoaderButton from '../../../emilog/components/LoaderButton';
import Feedback from '../../../emilog/components/Feedback';
import { Emicon } from '../../../../types/Device';
import DeleteButton from './DeleteButton';
import EmiconContext from '../../context/EmiconContext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
  },
  container: {
    width: '95vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  paper: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 16,
  },
  gridContainer: {
    [theme.breakpoints.up('lg')]: {
      borderRight: '1px solid lightgray',
    },
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },

  // cardHeader: {
  //   [theme.breakpoints.down('sm')]: {
  //     flexWrap: 'wrap',
  //   },
  // },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardContent: {
    maxHeight: '660px'
  },

  content: {
    marginTop: theme.spacing(1),
    flex: 1,
  },

  mb: {
    marginBottom: '8px',
  },
}));

interface EmiconInfosProps {
  device: Emicon
  handleMenuOpen: Function
  isViewOnly: boolean
}

export default function EmiconAnlagenInfo({ device, isViewOnly = false, handleMenuOpen }: EmiconInfosProps) {
  const {
    setCurrentDevice, loading: siteLoading, fetchDevices,
  } = useContext(EmiconContext);
  const classes = useStyles();
  const active = device.serialNumber !== undefined;

  const [betreiber, setBetreiber] = useState<string | undefined>('');
  const [motorennummer, setMotorennummer] = useState<string | undefined>('');
  const [adresse, setAdresse] = useState<string | undefined>('');
  const [auftrag, setAuftrag] = useState<string | undefined>('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setMotorennummer(device?.Motorennummer ?? '');
    setAdresse(device?.Adresse ?? '');
    setBetreiber(device?.Betreiber ?? '');
    setAuftrag(device?.Auftrag ?? '');
  }, [device]);

  useEffect(() => {
    setSuccess(false);
  }, [device.serialNumber]);

  async function handleSubmit() {
    try {
      setError(false);
      setSuccess(false);

      const body = {
        serialNumber: device.serialNumber,
        isEmicon: true,
        fields: {
          Adresse: (adresse === undefined || adresse === '') ? null : adresse,
          Betreiber: (betreiber === undefined || betreiber === '') ? null : betreiber,
          Auftrag: (auftrag === undefined || auftrag === '') ? null : auftrag,
          Motorennummer: (motorennummer === undefined || motorennummer === '') ? null : motorennummer,
        },
      };
      setLoading(true);
      await API.post('emilog', 'updateInfos', { body });
      setSuccess(true);

      setCurrentDevice((prevState: Emicon) => ({
        ...prevState,
        Adresse: adresse,
        Betreiber: betreiber,
        Auftrag: auftrag,
        Motorennummer: motorennummer,
      }));
      fetchDevices();

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(true);
    }
  }

  return (
    active ? (
      <Card
        style={{ maxHeight: 1000, minHeight: '100%' }}
      >
        <CardHeader
          title={(
            <div className={classes.cardHeader}>
              {/* @ts-ignore */}
              <ButtonBase onClick={handleMenuOpen}>
                <Typography variant="h5">Anlageninfos</Typography>
                <KeyboardArrowDownIcon />
              </ButtonBase>
              {isViewOnly ? (
                null
              ) : (
                <Box>
                  <DeleteButton
                    serialNumber={device.serialNumber}
                  />
                </Box>
              )}
            </div>
          )}
          style={{ position: 'relative' }}
        />

        <Divider />

        <CardContent>
          <Box maxHeight={'660px'}>
            <Grid container spacing={2}>

              {isViewOnly ? (
                <>
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={device.Gasart || 'unbekannt'}
                      disabled
                      label="Gasart"
                    />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Grid container spacing={2}>
                      <Grid item md={3}>
                        <TextField
                          value={device.CO_warn || ''}
                          disabled
                          label="CO Warn"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.CO_alarm || ''}
                          disabled
                          label="CO Alarm"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.NOx_warn || ''}
                          disabled
                          label="NOₓ Warn"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.NOx_alarm || ''}
                          disabled
                          label="NOₓ Alarm"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>mg/Nm³</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.O_B || ''}
                          disabled
                          label="Bezugssauerstoff"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>%</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.T_min || ''}
                          disabled
                          label="T_min"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>°C</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.T_max || ''}
                          disabled
                          label="T_max"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>°C</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          value={device.T_alarm || ''}
                          disabled
                          label="T_alarm"
                          variant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment:
                              // eslint-disable-next-line react/jsx-indent
                              <InputAdornment position="end" disableTypography className={classes.unit}>°C</InputAdornment>,
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ) : (
                null
              )}

              <Grid xs={12} md={6} item>
                <TextField
                  disabled={isViewOnly}
                  className={classes.textField}
                  type="text"
                  label="Betreiber"
                  value={betreiber || ''}
                  onChange={(e) => setBetreiber(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  disabled={isViewOnly}
                  className={classes.textField}
                  type="text"
                  label="Auftrag"
                  value={auftrag || ''}
                  onChange={(e) => setAuftrag(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  disabled={isViewOnly}
                  className={classes.textField}
                  type="text"
                  label="Adresse"
                  value={adresse || ''}
                  onChange={(e) => setAdresse(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  disabled={isViewOnly}
                  className={classes.textField}
                  type="text"
                  label="Motorennummer"
                  value={motorennummer || ''}
                  onChange={(e) => setMotorennummer(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </Grid>

            </Grid>
          </Box>
        </CardContent>
        {isViewOnly ? (
          null
        ) : (
          <>
            <Divider />
            <CardActions className={classes.cardActions}>
              <Feedback
                error={error}
                success={success}
              />
              <LoaderButton
                isLoading={loading}
                disabled={!active}
                onClick={handleSubmit}
              />
            </CardActions>
          </>
        )}

      </Card>
    ) : (
      <Card
        // className={classes.card}
        style={{ maxHeight: 1000, minHeight: '100%' }}
      >
        <CardHeader
          title={(
            <div className={classes.cardHeader}>
              {/* @ts-ignore */}
              <ButtonBase onClick={handleMenuOpen}>
                <Typography variant="h5">Anlageninfos</Typography>
                <KeyboardArrowDownIcon />
              </ButtonBase>
              {/* <Box>
                <DeleteButton
                  serialNumber={device.serialNumber}
                />
              </Box> */}
            </div>
          )}
          style={{ position: 'relative' }}
        />
        <Divider />
        <Box maxHeight={'660px'}>
          <Skeleton style={{ transform: 'scale(1, 1)', margin: 8 }} height={330} />
        </Box>
      </Card >
    )
  );
}
