/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import API from '@aws-amplify/api';

import CommentDialog from './CommentDialog';
import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import DeviceContext from '../../context/DeviceContext';
import { onlyValidNumbers } from '../../../utils/onlyValidNumbers';
import { Device } from '../../../../types/Device';
import { roundShadowValueNoString } from '../../../utils/roundShadowValueNoString';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  section: {
    padding: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginTop: '-3px!important',
  },
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },
}));

interface KalibrierungProps {
  isMotorlast: boolean
}
function Kalibrierung({ isMotorlast }: KalibrierungProps) {
  const { fetchDevices, currentDevice, setCurrentDevice } = useContext(DeviceContext);
  const active = currentDevice.serialNumber !== undefined;

  const [B_NOx, setB_NOx] = useState<number | string | undefined>(undefined);
  const [M_NOx, setM_NOx] = useState<number | string | undefined>(undefined);
  const [NOx_a, setNOx_a] = useState<number | string | undefined>(undefined);

  const [M_CO, setM_CO] = useState<number | string | undefined>(undefined);

  const [M_O2, setM_O2] = useState<number | string | undefined>(undefined);
  const [B_O2, setB_O2] = useState<number | string | undefined>(undefined);
  const [O2_a, setO2_a] = useState<number | string | undefined>(undefined);

  const [p_a, setp_a] = useState<number | string | undefined>(undefined);
  const [motorlastsignal_a, setMotorlastsignal_a] = useState<number | null | undefined>(undefined); // ergibt sich aus p_a mit umwandlungsfunktion

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [oldDetails, setOldDetails] = useState({});

  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();

  function percentToMbar(percent: number | string | undefined) {
    /* Gegebene Punkte */
    const p1 = { x: 0, y: 0 }; // 0mbar = 0%
    const p2 = { x: 350, y: 100 }; // 350mbar = 100%
    if (typeof percent === 'number') {
      const m = (p2.y - p1.y) / (p2.x - p1.x); // Berechnung der Steigung m
      const b = p1.y - m * p1.x; // Offset b

      // Berechnung des Drucks aus der Prozentzahl
      const pressure = (percent - b) / m;

      if (pressure >= 0) {
        return pressure;
      } else {
        return 0;
      }
    } else {
      return percent;
    }
  }

  function mbarLinearPercent(p: number | null | undefined) {
    /* Gegebene Punkte */
    const p1 = { x: 0, y: 0 } // 0mbar = 0%
    const p2 = { x: 350, y: 100 } // 350mbar = 100% 
    if (typeof p === 'number') {
      const m = (p2.y - p1.y) / (p2.x - p1.x); // Berechnung steigung m
      const b = p1.y - m * p1.x;  // p1 = [0, 0] -> Berechnung offset
      const result = m * p + b;
      if (result >= 0) {
        return result
      } else {
        return 0
      }
    } else {
      return p;
    }
  }

  async function onComment() {
    try {
      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        M_NOx,
        B_NOx,
        M_CO,
        M_O2,
        B_O2,
      }));
      setDialogOpen(true);
      return;
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      let p_aValue = p_a;
      if (isMotorlast) {
        p_aValue = percentToMbar(Number(motorlastsignal_a)); // p_a anhand von motorlastsignal setzen
      }
      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        NOx_a,
        O2_a,
        p_a: p_aValue,
      }));
      await API.post('emilog', 'updateKoeffs', {
        body: {
          serialNumber: currentDevice.MAC,
          fields: {
            NOx_a: (NOx_a === undefined || NOx_a === '') ? null : NOx_a,
            O2_a: (O2_a === undefined || O2_a === '') ? null : O2_a,
            p_a: (p_aValue === undefined || p_aValue === '') ? null : p_aValue,
          },
        },
      });
      await fetchDevices();
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    setOldDetails(currentDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, currentDevice.MAC]);

  useEffect(() => {
    setM_NOx(currentDevice.M_NOx);
    setB_NOx(currentDevice.B_NOx);
    setNOx_a(currentDevice.NOx_a);
    setM_CO(currentDevice.M_CO);
    setM_O2(currentDevice.M_O2);
    setB_O2(currentDevice.B_O2);
    setO2_a(currentDevice.O2_a);
    setp_a(currentDevice.p_a);
    const shortMotorlastsignal = roundShadowValueNoString(mbarLinearPercent(currentDevice.p_a), 2)
    setMotorlastsignal_a(shortMotorlastsignal)
  }, [currentDevice]);

  function handleCommentSubmit(finished: boolean) {
    if (finished) {
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } else {
      setError(true);
    }
    setDialogOpen(false);
  }

  const renderTitle = () => (
    <span>
      <span>Kalibrierung</span>
      <Feedback
        error={error}
        success={success}
        successText="Erfolgreich angepasst"
      />
    </span>
  );

  function handleMotorlastsignal_a(e: ChangeEvent<HTMLInputElement>) {
    const input = onlyValidNumbers(e.target.value);
    let numberInput = Number(input);
    if (numberInput < 0) {
      numberInput = 0;
    } else if (numberInput > 100) {
      numberInput = 100
    }
    // console.log({ numberInput })
    // console.log(typeof numberInput)
    setMotorlastsignal_a(numberInput)
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={renderTitle()}
          classes={{
            action: classes.action,
          }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="M_NOₓ"
                className={classes.input}
                disabled={!active}
                variant="outlined"
                value={M_NOx ?? ''}
                onChange={(e) => setM_NOx(onlyValidNumbers(e.target.value))}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="B_NOₓ"
                className={classes.input}
                disabled={!active}
                value={B_NOx ?? ''}
                variant="outlined"
                onChange={(e) => setB_NOx(onlyValidNumbers(e.target.value))}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" disableTypography className={classes.unit}>ppm</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="M_O2"
                className={classes.input}
                disabled={!active}
                value={M_O2 ?? ''}
                variant="outlined"
                onChange={(e) => setM_O2(onlyValidNumbers(e.target.value))}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="B_O2"
                className={classes.input}
                disabled={!active}
                value={B_O2 ?? ''}
                variant="outlined"
                onChange={(e) => setB_O2(onlyValidNumbers(e.target.value))}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" disableTypography className={classes.unit}>%</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="M_CO"
                className={classes.input}
                disabled={!active}
                value={M_CO ?? ''}
                variant="outlined"
                onChange={(e) => setM_CO(onlyValidNumbers(e.target.value))}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <LoaderButton
                isLoading={false}
                disabled={!active || loading}
                text="Kommentieren"
                onClick={onComment}
                noMargin
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isMotorlast ? (
              <Grid item lg={6} xs={6}>
                <TextField
                  size="small"
                  label="Motorlastsignal_a"
                  type="number"
                  className={classes.input}
                  disabled={!active}
                  value={motorlastsignal_a ?? ''}
                  variant="outlined"
                  onChange={handleMotorlastsignal_a}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" disableTypography className={classes.unit}>%</InputAdornment>,
                    inputProps: {
                      min: 0,
                      max: 100,
                      step: 0.5, // Optional: Allow fine-grained input for decimals
                    }, // Enforce the min and max limits in the input
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ) : (
              <Grid item lg={6} xs={6}>
                <TextField
                  size="small"
                  label="p_a"
                  className={classes.input}
                  disabled={!active}
                  value={p_a ?? ''}
                  variant="outlined"
                  onChange={(e) => setp_a(onlyValidNumbers(e.target.value))}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end" disableTypography className={classes.unit}>mbar</InputAdornment>,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="NOₓ_a"
                className={classes.input}
                disabled={!active}
                value={NOx_a ?? ''}
                variant="outlined"
                onChange={(e) => setNOx_a(onlyValidNumbers(e.target.value))}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" disableTypography className={classes.unit}>ppm</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextField
                size="small"
                label="O2_a"
                className={classes.input}
                disabled={!active}
                value={O2_a ?? ''}
                variant="outlined"
                onChange={(e) => setO2_a(onlyValidNumbers(e.target.value))}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end" disableTypography className={classes.unit}>%</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <LoaderButton
                isLoading={loading}
                disabled={!active || loading}
                text="Absenden"
                noMargin
                fullWidth
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>

        </CardContent>
      </Card>
      <CommentDialog
        newDetails={currentDevice}
        oldDetails={oldDetails}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleCommentSubmit}
      />
    </>
  );
}

export default Kalibrierung;
