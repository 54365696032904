/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import { AddCircleOutline, Clear as ClearIcon } from '@material-ui/icons';

import API from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    marginTop: 16,
    color: red[500],
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  success: {
    color: green[500],
    fontSize: '0.9rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'end',
  },
  deleteGridTile: {
    textAlign: 'end',
  },
  container: {
    width: '85vw',
  },
  // card: {
  //   height: '90vh',
  //   overflowY: 'scroll',
  // },
}));

export default function Gasarten() {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [gasarten, setGasarten] = useState([]);
  const [add, setAdd] = useState(false);
  const [newGasart, setNewGasart] = useState(null);

  async function fetch() {
    const data = await API.get('emilog', 'gasarten');
    setGasarten(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (newGasart !== null) {
      gasarten.push(newGasart);
    }
    setNewGasart(null);
    setAdd(false);
    setError(false);
    setSuccess(false);
    try {
      await API.post('emilog', 'updateGasarten', { body: gasarten });
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
    await fetch();
    setAdd(false);
  }

  function handleChange(event, index, field) {
    const stateCopy = [...gasarten];
    stateCopy[index][field] = parseInt(event.target.value, 10);
    setGasarten([...stateCopy]);
  }

  function handleNameChange(event, index) {
    const stateCopy = [...gasarten];
    stateCopy[index].Gasart = event.target.value;
    setGasarten([...stateCopy]);
  }

  async function resetForm() {
    fetch();
  }

  function stopAdd() {
    setNewGasart({});
    setAdd(false);
  }

  function deleteGasart(index) {
    const stateCopy = [...gasarten];
    stateCopy[index].delete = 'yes';
    setGasarten([...stateCopy]);
  }

  useEffect(() => {
    document.title = 'Gasarten - EMI-LOG Adminportal';
    fetch();
  }, []);

  return (
    <Container className={classes.container} maxWidth="xl">
      <Card title="Gasarten" className={classes.card}>
        <form onSubmit={handleSubmit}>
          <CardContent>
            {gasarten.map(({ Gasart }, index) => {
              if (gasarten[index].delete === undefined) {
                return (
                  <GridList key={Gasart} cols={13} spacing={16} cellHeight={60} className={classes.row}>
                    <GridListTile cols={2}>
                      <TextField
                        label="Name"
                        type="text"
                        value={Gasart}
                        onChange={(event) => handleNameChange(event, index)}
                      />
                    </GridListTile>
                    <GridListTile cols={2}>
                      <TextField
                        label="CO Warn"
                        type="number"
                        value={gasarten[index].CO_warn}
                        onChange={(event) => handleChange(event, index, 'CO_warn')}
                      />
                    </GridListTile>
                    <GridListTile cols={2}>
                      <TextField
                        label="CO Alarm"
                        type="number"
                        value={gasarten[index].CO_alarm}
                        onChange={(event) => handleChange(event, index, 'CO_alarm')}
                      />
                    </GridListTile>
                    <GridListTile cols={2}>
                      <TextField
                        label="NOₓ Warn"
                        type="number"
                        value={gasarten[index].NOx_warn}
                        onChange={(event) => handleChange(event, index, 'NOx_warn')}
                      />
                    </GridListTile>
                    <GridListTile cols={2}>
                      <TextField
                        label="NOₓ Alarm"
                        type="number"
                        value={gasarten[index].NOx_alarm}
                        onChange={(event) => handleChange(event, index, 'NOx_alarm')}
                      />
                    </GridListTile>
                    <GridListTile cols={2}>
                      <TextField
                        label="Bezugssauerstoff"
                        type="number"
                        value={gasarten[index].O_B}
                        onChange={(event) => handleChange(event, index, 'O_B')}
                      />
                    </GridListTile>
                    <GridListTile cols={1} className={classes.deleteGridTile}>
                      <Tooltip title="Löschen" onClick={() => deleteGasart(index)}>
                        <IconButton size="small" className={classes.deleteIcon}>
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </GridListTile>
                  </GridList>
                );
              }
              return null;
            })}
            {add && (
              <GridList cols={13} spacing={16} cellHeight={60} className={classes.row}>
                <GridListTile cols={2}>
                  <TextField
                    label="Bezeichnung"
                    onChange={(event) => setNewGasart({ ...newGasart, Gasart: event.target.value })}
                  />
                </GridListTile>
                <GridListTile cols={2}>
                  <TextField
                    label="CO Warn"
                    type="number"
                    onChange={(event) => setNewGasart({ ...newGasart, CO_warn: parseInt(event.target.value, 10) })}
                  />
                </GridListTile>
                <GridListTile cols={2}>
                  <TextField
                    label="CO Alarm"
                    type="number"
                    onChange={(event) => setNewGasart({ ...newGasart, CO_alarm: parseInt(event.target.value, 10) })}
                  />
                </GridListTile>
                <GridListTile cols={2}>
                  <TextField
                    label="NOx Warn"
                    type="number"
                    onChange={(event) => setNewGasart({ ...newGasart, NOx_warn: parseInt(event.target.value, 10) })}
                  />
                </GridListTile>
                <GridListTile cols={2}>
                  <TextField
                    label="NOx Alarm"
                    type="number"
                    onChange={(event) => setNewGasart({ ...newGasart, NOx_alarm: parseInt(event.target.value, 10) })}
                  />
                </GridListTile>
                <GridListTile cols={2}>
                  <TextField
                    label="Bezugssauerstoff"
                    type="number"
                    onChange={(event) => setNewGasart({ ...newGasart, O_B: parseInt(event.target.value, 10) })}
                  />
                </GridListTile>
                <GridListTile cols={1} className={classes.deleteGridTile}>
                  <Tooltip title="Abbrechen" onClick={stopAdd}>
                    <IconButton size="small" style={{ marginTop: 16 }}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </GridListTile>
              </GridList>
            )}
            <div style={{ marginTop: 12 }}>
              <Typography>
                Gasart hinzufügen
                <IconButton aria-label="hinzufügen" onClick={() => setAdd(true)} disabled={add}>
                  <AddCircleOutline className={classes.plusIcon} />
                </IconButton>

              </Typography>
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Feedback success={success} error={error} />
            <Button onClick={resetForm}>Zurücksetzen</Button>
            <LoaderButton
              isLoading={loading}
              text="Absenden"
            />
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
