/* eslint-disable no-console */
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';

import LoaderButton from '../../../emilog/components/LoaderButton';
import Feedback from '../../../emilog/components/Feedback';
import { Device } from '../../../../types/Device';
import EmiconContext from '../../context/EmiconContext';
import { FormControl, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
}));
interface EmiconVertragsKommentarProps {
  serialNumber: string;
  isEmicon?: boolean;
  rows?: number
}

export default function EmiconVertragsKommentar({ serialNumber, isEmicon = false, rows = 28 }: EmiconVertragsKommentarProps) {
  const classes = useStyles();
  const { currentDevice, setCurrentDevice } = useContext(EmiconContext); // evtl aus Einstellungen passen (WEnn in CON und LOG benutzt)
  const active = (serialNumber !== undefined && serialNumber !== null && serialNumber !== '');
  const [condition, setCondition] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);

      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber,
          isEmicon,
          fields: {
            ServiceVertragKondition: condition,
          },
        },
      });

      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        ServiceVertragKondition: condition,
      }));

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    setSuccess(false);
    setError(false);
    if (!serialNumber || serialNumber === undefined || serialNumber === null || serialNumber === '') return;
    setCondition(currentDevice?.ServiceVertragKondition ?? '');
  }, [serialNumber]);

  return (

    <Card className={classes.card}>
      <CardHeader
        title="Sonderkonditionen Servicevertrag"
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <TextField
          id="outlined-multiline-static"
          label="Sonderkonditionen"
          multiline
          fullWidth
          minRows={rows}
          maxRows={rows}
          variant="outlined"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
        />
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton isLoading={loading} text="Absenden" onClick={handleSubmit} disabled={!active} />
      </CardActions>
    </Card>
  );
}
