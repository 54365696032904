/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { DeviceProvider } from '../context/DeviceContext';
import MainLayout from './MainLayout';

export interface RouteProps {
  isEmicon: boolean
}

export default function EmilogRoute({ component: RouteComponent, path, exact = true }: { component: React.FC<RouteComponentProps & RouteProps>, path: string, exact: boolean }) {
  const { currentUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser?.loggedIn !== undefined) setLoading(false);
  }, [currentUser]);

  return (
    loading ? (
      <div />
    ) : (
      <Route
        path={path}
        exact={exact}
        render={(routeProps: RouteComponentProps) => (!currentUser?.loggedIn ? (
          <Redirect to="/login" />
        ) : (
          <DeviceProvider>
            <MainLayout>
              <RouteComponent {...routeProps} isEmicon={false} />
            </MainLayout>
          </ DeviceProvider>
        )
        )}
      />
    )
  );
}
