/* eslint-disable no-console */
import React, {
  createContext, useEffect, useState, useContext, useMemo,
} from 'react';
import API from '@aws-amplify/api';
import { Device, Emicon } from '../../../types/Device';
import AuthContext from '../../emilog/context/AuthContext';

export interface EmiconContextProps {
  currentDevice: Emicon
  setCurrentDevice: Function
  devices: Emicon[]
  setDevices?: Function
  loading: boolean
  setLoading?: React.SetStateAction<boolean>
  fetchDevices: Function
}

const EmiconContext = createContext<EmiconContextProps>({
  currentDevice: {
    serialNumber: '',
  },
  devices: [],
  setCurrentDevice: () => { },
  loading: false,
  fetchDevices: () => { },
});

/*
  Enthält alle verfügbaren Geräte und das aktuell ausgewählte Gerät
*/
function EmiconDeviceProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(AuthContext);
  const [currentDevice, setCurrentDevice] = useState<Emicon | {}>({});
  const [devices, setDevices] = useState<Emicon[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchDevices() {
    try {
      setLoading(true);
      const res = await API.get('emilog', 'emicon/getDevices', {}) as Emicon[];

      setLoading(false);
      setDevices(res);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  useEffect(() => {
    if (currentUser?.loggedIn) {
      fetchDevices();
    }
  }, [currentUser]);

  const value = useMemo(() => ({
    currentDevice: currentDevice as Device,
    setCurrentDevice,
    devices,
    setDevices,
    loading,
    fetchDevices,
  }), [devices, currentDevice, loading]);

  return (
    <EmiconContext.Provider value={value}>
      {children}
    </EmiconContext.Provider>
  );
}

export default EmiconContext;
export { EmiconDeviceProvider };
