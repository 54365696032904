/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AuthContext from '../../../emilog/context/AuthContext';
// import DeviceContext from '../../../emilog/context/DeviceContext';
import EmiconContext from '../../context/EmiconContext';
import { Button, CardActions, Grid, Typography } from '@material-ui/core';
import UtilsContext from '../../../utils/UtilsContext/UtilsContext';
import LoaderButton from '../../../emilog/components/LoaderButton';
import Feedback from '../../../emilog/components/Feedback';
import API from '@aws-amplify/api';
import capitalize from '../../../utils/capitalize';
import convertDate from '../../../utils/convertDate';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
  // cardContent: {
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  // },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    width: '100%',
    marginTop: '0px',
  },
  datePickersBox: {
    display: 'flex',
    marginBottom: '8px',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  spacer: { // Dient zusammen mit ner Box/Dic als Füllmaterial über z.B. der CardActions Komponente. In tandem mit min-height aus card Klasse.
    flexGrow: 1, // Fills the space above the date picker
  },
  servicePlanned: {
    background: 'green',
    color: 'white',
  },
  serviceNotPlanned: {
    background: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },

  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: -2,
    },
  },


}));

interface EmiconServiceAbgeschlossenProps {
  hasServicePlanned: boolean | null
  lastServicePlannedDate: number | null
  serialNumber: string
}

export default function EmiconServiceAbgeschlossen({ hasServicePlanned, lastServicePlannedDate, serialNumber }: EmiconServiceAbgeschlossenProps) {
  const classes = useStyles();
  const { currentDevice } = useContext(EmiconContext);
  const { currentUser } = useContext(AuthContext);
  const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  const author = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const active = currentDevice.serialNumber !== undefined;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newDate, setNewDate] = useState<MaterialUiPickersDate | null>(null);

  
  async function handleSubmit() {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);

      const timestamp = newDate ? Math.round(newDate.getTime() / 1000) : undefined;

      /* Schließt Service ab, indem der Timestamp auf 0 gesetzt wird */
      if (hasServicePlanned) {
        await API.post('emilog', 'updateInfos', {
          body: {
            serialNumber: currentDevice.serialNumber,
            isEmicon: true, // ACHTUNG
            fields: {
              ServiceGeplant: 0, // Aktueller Service
              ServiceGeplant_Alt: lastServicePlannedDate,// Vorheriger Service
              letzterService: timestamp,// Abschluss des vorherigen Service
            },
          },
        });

        let message = '';

        if (lastServicePlannedDate) {
          message = `Service abgeschlossen (${timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin des Service: ${convertDate(lastServicePlannedDate)}`;
        } else {
          message = `Service abgeschlossen (${timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin nicht verfügbar`;
        }

        await API.post('emilog', 'addLogbookEntry', {
          body: {
            serialNumber: currentDevice.serialNumber,
            author,
            messageContent: message,
            WartungAbgeschlossen: true, // Eig. Sollte man ServiceAbgeschlossen nehmen, das wäre aber für das Label im Logbuch unnötiger extra Code und umbennen will ic hdas jetz auch nicht 
          },
        });
      }

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Service abschließen"
      />
      <Divider />

      <CardContent className={classes.cardContent}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                value={newDate}
                label="Durchgeführter Service"
                className={classes.datePicker}
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                onChange={(e) => setNewDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
                disabled={serialNumber === undefined}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {(currentDevice?.ServiceGeplant_Alt && currentDevice?.ServiceGeplant_Alt > 0) && (
            <Grid item xs={12}>
              <Typography>
                Letzter geplanter Service: {convertDate(currentDevice?.ServiceGeplant_Alt)}
              </Typography>
            </Grid>
          )}

          {(hasServicePlanned && hasServicePlanned === true) && (
            <Grid item xs={12}>
              <Typography>
                Aktuell geplanter Service bis zum: {lastServicePlannedDate !== null ? convertDate(lastServicePlannedDate) : ''}
              </Typography>
            </Grid>
          )}

        </Grid>
      </CardContent>
      <Divider />
      <Box className={classes.spacer} /> {/* Spacer div to fill the space above */}
      <CardActions className={classes.cardActions}>
        {/* <Feedback error={error} success={success} /> */}
        {/* <Button
            style={{ minWidth: "200px" }}
            variant="contained"
            color="primary"
            component="label"
            disabled={!currentDevice?.serialNumber}
            onClick={() => handleAnlagenStatus('finished')}
          >
            Service abschließen
          </Button> */}
        <Feedback error={error} success={success} />
        <LoaderButton
          isLoading={loading}
          text="Service abschließen"
          onClick={handleSubmit}
          disabled={!active || hasServicePlanned === undefined || hasServicePlanned === null || !newDate || !hasServicePlanned}
        />
      </CardActions>
    </Card>
  );
}
