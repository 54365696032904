function getMimeType(base64String: string) {
  const base64Header = base64String.split(',')[0];
  const matches = base64Header.match(/:(.*?);/);

  if (matches && matches.length >= 2) {
    return matches[1];
  }

  // Fallback to a default MIME type if not found
  return 'application/octet-stream';
}

export function base64toFile(base64String: string, fileName: string) {
  const mimeType = getMimeType(base64String);

  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a File object
  const file = new File([blob], fileName, { type: mimeType });
  return file;
}
