import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CardContent, LinearProgress, Switch, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { EmiconShadow } from '../../../../types/Shadow';

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  categoryHeader: {
    fontWeight: 900,
  },

  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },

  shadowInput: {
    fontSize: '13px',
    margin: '2px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputForm: {
    fontSize: '13px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputSm: {
    width: '100px',
  },

  shadowInputXSm: {
    width: '115px',
  },

  shadowInputMd: {
    width: '150px',
  },

  shadowInputLg: {
    width: '175px',
  },

  shadowInputXl: {
    width: '200px',
  },

  shadowInputXxl: {
    width: '250px',
  },

  shadowInputDark: {
    background: '#014a69',
    color: 'white !important'
  },

  dropdown: {
    width: 150,
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -8,
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      fontSize: '13px',
      margin: '2px',
      height: '30px',
      paddingLeft: '8px !important',
      '&.Mui-disabled': {
        color: 'black',
      },
    },
  },

  select: {
    paddingLeft: 8,
  },

  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
    paddingLeft: '0px', // Linker Text unter Input Elementen
  },

  cardContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
  },

  boxCenter: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
  },

  marginTB: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  adornmentText: {
    '& .MuiTypography-root': {
      color: 'white',
    },
  },

}));



interface EmiconSimulierwerteProps {
  serialNumber: string
  active: boolean
  handleMenuOpen: Function
  updateReportedShadow: Partial<EmiconShadow> | null
  updateShadow: Function
  updateReported: Function
  setUpdateReportedShadow: Function
  loading: boolean
  notification: string
  notificationSuccess: string
  handleBlurForceZero: Function
  liveShadow?: boolean
}

export default function EmiconSimulierwerte({
  serialNumber,
  active,
  handleMenuOpen,
  updateReportedShadow,
  updateShadow,
  updateReported,
  setUpdateReportedShadow,
  loading,
  notification,
  notificationSuccess,
  handleBlurForceZero,
  liveShadow
}: EmiconSimulierwerteProps) {

  const classes = useStyles();

  // const handleSwitch = (key: keyof EmiconShadow) => {
  //   setUpdateReportedShadow((prevState: EmiconShadow) => ({
  //     ...prevState,
  //     [key]: typeof prevState[key] === 'boolean' ? !prevState[key] : prevState[key]
  //   }));
  // };

  const handleSwitch = (key: keyof EmiconShadow) => {
    setUpdateReportedShadow((prevState: EmiconShadow) => {
      const currentValue = prevState[key];
      // Determine if the key exists in the state
      if (typeof currentValue === 'boolean') {
        // Toggle the boolean value if it exists
        return {
          ...prevState,
          [key]: !currentValue
        };
      } else {
        // If the key doesn't exist or isn't a boolean, add it with a default value of `true`
        return {
          ...prevState,
          [key]: true
        };
      }
    });
  };

  return (
    <Card className={classes.card} style={{ maxHeight: 1000, minHeight: "100%" }}>

      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Simulationswerte</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      {serialNumber && active && updateReportedShadow && Object.keys(updateReportedShadow).length > 0 ? (
        <Box margin="16px 8px 16px 8px" maxHeight={660}>

          {/* Header */}
          <Box>
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Ist"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Simuliert"
              aria-describedby="outlined-weight-helper-text"
            />
          </Box>

          {/* NOx ein */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="NOx ein"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_NOx_In_ppm}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.NoxInPpmcSim}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'NoxInPpmcSim')}
              onBlur={() => handleBlurForceZero('NoxInPpmcSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.NoxInPpmcSimSwitch}
              onChange={() => handleSwitch('NoxInPpmcSimSwitch')}
            />
          </Box>

          {/* NOx aus */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="NOx aus"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_NOx_Out_ppm}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text" // Yo hier
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.NoxOutPpmcSim}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'NoxOutPpmcSim')}
              onBlur={() => handleBlurForceZero('NoxOutPpmcSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.NoxOutPpmcSimSwitch}
              onChange={() => handleSwitch('NoxOutPpmcSimSwitch')}
            />
          </Box>

          {/* O2 ein */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="O2 ein"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_O2_In_c}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.O2InCSim}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'O2InCSim')}
              onBlur={() => handleBlurForceZero('O2InCSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.O2InPpmCSimSwitch}
              onChange={() => handleSwitch('O2InPpmCSimSwitch')}
            />
          </Box>

          {/* O2 aus */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="O2 aus"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_O2_Out_c}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.O2OutCSim}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'O2OutCSim')}
              onBlur={() => handleBlurForceZero('O2OutCSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.O2OutPpmCSimSwitch}
              onChange={() => handleSwitch('O2OutPpmCSimSwitch')}
            />
          </Box>

          {/* CO */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="CO"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_CO_ppmc}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.COPpmCSim}
              endAdornment={<InputAdornment position="end">ppm</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'COPpmCSim')}
              onBlur={() => handleBlurForceZero('COPpmCSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.COPpmCSimSwitch}
              onChange={() => handleSwitch('COPpmCSimSwitch')} // TODO Überprüfen ob die ariable richtig ist
            />
          </Box>

          {/* Füllstand */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Füllstand"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_Level}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.LevelAdBlueSim}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'LevelAdBlueSim')}
              onBlur={() => handleBlurForceZero('LevelAdBlueSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.LevelAdBlueSimSwitch}
              onChange={() => handleSwitch('LevelAdBlueSimSwitch')}
            />
          </Box>

          {/* Differenz p */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Differenz p"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_p_DiffCat}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.CatDiffPSim}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'CatDiffPSim')}
              onBlur={() => handleBlurForceZero('CatDiffPSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.CatDiffPSimSwitch}
              onChange={() => handleSwitch('CatDiffPSimSwitch')}
            />
          </Box>

          {/* Druck Luft */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Druck Luft"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_p_Air}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.SuperchargPSim}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'SuperchargPSim')}
              onBlur={() => handleBlurForceZero('SuperchargPSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.SuperchargPSimSwitch}
              onChange={() => handleSwitch('SuperchargPSimSwitch')}
            />
          </Box>

          {/* Druck HWL */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Druck HWL"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_p_UWS}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.AdBluePSim}
              endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'AdBluePSim')}
              onBlur={() => handleBlurForceZero('AdBluePSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.AdBluePSimSwitch}
              onChange={() => handleSwitch('AdBluePSimSwitch')}
            />
          </Box>

          {/* Motorlast */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorlast"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_EnLoad}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.EngineLoadSim}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'EngineLoadSim')}
              onBlur={() => handleBlurForceZero('EngineLoadSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.EngineLoadSimSwitch}
              onChange={() => handleSwitch('EngineLoadSimSwitch')}
            />
          </Box>

          {/* Temperatur */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Temperatur"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_T_Exhaust}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.CatTempSim}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'CatTempSim')}
              onBlur={() => handleBlurForceZero('CatTempSim')} // Use the handleBlurForceZero function
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.CatTempSimSwitch}
              onChange={() => handleSwitch('CatTempSimSwitch')}
            />
          </Box>

          {/* <Divider className={classes.marginTB} /> */}

          {/* Dumme Platzhalter um Abstand zu gewinnen, könnte auch Margin nutzen, aber so fand ich schöner :D */}

          {/* <Box>
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
          </Box>

          <Box>
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
          </Box> */}

          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value={`Simulation ${updateReportedShadow.sSimOnOff === true ? 'Ein' : 'Aus'}`}
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.sSimOnOff}
              onChange={() => handleSwitch('sSimOnOff')}
            />
          </Box>

          {/* Start - Stop - Bereich */}

          {/* Pumpentest */}

          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Pumpentest"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Pumpentest_Leistung}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Pumpentest_Leistung')}
              onBlur={() => handleBlurForceZero('Pumpentest_Leistung')} // Use the handleBlurForceZero function
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Start/Stop"
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.Steuer_AdBlue_Pumpe}
              onChange={() => handleSwitch('Steuer_AdBlue_Pumpe')}
            />
          </Box>

          {/* Kompressor */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Kompressor"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Start/Stop"
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.Steuer_Kompressor}
              onChange={() => handleSwitch('Steuer_Kompressor')}
            />
          </Box>

          {/* Adblue Spülventil */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="AdBlue Spülventil"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Start/Stop"
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.Steuer_Spuelventil}
              onChange={() => handleSwitch('Steuer_Spuelventil')}
            />
          </Box>

          {/* Reset CAN-BUS */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Reset CAN-BUS"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value={`Reset Status: ${updateReportedShadow.CAN_BUS_Reset_Man === true ? 'Ein' : 'Aus'}`}
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.CAN_BUS_Reset_Man}
              onChange={() => handleSwitch('CAN_BUS_Reset_Man')}
            />
          </Box>

          {/* Motor_An_Aus */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorstatus"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value={`Motor Status: ${updateReportedShadow.Motor_An_Aus === true ? 'Ein' : 'Aus'}`}
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              disabled={liveShadow || !active}
              color="primary"
              checked={updateReportedShadow.Motor_An_Aus}
              onChange={() => handleSwitch('Motor_An_Aus')}
            />
          </Box>

          {/* UPDATE-BUTTON */}
          <CardContent className={notification === '' && notificationSuccess === '' ? classes.cardContentRight : classes.cardContent}>
            {notification && <Typography style={{ fontSize: '16px' }} color="error">{notification}</Typography>}
            {notificationSuccess && <Typography style={{ fontSize: '16px', color: "green" }}>{notificationSuccess}</Typography>}
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={!active || !serialNumber || liveShadow}
              onClick={() => updateShadow()}
            >
              Absenden
            </Button>
          </CardContent>
          {loading ? (
            <LinearProgress />
          ) : (
            null
          )}
        </Box>
      ) : (
        <Box margin="16px 8px 16px 8px">
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={660} />
        </Box>
      )
      }
    </Card >
  );
};

