/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import LoaderButton from '../../../emilog/components/LoaderButton';
import Feedback from '../../../emilog/components/Feedback';
import { Device } from '../../../../types/Device';
import EmiconContext from '../../context/EmiconContext';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
}));
interface EmiconVertragsEndeProps {
  serialNumber: string;
  isEmicon?: boolean;
}

export default function EmiconVertragsEnde({ serialNumber, isEmicon = false }: EmiconVertragsEndeProps) {
  const classes = useStyles();
  const { currentDevice, setCurrentDevice } = useContext(EmiconContext);
  const active = (serialNumber !== undefined && serialNumber !== null && serialNumber !== '');
  const [date, setDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);
      const timestamp = date ? Math.round(date.getTime() / 1000) : undefined;

      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber,
          isEmicon,
          fields: {
            ServiceVertragsende: timestamp,
          },
        },
      });

      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        ServiceVertragsende: Number.isNaN(timestamp) ? undefined : timestamp,
      }));

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    setSuccess(false);
    setError(false);
    if (!serialNumber || serialNumber === undefined || serialNumber === null || serialNumber === '') return;
    if (!currentDevice?.ServiceVertragsende || currentDevice?.ServiceVertragsende === undefined || currentDevice?.ServiceVertragsende === null) return;
    const endDate = new Date(currentDevice?.ServiceVertragsende * 1000);
    setDate(endDate as Date);
  }, [serialNumber]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Card className={classes.card}>
        <CardHeader
          title="Vertragsende"
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <KeyboardDatePicker
            value={date}
            className={classes.datePicker}
            disabled={!active}
            disableToolbar
            autoOk
            minDate={new Date()}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Ablaufdatum"
            onChange={(e) => setDate(e)}
            invalidDateMessage="Kein korrektes Format"
            minDateMessage="Lizenz abgelaufen"
            inputVariant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Feedback error={error} success={success} />
          <LoaderButton isLoading={loading} text="Absenden" onClick={handleSubmit} disabled={!active || date === null} />
        </CardActions>
      </Card>
    </MuiPickersUtilsProvider>
  );
}
