import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CardContent, LinearProgress, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { EmiconShadow } from '../../../../types/Shadow';

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  categoryHeader: {
    fontWeight: 900,
  },

  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },

  saveButton: {
    border: '1px solid black',
  },

  saveButtonGreen: {
    border: '1px solid black',
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'lightgreen',
      color: 'black'
    }
  },

  shadowInput: {
    fontSize: '13px',
    margin: '2px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputForm: {
    fontSize: '13px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputSm: {
    width: '100px',
  },

  shadowInputXSm: {
    width: '115px',
  },

  shadowInputMd: {
    width: '150px',
  },

  shadowInputLg: {
    width: '175px',
  },

  shadowInputXl: {
    width: '200px',
  },

  shadowInputXxl: {
    width: '250px',
  },

  shadowInputDark: {
    background: '#014a69',
    color: 'white !important'
  },

  dropdown: {
    width: 150,
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -8,
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      fontSize: '13px',
      margin: '2px',
      height: '30px',
      paddingLeft: '8px !important',
      '&.Mui-disabled': {
        color: 'black',
      },
    },
  },

  select: {
    paddingLeft: 8,
  },

  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
    paddingLeft: '0px', // Linker Text unter Input Elementen
  },

  cardContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
  },

  boxCenter: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
  },

  marginTB: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  adornmentText: {
    '& .MuiTypography-root': {
      color: 'white',
    },
  },

}));

interface EmiconKalibirerWerteProps {
  serialNumber: string
  active: boolean
  handleMenuOpen: Function
  updateReportedShadow: Partial<EmiconShadow> | null
  updateShadow: Function
  updateReported: Function
  loading: boolean
  notification: string
  notificationSuccess: string
  handleBlurForceZero: Function
  liveShadow?: boolean
  updateReportedSwitch: Function
}

export default function EmiconKalibrierwerte({
  serialNumber,
  active,
  handleMenuOpen,
  updateReportedShadow,
  updateShadow,
  updateReported,
  loading,
  notification,
  notificationSuccess,
  handleBlurForceZero,
  liveShadow,
  updateReportedSwitch
}: EmiconKalibirerWerteProps) {

  const classes = useStyles();

  return (
    <Card className={classes.card} style={{ maxHeight: 1000, minHeight: "100%" }}>

      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Skalenwerte</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      {serialNumber && active && updateReportedShadow && Object.keys(updateReportedShadow).length > 0 ? (
        <Box margin="16px 8px 16px 8px" maxHeight={660}>

          {/* NOX-Werte */}
          <Box>
            <OutlinedInput
              disabled
              style={{ visibility: 'hidden' }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value=""
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="Ist (ppm | %)"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
              value="Ist Kalibriert (ppm | %)"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="Verstärkung"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXSm}`}
              value="Offset (ppm | %)"
              aria-describedby="outlined-weight-helper-text"
            />
          </Box>

          {/* Ab hier Eintragbar */}

          {/* NOX-ein */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="NOx ein"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_NOx_In_ppm}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_NOx_In_ppm')}
            // onBlur={() => handleBlurForceZero('Var_NOx_In_ppm')} 
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_NOx_In_ppmc}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_NOx_In_ppmc')}
            // onBlur={() => handleBlurForceZero('Var_NOx_In_ppmc')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_M_NOx_In}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_M_NOx_In')}
              onBlur={() => handleBlurForceZero('Var_M_NOx_In')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
              value={updateReportedShadow.Var_B_NOx_In}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_B_NOx_In')}
              onBlur={() => handleBlurForceZero('Var_B_NOx_In')}
            />
          </Box>

          {/* NOX-aus */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="NOx aus"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_NOx_Out_ppm}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_NOx_Out_ppm')}
            // onBlur={() => handleBlurForceZero('Var_NOx_Out_ppm')} 
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.NOxOutppmc}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'NOxOutppmc')}
            // onBlur={() => handleBlurForceZero('NOxOutppmc')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_M_NOx_Out}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_M_NOx_Out')}
              onBlur={() => handleBlurForceZero('Var_M_NOx_Out')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
              value={updateReportedShadow.Var_B_NOx_Out}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_B_NOx_Out')}
              onBlur={() => handleBlurForceZero('Var_B_NOx_Out')}
            />
          </Box>

          {/* O2-ein */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="O2 ein"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_O2_In}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_O2_In')}
            // onBlur={() => handleBlurForceZero('Var_O2_In')} 
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_O2_In_c}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_O2_In_c')}
            // onBlur={() => handleBlurForceZero('Var_O2_In_c')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_M_O2_In}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_M_O2_In')}
              onBlur={() => handleBlurForceZero('Var_M_O2_In')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
              value={updateReportedShadow.Var_B_O2_In}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_B_O2_In')}
              onBlur={() => handleBlurForceZero('Var_B_O2_In')} // Use the handleBlurForceZero function
            />
          </Box>

          {/* O2-aus */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value="O2 aus"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_O2_Out}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_O2_Out')}
            // onBlur={() => handleBlurForceZero('Var_O2_Out')} 
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              value={updateReportedShadow.Var_O2_Out_c}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_O2_Out_c')}
            // onBlur={() => handleBlurForceZero('Var_O2_Out_c')} 
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_M_O2_Out}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_M_O2_Out')}
              onBlur={() => handleBlurForceZero('Var_M_O2_Out')}
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
              value={updateReportedShadow.Var_B_O2_Out}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_B_O2_Out')}
              onBlur={() => handleBlurForceZero('Var_B_O2_Out')}
            />
          </Box>



          <Divider className={classes.marginTB} />



          {/* Unterer Abschnitt nach der Matrix */}

          {/* FÜLLSTAND */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Füllstand"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_Level}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_Level')}
            // onBlur={() => handleBlurForceZero('Var_Level')}
            />
          </Box>

          {/* FÜLLSTAND-MINIMUM*/}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Füllstand Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              // value={updateReportedShadow.pNiveauMin ?? 0} // Soll immer 0 sein...
              value={0}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'pNiveauMin')}
            // onBlur={() => handleBlurForceZero('pNiveauMin')}
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value={updateReportedShadow.LevelAdBlueMinCurrent ?? 4}
              endAdornment={<InputAdornment position="end" classes={{ root: classes.adornmentText }}>mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'LevelAdBlueMinCurrent')}
            // onBlur={() => handleBlurForceZero('LevelAdBlueMinCurrent')}

            />
            <Button
              disabled={liveShadow}
              onClick={() => updateReportedSwitch(true, 'LevelAdBlueSaveMin')}
              className={`${classes.shadowInput} ${classes.shadowInputSm} ${updateReportedShadow.LevelAdBlueSaveMin === true ? classes.saveButtonGreen : classes.saveButton} `}
            >
              Speichern
            </Button>
          </Box>

          {/* FÜLLSTAND-MAXIMUM*/}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Füllstand Max.Ist"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.LevelAdBlueDes}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'LevelAdBlueDes')}
              onBlur={() => handleBlurForceZero('LevelAdBlueDes')}
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value={updateReportedShadow.LevelAdBlueMaxCurrent ?? 20}
              endAdornment={<InputAdornment position="end" classes={{ root: classes.adornmentText }}>mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'LevelAdBlueMaxCurrent')}
            // onBlur={() => handleBlurForceZero('LevelAdBlueMaxCurrent')}

            />
            <Button
              disabled={liveShadow}
              onClick={() => updateReportedSwitch(true, 'LevelAdBlueSaveMax')}
              className={`${classes.shadowInput} ${classes.shadowInputSm} ${updateReportedShadow.LevelAdBlueSaveMax === true ? classes.saveButtonGreen : classes.saveButton} `}
            >
              Speichern
            </Button>
          </Box>

          {/* FÜLLSTAND-SKALA */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Füllstand Skalen Rohwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.LevelAdBlueActCurrent}
              endAdornment={<InputAdornment position="end">mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'LevelAdBlueActCurrent')}
            // onBlur={() => handleBlurForceZero('LevelAdBlueActCurrent')}
            />
          </Box>

          {/* MOTORLAST */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorlast"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_EnLoad}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'Var_EnLoad')}
            // onBlur={() => handleBlurForceZero('Var_EnLoad')}
            />
          </Box>

          {/* MOTORLAST-0% */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorlast 0%"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.EnginePowerMin}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'EnginePowerMin')}
              onBlur={() => handleBlurForceZero('EnginePowerMin')}
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value={updateReportedShadow.EnginePowerMinCurrent ?? 4}
              endAdornment={<InputAdornment position="end" classes={{ root: classes.adornmentText }}>mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'EnginePowerMinCurrent')}
            // onBlur={() => handleBlurForceZero('EnginePowerMinCurrent')}
            />
            <Button
              disabled={liveShadow}
              onClick={() => updateReportedSwitch(true, 'EnginePowerSaveMin')}
              className={
                `${classes.shadowInput} ${classes.shadowInputSm} ${updateReportedShadow.EnginePowerSaveMin === true ? classes.saveButtonGreen : classes.saveButton}`
              }
            >
              Speichern
            </Button>
          </Box>

          {/* MOTORLAST-MAXIMUM*/}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorlast Max %"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.EnginePowerMax}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'EnginePowerMax')}
              onBlur={() => handleBlurForceZero('EnginePowerMax')}
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
              value={updateReportedShadow.EnginePowerMaxCurrent ?? 20}
              endAdornment={<InputAdornment position="end" classes={{ root: classes.adornmentText }}>mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'EnginePowerMaxCurrent')}
            // onBlur={() => handleBlurForceZero('EnginePowerMaxCurrent')}
            />
            <Button
              disabled={liveShadow}
              onClick={() => updateReportedSwitch(true, 'EnginePowerSaveMax')}
              className={`${classes.shadowInput} ${classes.shadowInputSm} ${updateReportedShadow.EnginePowerSaveMax === true ? classes.saveButtonGreen : classes.saveButton} `}
            >
              Speichern
            </Button>
          </Box>

          {/* MOTORLAST-SKALA */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Motorlast Skalen Rohwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.EnginePowerActCurrent}
              endAdornment={<InputAdornment position="end">mA</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
            // onChange={(e) => updateReported(e, 'EnginePowerActCurrent')}
            // onBlur={() => handleBlurForceZero('EnginePowerActCurrent')}
            />
          </Box>

          {/* PI REGLER-TUNING */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="PI Regler-Tuning"
              aria-describedby="outlined-weight-helper-text"
            />
          </Box>

          {/* VERSTÄRKUNG KP */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Verstärkung Kp"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_PI_k}
              // endAdornment={<InputAdornment position="end"></InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_PI_k')}
              onBlur={() => handleBlurForceZero('Var_PI_k')}
            />
          </Box>

          {/* ZEITKONSTANTE TI */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
              value="Zeitkonstante Ti"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              value={updateReportedShadow.Var_PI_t}
              // endAdornment={<InputAdornment position="end"></InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_PI_t')}
              onBlur={() => handleBlurForceZero('Var_PI_t')}
            />
          </Box>

          {/* UPDATE-BUTTON */}
          <CardContent className={notification === '' && notificationSuccess === '' ? classes.cardContentRight : classes.cardContent}>
            {notification && <Typography style={{ fontSize: '16px' }} color="error">{notification}</Typography>}
            {notificationSuccess && <Typography style={{ fontSize: '16px', color: "green" }}>{notificationSuccess}</Typography>}
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={!active || !serialNumber || liveShadow}
              onClick={() => updateShadow()}
            >
              Absenden
            </Button>
          </CardContent>
          {loading ? (
            <LinearProgress />
          ) : (
            null
          )}
        </Box>
      ) : (
        <Box margin="16px 8px 16px 8px">
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={660} />
        </Box>
      )
      }
    </Card >
  );
}

