import React, { useEffect, createContext, useMemo } from 'react';
import Auth from '@aws-amplify/auth';
import { Hub, HubPayload } from '@aws-amplify/core';

interface UserType {
  user: any
  loggedIn?: boolean
}

interface ContextProps {
  currentUser: UserType
  authenticate: Function
}

const AuthContext = createContext<Partial<ContextProps>>({});

function AuthProvider({ children }: { children: React.ReactChild }) {
  const [currentUser, setCurrentUser] = React.useState<UserType>({ user: null, loggedIn: undefined });

  async function onAuthEvent({ payload }: { payload: HubPayload }) {
    const { event, data } = payload;
    switch (event) {
      case 'signIn': {
        setCurrentUser({ user: data, loggedIn: true });
        break;
      }
      case 'signOut':
        setCurrentUser(() => ({
          user: null, loggedIn: false,
        }));
        break;
      default: {
        break;
      }
    }
  }

  async function authenticate() {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setCurrentUser({
        user, loggedIn: true,
      });
    } catch (e) {
      Auth.signOut();
    }
  }

  useEffect(() => {
    try {
      authenticate();
    } catch (e) {
      console.log(e);
    }
    Hub.listen('auth', onAuthEvent);
  }, []);

  const value = useMemo(() => ({
    currentUser, setCurrentUser, authenticate,
  }), [currentUser]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthProvider };
