import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import propTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useLocation, useHistory } from 'react-router-dom';

import API from '@aws-amplify/api';

import LoaderButton from '../LoaderButton';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
  },
  flex: {
    display: 'flex',
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: '0.4',
    backgroundColor: 'black',
  },
}));

function CsvTransferDialog({
  onClose, serialNumber, open, target, setTarget, setError, setSuccess,
}) {
  CsvTransferDialog.propTypes = {
    onClose: propTypes.func.isRequired,
    serialNumber: propTypes.string,
    /* eslint-disable-next-line */
    target: propTypes.objectOf(propTypes.any),
    setTarget: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    setSuccess: propTypes.func.isRequired,
    setError: propTypes.func.isRequired,
  };

  CsvTransferDialog.defaultProps = {
    target: {},
    serialNumber: '',
  };

  const {
    setCurrentDevice, fetchDevices,
  } = useContext(DeviceContext);

  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  const classes = useStyles();
  const [csvNoData, setCsvNoData] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCsvClose = (_event, reason) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setCsvNoData(false);
    onClose();
  };

  const handleCsvSubmit = async () => {
    const filename = `EMI-LOG_${serialNumber}`;
    try {
      setCsvLoading(true);
      const res = await API.get('emilog', 'csv', {
        queryStringParameters: {
          serialNumber,
          timezone: new Date().getTimezoneOffset(),
        },
      });
      setCsvLoading(false);
      setCsvError(false);
      setCsvNoData(false);
      const uri = `data:text/csv;charset=utf-8,${escape(res)}`;
      const link = document.createElement('a');
      link.href = uri;
      link.download = `${filename}.csv`;
      link.click();
    } catch (err) {
      setCsvLoading(false);
      if (err.response) {
        if (err.response.status === 404) {
          setCsvNoData(true);
        }
      } else {
        setCsvError(true);
      }
    }
  };

  async function handleTransfer(event) {
    try {
      event.preventDefault();
      setLoading(true);
      setError(false);
      const res = await API.post('transferLambda', '/', { body: { source: serialNumber, target: target.MAC } });

      if (res.statusCode !== 200) {
        onClose();
        setError(true);
        setLoading(false);
        await fetchDevices();
        return;
      }

      await fetchDevices();
      const details = await API.get('emilog', 'details', {
        queryStringParameters: {
          serialNumber: target.MAC,
        },
      });
      setCurrentDevice(details);

      setLoading(false);
      setSuccess(true);
      history.push(`/${path}/${target.MAC}`);
      setTarget(null);
      onClose();
    } catch (err) {
      onClose();
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <Dialog open={open} onClose={handleCsvClose}>
      <DialogTitle>
        Datentransfer von
        {' '}
        {serialNumber}
        {' '}
        zu
        {' '}
        {target?.MAC}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sind Sie sich sicher, dass Sie die Daten übertragen wollen?
          Dieser Vorgang löscht sämtliche Daten auf dem Zielgerät,
          überträgt die Daten vom Ursprungsgerät und löscht anschließend dessen Daten.
          Zur Überprüfung können Sie hier eine CSV-Datei
          mit den Daten des Ursprungsgeräts herunterladen.
        </DialogContentText>

        {csvError ? (
          <Grid item xs={12} md={12} lg={12}>
            <Typography color="error">Etwas ist schiefgelaufen</Typography>
          </Grid>
        ) : null}
        {csvNoData ? (
          <Grid item xs={12} md={12} lg={12}>
            <Typography color="error">Keine Daten gefunden</Typography>
          </Grid>
        ) : null}

      </DialogContent>
      <DialogActions className={classes.actions}>
        <div>
          <LoaderButton
            isLoading={csvLoading}
            onClick={handleCsvSubmit}
            text="CSV Download"
            fullWidth={false}
          />
        </div>
        <div className={classes.flex}>
          <Button onClick={handleCsvClose}>Abbrechen</Button>
          <Button
            onClick={handleTransfer}
            fullWidth={false}
            variant="text"
            color="primary"
          >
            Daten übertragen
          </Button>
        </div>
      </DialogActions>

      {loading && (
        <>
          <div className={classes.overlay} />
          <div className={classes.iconWrapper}>
            <CircularProgress />
          </div>
        </>
      )}

    </Dialog>
  );
}

export default CsvTransferDialog;
