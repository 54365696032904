import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface DelteAttachmentDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filename: string;
  deleteAttachmentConfirm: Function
}

/* Einzigartigen Dateiname zurück zum Originalnamen konvertieren */
function toOriginalFilename(uniqieFilename: string) {
  const filenameWithoutType = uniqieFilename.split('_')[0];
  const filetype = uniqieFilename.split('.')[1];
  return `${filenameWithoutType}.${filetype}`;
}

export default function DeleteAttachmentDialog({
  open, setOpen, filename, deleteAttachmentConfirm,
} : DelteAttachmentDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Anhang wirklich löschen?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sind Sie sicher, dass Sie folgenden Anhang löschen möchten?
        </DialogContentText>
        <DialogContentText style={{ fontWeight: 'bold' }}>
          {toOriginalFilename(filename)}
        </DialogContentText>
        <DialogContentText>
          Dieser Vorang kann nicht rückgängig gemacht werden!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Abbrechen</Button>
        <Button color="secondary" variant="outlined" onClick={() => deleteAttachmentConfirm()} autoFocus>Anhang löschen</Button>
      </DialogActions>
    </Dialog>
  );
}
