import API from "@aws-amplify/api";
import { Device, Emicon } from "../../types/Device";

async function handleAnlagenStatusNew(currentDevice: Emicon | Device, isEmicon: boolean) {
  let status;

  if (currentDevice?.AnlagenStatus) {
    if (currentDevice?.AnlagenStatus === 'In_Bearbeitung') {
      status = 'aus'
    } else {
      status = 'ein'
    }
  } else {
    status = 'ein'
  }

  const statusString =
    status === 'ein' ? 'In_Bearbeitung' :
      status === 'aus' ? 'Kein_Status' :
        status === 'planned' ? 'Service_geplant' :
          'finished';

  try {
    const { AnlagenStatus, serialNumber } = currentDevice || {};

    if ((AnlagenStatus === 'In_Bearbeitung' && status === 'aus') || AnlagenStatus !== 'In_Bearbeitung') {
      const newStatus = (AnlagenStatus === 'In_Bearbeitung' && status === 'aus')
        ? 'Kein_Status'
        : statusString;

      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber,
          isEmicon,
          fields: { AnlagenStatus: newStatus },
        },
      });

      return { AnlagenStatus: newStatus }
    }
  } catch (err) {
    console.log(err);
  }
}

export default handleAnlagenStatusNew;
