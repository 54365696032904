/* eslint-disable no-console */
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Chip from '@material-ui/core/Chip';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import AuthContext from '../../../emilog/context/AuthContext';
import capitalize from '../../../utils/capitalize';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
  // cardContent: {
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  // },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    width: '100%',
    marginTop: '0px',
  },
  datePickersBox: {
    display: 'flex',
    marginBottom: '8px',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  servicePlanned: {
    background: 'green',
    color: 'white',
  },
  serviceNotPlanned: {
    background: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },

}));

interface WartungGeplantProps {
  // serialNumber: string,
  hasWartungPlanned: boolean | null
  lastWartungPlannedDate: number | null
}

export default function WartungGeplant({ hasWartungPlanned, lastWartungPlannedDate }: WartungGeplantProps) {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const { currentDevice, fetchDevices } = useContext(DeviceContext);
  const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  const author = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const active = currentDevice.serialNumber !== undefined;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reasonText, setReasonText] = useState<string | null>('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    try {
      const timestampStart = startDate ? Math.round(startDate.getTime() / 1000) : null;
      const timestampEnd = endDate ? Math.round(endDate.getTime() / 1000) : null;
      if (timestampStart === null || timestampEnd === null || lastWartungPlannedDate === null) return;
      setLoading(true);
      setError(false);
      setSuccess(false);

      /* Letzte geplante Wartung in EMI-LOG-Infos Tabelle hinzufügen.
      macht das spätere filtern nach der Spalte, ob eine Wartung geplant ist, einfacher.
      Der spätere Timestamp ist für die Tabelle ausschlaggebend, darum der End und nicht merh der Start Timestamp*/
      if (timestampEnd > lastWartungPlannedDate) { // Was wenn Wartung vorgezogen wird? ==> Einfach Wartung abschließen un neu erstellen
        await API.post('emilog', 'updateInfos', {
          body: {
            serialNumber: currentDevice.serialNumber,
            isEmicon: false, // ACHTUNG
            fields: {
              WartungGeplant: timestampEnd,
            },
          },
        });
      }

      let reason = `Wartung geplant: ${reasonText}`;

      await API.post('emilog', 'addServiceEntry', {
        body: {
          serialNumber: currentDevice.serialNumber,
          timestampStart,
          timestampEnd,
          author,
          reason,
          isEmicon: false,
        },
      });

      fetchDevices();
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    setSuccess(false);
    setError(false);
    // checkHasActiveWartung();
  }, [currentDevice.serialNumber]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.card}>
        <CardHeader
          title="Wartung geplant"
          action={hasWartungPlanned !== null ? (
            <Chip
              className={hasWartungPlanned ? classes.servicePlanned : classes.serviceNotPlanned}
              label={hasWartungPlanned ? 'Wartung geplant' : 'Keine Wartung geplant'}
            />
          ) : (
            null
          )}
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Box>

            <Box className={classes.datePickersBox}>
              <KeyboardDatePicker
                size="small"
                value={startDate}
                className={classes.datePicker}
                style={{ marginRight: '16px' }}
                disabled={!active}
                disableToolbar
                autoOk
                minDate={new Date()}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="von"
                onChange={(e) => setStartDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
              />
              <KeyboardDatePicker
                size="small"
                value={endDate}
                className={classes.datePicker}
                disabled={!active}
                disableToolbar
                autoOk
                minDate={new Date()}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="bis"
                onChange={(e) => setEndDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
              />
            </Box>
            <TextField
              size="small"
              label="Grund"
              disabled={!active}
              variant="outlined"
              fullWidth
              type="text"
              value={reasonText !== null ? reasonText : ''}
              onChange={(e) => setReasonText(e.target.value)}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Feedback error={error} success={success} />
          <LoaderButton
            isLoading={loading}
            text="Absenden"
            onClick={handleSubmit}
            disabled={!active || startDate === null || endDate === null || reasonText === '' || hasWartungPlanned === true}
          />
        </CardActions>
      </Card>
    </MuiPickersUtilsProvider>
  );
}
