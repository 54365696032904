import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MenuItem from '@material-ui/core/MenuItem';
import { CardActions, CardContent, CardHeader, Chip, Divider, FormControl, InputLabel, Select } from '@material-ui/core';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import API from '@aws-amplify/api';
import { Device } from '../../../../types/Device';
import EmiconContext from '../../context/EmiconContext';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  formControl: {
    minWidth: 200,
  },
}));

interface EmiconVertragsArtenProps {
  serialNumber: string;
  isEmicon?: boolean;
}

export default function EmiconVertragsArten({ serialNumber, isEmicon = false }: EmiconVertragsArtenProps) {
  const classes = useStyles();
  const { currentDevice, setCurrentDevice } = useContext(EmiconContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const vertragsArtenDropdown = [
    'Kein Vertrag',
    'Basic',
    'Basic + Messung',
    'Full',
    'Full + Messung',
  ];
  const [vertragsArt, setVertragsArt] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVertragsArt(event.target.value as string);
  };

  async function handleSubmit() {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);

      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber,
          isEmicon,
          fields: {
            Servicevertrag: vertragsArt,
          },
        },
      });

      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        Servicevertrag: vertragsArt,
      }));

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (!serialNumber || serialNumber === undefined || serialNumber === null || serialNumber === '') return;
    setVertragsArt(currentDevice?.Servicevertrag ?? '');
  }, [serialNumber]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Vertragsart"
        action={vertragsArt ? (
          <Chip
            label={vertragsArt}
          />
        ) : null}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <FormControl className={classes.formControl}>
          <InputLabel id="vertragsart-label">Vertragsart</InputLabel>
          <Select
            labelId="vertragsart-label"
            value={vertragsArt}
            onChange={handleChange}
          >
            {vertragsArtenDropdown.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton
          isLoading={loading}
          text="Vertragsart aktualisieren"
          onClick={handleSubmit}
          disabled={!serialNumber}
        />
      </CardActions>
    </Card>
  );
}
