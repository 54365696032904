/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {
  useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ProtocolView from '../../../emilog/components/Setup/ProtocolView';
import ShadowViewEmicon from './ShadowViewEmicon';
import { EmiconShadow } from '../../../../types/Shadow';
import StoerungViewEmicon from './StoerungViewEmicon';
import HinweisViewEmicon from './HinweisViewEmicon';
import EmiconAnlagenInfo from '../Support/EmiconAnlagenInfo';
import { Emicon } from '../../../../types/Device';
import EmiconBerichtDeckblattInfo from '../Support/EmiconBerichtDeckblattInfo';
import ServiceInfoEmicon from './ServiceInfoEmicon';

const useStyles = makeStyles(() => ({
  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    // maxHeight: '697px', // TODO: anpassen ggf'80vh',
  },
}));

interface DataCardProps {
  serialNumber: string
  active: boolean
  isEmicon?: boolean
  reported: Partial<EmiconShadow> | null
  setReported: Function
  setReportedTimestamp: Function
  device: Emicon
  loadingShadow: boolean
  setLoadingShadow: Function
}

export default function DataCard({ serialNumber, active, isEmicon = false, reported, setReported, setReportedTimestamp, device, loadingShadow, setLoadingShadow }: DataCardProps) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [mode, setMode] = useState(0);

  const handleMenuOpen = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (_event: any, index: number) => {
    setMode(index);
    setMenuAnchor(null);
  };

  return (
    <>
      <Card className={classes.card} style={{ maxHeight: isEmicon ? 905.75 : 697 }}>
        {
          mode === 0 ? (
            <EmiconAnlagenInfo
              isViewOnly={false}
              device={device}
              handleMenuOpen={handleMenuOpen}
            />
          ) : mode === 1 ? (
            <EmiconBerichtDeckblattInfo
              isViewOnly={false}
              handleMenuOpen={handleMenuOpen}
              device={device}
              isEmicon={true}
            />
          ) : mode === 2 ? (
            <ServiceInfoEmicon
              device={device}
              handleMenuOpen={handleMenuOpen}
            />
          ) : mode === 5 ? (
            <ShadowViewEmicon
              serialNumber={serialNumber}
              active={active}
              reported={reported}
              setReported={setReported}
              handleMenuOpen={handleMenuOpen}
              setReportedTimestamp={setReportedTimestamp}
              loadingShadow={loadingShadow}
              setLoadingShadow={setLoadingShadow}

            />
          ) : mode === 6 ? (
            <ProtocolView
              handleMenuOpen={handleMenuOpen}
              serialNumber={serialNumber}
              isEmicon={isEmicon}
            />
          ) : mode === 7 ? (
            <StoerungViewEmicon
              handleMenuOpen={handleMenuOpen}
              serialNumber={serialNumber}
              isEmicon={isEmicon}
            />
          ) : mode === 8 ? (
            <HinweisViewEmicon
              handleMenuOpen={handleMenuOpen}
              serialNumber={serialNumber}
              isEmicon={isEmicon}
            />
          ) : (
            null
          )}
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem
          key={0}
          selected={mode === 0}
          onClick={(event) => handleMenuItemClick(event, 0)}
        >
          Anlageninfos
        </MenuItem>
        <MenuItem
          key={1}
          selected={mode === 1}
          onClick={(event) => handleMenuItemClick(event, 1)}
        >
          Deckblatt Informationen
        </MenuItem>
        <MenuItem
          key={2}
          selected={mode === 2}
          onClick={(event) => handleMenuItemClick(event, 2)}
        >
          Service Informationen
        </MenuItem>
        <MenuItem
          key={5}
          selected={mode === 5}
          onClick={(event) => handleMenuItemClick(event, 5)}
        >
          Shadow
        </MenuItem>
        <MenuItem
          key={6}
          selected={mode === 6}
          onClick={(event) => handleMenuItemClick(event, 6)}
        >
          Protokoll
        </MenuItem>
        <MenuItem
          key={7}
          selected={mode === 7}
          onClick={(event) => handleMenuItemClick(event, 7)}
        >
          Störungen
        </MenuItem>
        <MenuItem
          key={8}
          selected={mode === 8}
          onClick={(event) => handleMenuItemClick(event, 8)}
        >
          Alarme/Warnung
        </MenuItem>

      </Menu>
    </>
  );
}
