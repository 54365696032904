import React, { useContext, useState } from 'react';
import { API } from '@aws-amplify/api';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import EmiconContext from '../../context/EmiconContext';

interface DeleteButtonProps {
  serialNumber: string
}

export default function DeleteButton({ serialNumber }: DeleteButtonProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const {
    fetchDevices,
  } = useContext(EmiconContext);

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setDialogOpen(false);
    setSuccess(false);
  };

  async function handleSubmit() {
    try {
      setError(false);
      setLoading(true);
      await API.post('emilog', 'deleteDevice', { body: { serialNumber, isEmicon: true } });
      await fetchDevices();
      setTimeout(() => {
        setSuccess(true);
        setLoading(false);
        handleClose(null, '');
        history.push('/emicon/setup');
      }, 2000);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  return (
    <>
      <LoaderButton
        isLoading={false}
        text="EMI-CON löschen"
        variant="outlined"
        onClick={() => setDialogOpen(true)}
        disabled={serialNumber === undefined}
        noMargin
      />
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          EMI-CON
          {' '}
          {serialNumber}
          {' '}
          entfernen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sich sicher, dass Sie die EMI-CON entfernen wollen?
            Dies löscht die hinterlegten Einstellungen (Adresse, Auftrag, usw.),
            Messwerte sind von dieser Löschung nicht betroffen.
          </DialogContentText>

          <Feedback
            error={error}
            success={success}
            successText="Gerät erfolgreich entfernt"
            style={{ marginLeft: 0 }}
          />
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={handleClose}>Abbrechen</Button>
          <LoaderButton
            isLoading={loading}
            onClick={handleSubmit}
            text="Bestätigen"
            fullWidth={false}
          />

        </DialogActions>
      </Dialog>
    </>
  );
}
