import { Device, Emicon } from '../../types/Device';

/* eslint-disable no-param-reassign */
export default function filterData(data: Device[] | Emicon[], filter: string, filter2: string) {
  const filterLower = filter.toLowerCase();
  const filterLower2 = filter2.toLowerCase();

  // @ts-ignore Known TS issue
  const filtered = data.filter(
    (device: Emicon | Device) => {
      if (device.serialNumber === undefined) {
        console.log('unbekannt', device);
      }
      if (typeof device.Motorennummer === 'undefined') {
        device.Motorennummer = '';
      }
      if (typeof device.Betreiber === 'undefined') {
        device.Betreiber = '';
      }
      if (typeof device.Adresse === 'undefined') {
        device.Adresse = '';
      }
      if (typeof device.Auftrag === 'undefined') {
        device.Auftrag = '';
      }
      if (typeof device.Gasart === 'undefined') {
        device.Gasart = '';
      }
      //@ts-ignore
      if (typeof device.status === 'undefined') {
        //@ts-ignore
        device.status = '';
      }
      //@ts-ignore
      if (typeof device.nox_m_sort === 'undefined') {
        //@ts-ignore
        device.nox_m_sort = '';
      }
      //@ts-ignore
      if (typeof device.co_m_sort === 'undefined') {
        //@ts-ignore
        device.co_m_sort = '';
      }
      //@ts-ignore
      if (typeof device.versionsNr === 'undefined') {
        //@ts-ignore
        device.versionsNr = '';
      }
      //@ts-ignore
      if (typeof device.betriebsstunden === 'undefined') {
        //@ts-ignore
        device.betriebsstunden = '';
      }
      //@ts-ignore
      if (typeof device.ausfallstunden === 'undefined') {
        //@ts-ignore
        device.ausfallstunden = '';
      }
      if (typeof device.SoftwareVersion === 'undefined') {
        //@ts-ignore
        device.SoftwareVersion = '';
      }
      if (typeof device.AnlagenStatus === 'undefined') {
        //@ts-ignore
        device.AnlagenStatus = '';
      }
      if (typeof device.Servicevertrag === 'undefined') {
        //@ts-ignore
        device.Servicevertrag = '';
      }

      return (
        device.serialNumber?.toLowerCase()?.includes(filterLower)
        || device.Motorennummer?.toLowerCase()?.includes(filterLower)
        || device.Betreiber?.toLowerCase()?.includes(filterLower)
        || device.Adresse?.toLowerCase()?.includes(filterLower)
        || device.Auftrag?.toLowerCase()?.includes(filterLower)
        || device.Gasart?.toLowerCase()?.includes(filterLower)
        //@ts-ignore
        || device.status?.toLowerCase()?.includes(filterLower)
        //@ts-ignore
        || device.nox_m_sort?.toString()?.includes(filterLower)
        //@ts-ignore
        || device.co_m_sort?.toString()?.includes(filterLower)
        //@ts-ignore
        || device.versionsNr?.toLowerCase()?.includes(filterLower)
        //@ts-ignore
        || device.betriebsstunden?.toString()?.includes(filterLower)
        //@ts-ignore
        || device.ausfallstunden?.toString()?.includes(filterLower)
        //@ts-ignore
        || device.SoftwareVersion?.toLowerCase()?.includes(filterLower)
        //@ts-ignore
        || device.AnlagenStatus?.toLowerCase()?.includes(filterLower)
        //@ts-ignore
        || device.Servicevertrag?.toLowerCase()?.includes(filterLower));
    },
  );

  if (filter2 !== '' && filter !== '') {
    // @ts-ignore Known TS issue
    const filtered2 = filtered.filter(
      (device: Emicon | Device) => {
        if (device.serialNumber === undefined) {
          console.log('unbekannt', device);
        }
        if (typeof device.Motorennummer === 'undefined') {
          device.Motorennummer = '';
        }
        if (typeof device.Betreiber === 'undefined') {
          device.Betreiber = '';
        }
        if (typeof device.Adresse === 'undefined') {
          device.Adresse = '';
        }
        if (typeof device.Auftrag === 'undefined') {
          device.Auftrag = '';
        }
        if (typeof device.Gasart === 'undefined') {
          device.Gasart = '';
        }
        //@ts-ignore
        if (typeof device.status === 'undefined') {
          //@ts-ignore
          device.status = '';
        }
        //@ts-ignore
        if (typeof device.nox_m_sort === 'undefined') {
          //@ts-ignore
          device.nox_m_sort = '';
        }
        //@ts-ignore
        if (typeof device.co_m_sort === 'undefined') {
          //@ts-ignore
          device.co_m_sort = '';
        }
        //@ts-ignore
        if (typeof device.versionsNr === 'undefined') {
          //@ts-ignore
          device.versionsNr = '';
        }
        //@ts-ignore
        if (typeof device.betriebsstunden === 'undefined') {
          //@ts-ignore
          device.betriebsstunden = '';
        }
        //@ts-ignore
        if (typeof device.ausfallstunden === 'undefined') {
          //@ts-ignore
          device.ausfallstunden = '';
        }
        if (typeof device.SoftwareVersion === 'undefined') {
          //@ts-ignore
          device.SoftwareVersion = '';
        }
        if (typeof device.AnlagenStatus === 'undefined') {
          //@ts-ignore
          device.AnlagenStatus = '';
        }
        if (typeof device.Servicevertrag === 'undefined') {
          //@ts-ignore
          device.Servicevertrag = '';
        }

        return (
          device.serialNumber?.toLowerCase()?.includes(filterLower2)
          || device.Motorennummer?.toLowerCase()?.includes(filterLower2)
          || device.Betreiber?.toLowerCase()?.includes(filterLower2)
          || device.Adresse?.toLowerCase()?.includes(filterLower2)
          || device.Auftrag?.toLowerCase()?.includes(filterLower2)
          || device.Gasart?.toLowerCase()?.includes(filterLower2)
          //@ts-ignore
          || device.status?.toLowerCase()?.includes(filterLower2)
          //@ts-ignore
          || device.nox_m_sort?.toString()?.includes(filterLower2)
          //@ts-ignore
          || device.co_m_sort?.toString()?.includes(filterLower2)
          //@ts-ignore
          || device.versionsNr?.toLowerCase()?.includes(filterLower2)
          //@ts-ignore
          || device.betriebsstunden?.toString()?.includes(filterLower2)
          //@ts-ignore
          || device.ausfallstunden?.toString()?.includes(filterLower2)
          //@ts-ignore
          || device.SoftwareVersion?.toLowerCase()?.includes(filterLower2)
          //@ts-ignore
          || device.AnlagenStatus?.toLowerCase()?.includes(filterLower2)
          //@ts-ignore
          || device.Servicevertrag?.toLowerCase()?.includes(filterLower2));
      },
    );

    return filtered2
  }

  return filtered;
}
