import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import API from '@aws-amplify/api';
import { useLocation, useHistory } from 'react-router-dom';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16, // anders lösen, in Einstellungen
    },
  },
}));

export default function DeleteDevice() {
  const {
    setCurrentDevice, fetchDevices, currentDevice,
  } = useContext(DeviceContext);
  const active = currentDevice.serialNumber !== undefined;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  const [activated, setActivated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setActivated(event.target.checked);
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setOpen(false);
    setSuccess(false);
  };

  async function handleSubmit() {
    try {
      setError(false);
      setLoading(true);
      await API.post('emilog', 'deleteDevice', { body: { serialNumber: currentDevice.serialNumber } });
      setSuccess(true);
      setLoading(false);
      setCurrentDevice({});
      fetchDevices();
      setTimeout(() => {
        setActivated(false);
        handleClose(null, '');
        history.push(`/${path}`);
      }, 2000);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  function renderTitle() {
    return (
      <span className={classes.titleWrapper}>
        <span>EMI-LOG löschen</span>

      </span>
    );
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={(
            renderTitle()
          )}
          action={(
            <Switch
              color="primary"
              checked={activated}
              onChange={handleChange}
              disabled={!active}
            />
          )}
        />
        <Divider />
        <CardActions>
          <LoaderButton
            isLoading={false}
            text="Gerät entfernen"
            disabled={!activated}
            onClick={() => setOpen(true)}
            fullWidth
          />
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          EMI-LOG
          {' '}
          {currentDevice.serialNumber}
          {' '}
          entfernen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sich sicher, dass Sie die EMI-LOG entfernen wollen?
            Dies löscht die hinterlegten Einstellungen (DAC, Kalibrierung, usw.),
            Messwerte sind von dieser Löschung nicht betroffen.
          </DialogContentText>

          <Feedback
            error={error}
            success={success}
            successText="Gerät erfolgreich entfernt"
            style={{ marginLeft: 0 }}
          />
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={handleClose}>Abbrechen</Button>
          <LoaderButton
            isLoading={loading}
            onClick={handleSubmit}
            text="Bestätigen"
            fullWidth={false}
          />

        </DialogActions>
      </Dialog>
    </>
  );
}
