import React, {
  useEffect, useState, useCallback, useContext,
} from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import API from '@aws-amplify/api';

import convertTimestamp from '../../../utils/convertTimestamp';
import capitalize from '../../../utils/capitalize';
import DeviceContext from '../../../emilog/context/DeviceContext';
import { Box } from '@material-ui/core';

interface StoerungViewProps {
  handleMenuOpen: Function
  serialNumber: string
  isEmicon?: boolean
}

export interface SensorStoerung {
  sensorType: string
  errorType: string
  timestamp: number
}

export default function StoerungView({ handleMenuOpen, serialNumber, isEmicon = false }: StoerungViewProps) {
  const { currentDevice } = useContext(DeviceContext);

  const [data, setData] = useState<SensorStoerung[]>([]);
  const [loading, setLoading] = useState(false);

  const [noData, setNoData] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await API.get('emilog', 'getStoerungen', {
        queryStringParameters: {
          serialNumber,
          isEmicon,
        },
      });

      setLoading(false);
      setData(res);
    } catch (err: any) {
      setLoading(false);
      if (err.response.status === 404) {
        setNoData(true);
      }
      console.log(err);
    }
  }, [serialNumber, isEmicon]);

  useEffect(() => {
    setData([]);
    if (typeof serialNumber !== 'undefined') {
      fetch();
    }
  }, [serialNumber, fetch]);

  const renderCardTitle = () => (
    <>
      {/* @ts-ignore */}
      <ButtonBase onClick={handleMenuOpen}>
        <Typography variant="h5">Störungen</Typography>
        <KeyboardArrowDownIcon />
      </ButtonBase>
    </>
  );

  const renderRefreshButton = () => (
    <Button variant="outlined" onClick={fetch}>
      <RefreshIcon />
    </Button>
  );

  const renderList = () => {
    const list = data.map((entry) => {
      if (entry.sensorType === 'Druck' && currentDevice.Betreiber === 'Agrarservice Lass GmbH') return null;
      return (
        <React.Fragment key={`${entry.timestamp}-${entry.errorType}-${entry.sensorType}`}>

          <Grid item xs={4}>
            {convertTimestamp(entry.timestamp)}
          </Grid>
          <Grid item xs={4}>
            {entry.sensorType}
          </Grid>
          <Grid item xs={4}>
            {entry.errorType === 'sensor_defekt' ? (
              'Störung'
            ) : (
              capitalize(entry.errorType)
            )}
          </Grid>
        </React.Fragment>
      );
    });
    return list;
  };

  return (
    <>
      <CardHeader
        title={(renderCardTitle())}
        action={renderRefreshButton()}
      />
      <Divider />
      <CardContent>
        {loading === true ? (
          <>
            <Skeleton key="1" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="2" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="4" style={{ marginBottom: 10 }} variant="text" />
          </>
        ) : (

          <Box maxHeight={'660px'}>
            <Grid container spacing={1} >
              {noData ? (
                <Typography>Keine Daten vorhanden</Typography>
              ) : (
                <>
                  <>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Datum
                    </Grid>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Sensortyp
                    </Grid>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Fehlertyp
                    </Grid>
                  </>
                  {renderList()}
                </>
              )}
            </Grid>
          </Box>
        )}

      </CardContent>
    </>

  );
}
