/* eslint-disable no-console */
import React, { createContext, useMemo, useState } from 'react';
import { AvailableColumns } from '../../../types/Interfaces';

interface ContextProps {
  filter: string
  setFilter: Function
  filter2: string
  setFilter2: Function
  columns: {}
  setColumns: Function
  rowsPerPage: number
  setRowsPerPage: Function
  order: 'asc' | 'desc'
  setOrder: Function
  orderBy: string
  setOrderBy: Function
  page: number
  setPage: Function
}

const SearchContext = createContext<ContextProps>({
  filter: '',
  filter2: '',
  columns: {},
  order: 'asc',
  orderBy: 'MAC',
  page: 0,
  rowsPerPage: 10,
  setPage: () => { },
  setOrder: () => { },
  setOrderBy: () => { },
  setRowsPerPage: () => { },
  setFilter: () => { },
  setFilter2: () => { },
  setColumns: () => { },
});

/*
  Enthält Suchfilter und Auswahl der Seite, um diese auch beim Seitenwechsel zum Dashboard zurück
  zur Verfügung zu stellen
*/

function SearchProvider({ children }: { children: React.ReactNode[] | React.ReactNode }) {
  const [filter, setFilter] = useState('');
  const [filter2, setFilter2] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('MAC');
  const [columns, setColumns] = useState<AvailableColumns>({ // Columns für EMI-CON, EMI-LOG befindet sich in der Datei selbst
    Motorennummer: false,
    Betreiber: true,
    Adresse: false,
    Auftrag: false,
    Gasart: true,
    status: true,
    Servicevertrag: true,
    ServiceVertragsende: false,
    AnlagenStatus: true,
    ServiceGeplant: true,
    WartungGeplant: true,
    letzteWartung: true,
    letzterService: false,
    Messtermin: true,
    Messtermin_Alt: false,
    ausfalltagePercent: true,
    ausfallstundenPercent: false,
    ausfallstunden: false,
    DaysWithoutData: true,
    SoftwareVersion: true,
  });

  const value = useMemo(() => ({
    filter, setFilter, rowsPerPage, setRowsPerPage, page, setPage, order, setOrder, orderBy, setOrderBy, filter2, setFilter2, columns, setColumns
  }), [filter, rowsPerPage, page, order, orderBy, filter2, columns]);

  return (
    <SearchContext.Provider value={value}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContext;
export { SearchProvider };
