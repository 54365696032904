import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddCalUser from './AddCalUser';
import CalUserTable from './CalUserTable';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}));

export default function CalUser() {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'EMI-CAL Nutzer - EMI-LOG Adminportal';
  }, []);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item lg={6} xs={12}>
        <AddCalUser />
      </Grid>
      <Grid item lg={6} xs={12}>
        <CalUserTable />
      </Grid>
    </Grid>
  );
}
