import React, { useState, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, lighten, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExitToApp from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import BarChartIcon from '@material-ui/icons/BarChart';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import Auth from '@aws-amplify/auth';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { MenuBook } from '@material-ui/icons';
import banner from '../../assets/banner-mirrored.png';
import EmiconContext from '../context/EmiconContext';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  appbar: {
    padding: 8,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    background: `no-repeat url(${banner})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderBottom: '1px solid lightgray',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
      height: 64,
      backgroundPosition: '40% center',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      padding: 8,
    },
  },
  headLine: {
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      marginTop: 4,
    },
  },
  icon: {
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  email: {
    color: lighten('#000000', 0.5),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  active: {
    color: theme.palette.primary.main,
  },
  drawer: {
    width: '20vw',
    [theme.breakpoints.down('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80vw',
    },
    height: '100vh',
  },
  menuButton: {
    position: 'absolute',
    top: 8,
    left: 16,
    [theme.breakpoints.down('sm')]: {
      top: -4,
    },
    [theme.breakpoints.down('md')]: {
      top: 0,
      left: 0,
    },
  },
  iconButton: {
    fontSize: '2rem',
  },
  menuLink: {
    textDecoration: 'none',
    color: 'black',
  },
  menuLinkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  titleLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    marginLeft: 8,
  },
}));

interface NavLink {
  path: string
  icon: React.ReactElement<SvgIconProps>
  name: string
  dividerBefore?: Boolean
}

const navLinks: NavLink[] = [
  {
    path: '/emicon',
    icon: <HomeIcon />,
    name: 'Übersicht',
  },
  {
    path: '/emicon/uebersicht', // Wird Übersicht alles Aktuelle ohne Änderungen - View Only
    icon: <VisibilityIcon />,
    name: 'Übersicht EMI-CON',
  },
  {
    path: '/emicon/service', // Alle Service Komponenten
    icon: <BuildIcon />,
    name: 'Service',
  },
  {
    path: '/emicon/support', // Alle Parameter Einstellungen
    icon: <SettingsIcon />,
    name: 'Support',
  },
  {
    path: '/emicon/visualisierung',
    icon: <BarChartIcon />,
    name: 'Visualisierung',
  },
  {
    path: '/emicon/logbuch',
    icon: <MenuBook />,
    name: 'Logbuch',
  },
];

export default function MainLayout({ children }: { children: React.ReactChild }) {
  const classes = useStyles();
  const { pathname } = useLocation();

  const history = useHistory();
  // const { currentUser } = useContext(AuthContext);
  const { currentDevice } = useContext(EmiconContext);
  const { serialNumber } = currentDevice;
  const [drawerOpen, setDrawerOpen] = useState(false);

  function logout(event: any) {
    if (event) {
      event.preventDefault();
    }
    Auth.signOut();
    history.push('/login');
  }
  function handleClose() {
    setDrawerOpen(false);
  }

  function goToEmilog() {
    setDrawerOpen(false);
    history.push('/');
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="static" className={classes.appbar}>
        <IconButton
          onClick={() => setDrawerOpen(true)}
          className={classes.menuButton}
          classes={{
            root: classes.iconButton,
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <Typography variant="h3" color="primary" align="center" className={classes.headLine}>
          <Link to="/emicon" className={classes.titleLink}>
            EMI-CON Adminportal
          </Link>
        </Typography>

      </AppBar>
      <main className={classes.container}>
        {children}
      </main>
      <Drawer open={drawerOpen} onClose={handleClose}>
        <List className={classes.drawer}>
          <div className={classes.menuLinkContainer}>
            <div>

              {navLinks.map(({
                icon, path, name, dividerBefore,
              }) => {
                let route = path;
                if (typeof serialNumber !== 'undefined' && serialNumber !== '' && ['/emicon/visualisierung', '/emicon/uebersicht', '/emicon/service', '/emicon/support', '/emicon/logbuch'].includes(path)) {
                  route = `${path}/${serialNumber}`;
                }
                return (
                  <React.Fragment key={name}>
                    {dividerBefore && (<Divider />)}
                    <Link to={route} className={classes.menuLink} onClick={handleClose}>
                      <ListItem button selected={route === pathname}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{name}</ListItemText>
                      </ListItem>
                    </Link>
                  </React.Fragment>
                );
              })}
            </div>
            <div>
              <Divider />
              <ListItem button onClick={goToEmilog}>
                <ListItemIcon><DoubleArrowIcon /></ListItemIcon>
                <ListItemText>Wechsel zu EMI-LOG</ListItemText>
              </ListItem>
              <ListItem button onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText>Abmelden</ListItemText>
              </ListItem>
            </div>
          </div>
        </List>
      </Drawer>
    </div>
  );
}
