import React, {
  useState, useEffect, useContext,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import filterData from '../../../utils/filterData';
import { sortData, getSorting } from '../../../utils/sortTable';

import DashboardTableHead from './DashboardTableHead';
import DashboardTableToolbar from './DashboardTableToolbar';
import Row from './Row';

import DeviceContext from '../../context/DeviceContext';
import SearchContext from '../../context/SearchContext';
import { DeviceWithShadow } from '../../../../types/Device';
import { AvailableColumns } from '../../../../types/Interfaces';

const useStyles = makeStyles((theme) => ({
  tablePaper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
    },
  },
  paginationActions: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiIconButton-root': {
        padding: 0,
      },
      marginLeft: 8,
    },
  },
  paginationToolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  paginationInput: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 8,
    },
  },
}));

function DashboardTable() {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:720px)');
  const { devices, loading } = useContext(DeviceContext);

  const {
    filter, rowsPerPage, setRowsPerPage, page, setPage, orderBy, setOrderBy, order, setOrder,
  } = useContext(SearchContext);

  const [filteredData, setFilteredData] = useState<DeviceWithShadow[]>([]);

  const [columns, setColumns] = useState<AvailableColumns>({
    Motorennummer: false,
    Betreiber: true,
    Adresse: false,
    Auftrag: false,
    Gasart: true,
    status: true,
    Servicevertrag: false,
    ServiceVertragsende: false,
    AnlagenStatus: true,
    ServiceGeplant: true,
    WartungGeplant: true,
    letzterService: true,
    letzteWartung: true,
    Messtermin: true,
    Messtermin_Alt: false,
    ausfalltagePercent: true,
    ausfallstundenPercent: false,
    // DaysWithoutData: true,
    // SoftwareVersion: true,
    Messbericht_bis: false,
    versionsNr: false,
    versionsDatum: false,
    betriebsstunden: false,
    ausfallstunden: false,
    co_m_sort: false,
    nox_m_sort: false,
  });

  const handleRequestSort = (_event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleColumnChange = (target: any) => {
    setColumns({
      ...columns,
      [target.name]: target.checked,
    });
  };

  useEffect(() => {
    setFilteredData(filterData(devices, filter, ''));
  }, [filter, devices]);

  useEffect(() => {
    setPage(0);
  }, [filter, setPage]);


  // const foundDevice = devices.find(device => device.serialNumber === 'AC67B2D69417');

  // if (foundDevice) {
  //   console.log({ foundDevice });
  // }

  return (
    <Paper className={classes.tablePaper}>
      <DashboardTableToolbar
        devices={filteredData}
        handleColumnChange={handleColumnChange}
        columns={columns}
      />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          size="medium"
          aria-label="emilog-tabelle"
        >
          <DashboardTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {loading ? (
              [...Array(10)].map((_i, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={`placeholder-${index}`}>
                  <TableCell colSpan={Object.keys(columns).length + 1}> {/* Alle Spalten + Serial */}
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                {(sortData(filteredData, getSorting(order, orderBy)) as DeviceWithShadow[])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((rowData) => (
                    <Row
                      data={rowData}
                      key={rowData.MAC}
                      columns={columns}
                    />
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      <div className={classes.tableFooter}>
        {!mobile && (
          <div style={{ alignSelf: 'center' }}>
            * in mg/Nm³ - Trocken, 5 % Referenzsauerstoffgehalt
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Geräte pro Seite"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            actions: classes.paginationActions,
            toolbar: classes.paginationToolbar,
            input: classes.paginationInput,
          }}
        />
      </div>
    </Paper>
  );
}

export default React.memo(DashboardTable);
