/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';
import { blue } from '@material-ui/core/colors';

import DeviceContext from '../../context/DeviceContext';
import convertDate from '../../../utils/convertDate';
import formatStatus from '../../../utils/formatStatus';
import { DeviceWithShadow } from '../../../../types/Device';
import { AvailableColumns } from '../../../../types/Interfaces';
import { roundShadowValue } from '../../../utils/roundShadowValue';

function convertVersionTime(timestamp: any) {
  if (typeof timestamp === 'undefined' || timestamp === 'unbekannt') {
    return '-';
  }
  const date = new Date(timestamp);
  if (Number.isNaN(date)) return '-';
  return date.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

const useStyles = makeStyles(() => ({
  cellWidth: {
    minWidth: '20vh',
  },
  shorten: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '20vh',
    display: 'inline-block',
  },
  link: {
    textDecoration: 'inherit',
    color: blue[900],
  },

}));

interface RowProps {
  data: DeviceWithShadow
  columns: AvailableColumns
}

function Row({
  data, columns,
}: RowProps) {
  const {
    MAC,
    Motorennummer,
    Adresse,
    Gasart,
    Betreiber,
    Auftrag,
    co_m,
    nox_m,
    status,
    versionsNr,
    versionsDatum,
    Messbericht_bis,
    betriebsstunden, // alt aus Shadow
    ausfallstunden,// alt aus Shadow
    timestamps,
    letzteWartung,
    letzterService,
    AnlagenStatus,
    Messtermin,
    Messtermin_Alt,
    Servicevertrag,
    ServiceVertragsende,
    ServiceGeplant,
    WartungGeplant,
    Ausfalltage,
    Betriebstage,
    Ausfallstunden,
    Betriebsstunden
    // DaysWithoutData,
  } = data;

  function renderValue(value: number | undefined | null) {
    if (typeof value === 'undefined' || value === null) {
      return '-';
    }
    const hideValue = status === 'offline' || status === 'passiv';
    if (hideValue || !Number.isFinite(value) || typeof value === 'undefined') {
      return '-';
    }
    return Math.round(value);
  }

  const { setCurrentDevice } = useContext(DeviceContext);

  const classes = useStyles();

  function handleLinkClick() {
    setCurrentDevice(data);
  }

  function checkVals(ausfallstunden: number | undefined, betriebsstunden: number | undefined) {
    if (ausfallstunden === null || ausfallstunden === undefined) {
      ausfallstunden = 0;
    }
    if (betriebsstunden === null || betriebsstunden === undefined) {
      betriebsstunden = 0;
    }
    if (ausfallstunden === 0 && betriebsstunden === 0) {
      return '-';
    }
    return `${roundShadowValue((ausfallstunden / betriebsstunden) * 100, 2)} %`;
  }

  return (
    <TableRow
      tabIndex={-1}
      key={MAC}
    >
      <TableCell>
        <span style={{ position: 'relative' }}>
          <Link
            to={{ pathname: `/setup/${MAC}` }}
            className={classes.link}
            onClick={handleLinkClick}
          >
            {MAC}
          </Link>
        </span>
      </TableCell>

      {columns.Motorennummer && (
        <TableCell
          align="left"
          className={classes.cellWidth}
        >
          <Tooltip title={Motorennummer || ''}>
            <span className={classes.shorten}>
              {Motorennummer ? Motorennummer : '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.Betreiber && (
        <TableCell
          align="left"
          className={classes.cellWidth}
        >
          <Tooltip title={Betreiber || ''}>
            <span className={classes.shorten}>
              {Betreiber || 'unbekannt'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.Adresse && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={Adresse || ''}>
            <span className={classes.shorten}>
              {Adresse || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.Auftrag && (
        <TableCell
          className={classes.cellWidth}
          align="left"
        >
          <Tooltip title={Auftrag || ''}>
            <span className={classes.shorten}>
              {Auftrag || '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.Gasart && (
        <TableCell align="left">
          {Gasart || 'unbekannt'}
        </TableCell>
      )}

      {columns.status && (
        <Tooltip
          title={`Status: ${convertDate(timestamps?.status.timestamp)}` || '-'}
        >
          <TableCell>
            <span>{formatStatus(status as Status) ?? '-'}</span>
          </TableCell>
        </Tooltip>
      )}

      {columns.Servicevertrag
        && (
          <TableCell>
            {Servicevertrag ? Servicevertrag : 'Kein Vertrag'}
          </TableCell>
        )}

      {columns.ServiceVertragsende && (
        <TableCell>
          {ServiceVertragsende ? convertDate(ServiceVertragsende) : '-'}
        </TableCell>
      )}

      {columns.AnlagenStatus && (
        AnlagenStatus === 'In_Bearbeitung' ? (
          <TableCell>
            In Bearbeitung
          </TableCell>
        ) : (
          <TableCell>
            -
          </TableCell>
        )
      )}

      {columns.ServiceGeplant && (
        <TableCell
          align="left"
          className={classes.cellWidth}
        >
          <Tooltip title={ServiceGeplant || ''}>
            <span className={classes.shorten}>
              {ServiceGeplant && ServiceGeplant > 0 ? convertDate(ServiceGeplant) : '-'}
            </span>
          </Tooltip>
        </TableCell>
      )}

      {columns.WartungGeplant && (
        <TableCell>
          {WartungGeplant && WartungGeplant > 0 ? convertDate(WartungGeplant) : '-'}
        </TableCell>
      )}

      {columns.letzterService && (
        <TableCell align="left">
          {convertDate(letzterService) || '-'}
        </TableCell>
      )}

      {columns.letzteWartung && (
        <TableCell align="left">
          {convertDate(letzteWartung) || '-'}
        </TableCell>
      )}

      {columns.Messtermin && (
        <TableCell>
          {convertDate(Messtermin) || '-'}
        </TableCell>
      )}

      {columns.Messtermin_Alt && (
        <TableCell>
          {convertDate(Messtermin_Alt) || '-'}
        </TableCell>
      )}

      {columns.ausfalltagePercent && (
        <TableCell>
          {checkVals(Ausfalltage, Betriebstage)}
        </TableCell>
      )}

      {columns.ausfallstundenPercent && (
        <TableCell>
          {checkVals(Ausfallstunden, Betriebsstunden)}
        </TableCell>
      )}

      {/* {columns.DaysWithoutData && (
        <TableCell>
          {DaysWithoutData}
        </TableCell>
      )} */}

      {columns.Messbericht_bis && (
        <TableCell align="left">
          {convertDate(Messbericht_bis) || '-'}
        </TableCell>
      )}

      {columns.versionsNr && (
        <TableCell align="left">
          {versionsNr || '-'}
        </TableCell>
      )}

      {columns.versionsDatum && (
        <TableCell align="left">
          {convertVersionTime(versionsDatum) || '-'}
        </TableCell>
      )}

      {columns.betriebsstunden && (
        <TableCell align="right">
          {Betriebsstunden ?? '-'}
        </TableCell>
      )}

      {columns.ausfallstunden && (
        <TableCell align="right">
          {Ausfallstunden ?? '-'}
        </TableCell>
      )}

      {columns.nox_m_sort && (
        <TableCell align="right">
          {renderValue(nox_m)}
        </TableCell>
      )}

      {columns.co_m_sort && (
        <TableCell align="right">
          {renderValue(co_m)}
        </TableCell>
      )}

    </TableRow>
  );
}

export default React.memo(Row, isEqual);
