import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import deLocale from 'date-fns/locale/de';
import useStateRef from 'react-usestateref';
import API from '@aws-amplify/api';
import DialogContentText from '@material-ui/core/DialogContentText';
import DateFnsUtils from '@date-io/date-fns';
import LoaderButton from '../LoaderButton';
// import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

interface ReportDialogProps {
  open: boolean
  onClose: Function
  serialNumber: string
  isEmicon?: boolean
}

async function downloadFile(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.setProperty('display', 'none');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function ReportDialog({
  open, onClose, serialNumber, isEmicon = false,
}: ReportDialogProps) {
  const [type, setType] = useState('');

  const [minDate, setMinDate] = useState<Date>(new Date());
  const [minYear, setMinYear] = useState<Date>(new Date());

  const [genericError, setGenericError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate, selectedDateRef] = useStateRef(new Date());
  const [lastDate, setLastDate] = useState(new Date());

  const [timeframeError, setTimeframeError] = useState(false); // State to track timeframe errors

  // const timeframeError = false;
  const dateIsInTheFuture = new Date() <= selectedDate;

  async function handleSubmit(event: any) {
    event.preventDefault();
    try {
      setGenericError(false);
      setLoading(true);

      let pdfRes;
      let filename = '';
      switch (type) {
        case 'year': {
          filename = `${serialNumber}_Jahresbericht.pdf`;
          const firstDatum = new Date(selectedDate.getFullYear(), 0, 1);
          firstDatum.setHours(0, 0, 0, 0);

          const lastDatum = new Date(selectedDate.getFullYear(), 11, 31);
          lastDatum.setHours(23, 59, 59, 999);

          pdfRes = await API.get('emilog', 'jahresBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              isEmicon,
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }
        case 'month': {
          filename = `${serialNumber}_Monatsbericht.pdf`;
          const firstDatum = new Date(selectedDate);
          firstDatum.setDate(1);
          firstDatum.setHours(0, 0, 0, 0);

          const lastDatum = new Date(selectedDate);
          /* This increases the month of lastDatum by 1 and sets the day to 0. When you set the day to 0 in JavaScript,
          it sets the date to the last day of the previous month. Essentially, this makes lastDatum the last day of the month of selectedDate. */
          lastDatum.setMonth(lastDatum.getMonth() + 1, 0); // Setzt auf den letzten Tag des aktuellen Monats
          lastDatum.setHours(23, 59, 59, 999); // Setzt Stunden, Minuten, Sekunden und

          pdfRes = await API.get('emilog', 'monatsBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              isEmicon,
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }

        case 'mess': {
          filename = `${serialNumber}_Messbericht.pdf`;

          const firstDatum = new Date(selectedDate.getTime());
          firstDatum.setHours(0, 0, 0, 0);

          let lastDatum = new Date(lastDate.getTime());
          lastDatum.setHours(0, 0, 0, 0); // Ensure this is set to midnight too
          
          if (firstDatum.getTime() === lastDatum.getTime()) {
            lastDatum = new Date(); // Set lastDatum to current date and time
          } 

          /* Dieser teil macht für Mess und Jahresberichte wenig sinn, eher wie weiter oben bei Monats bzw. Jahresbericht,
          aber dort wird eh ein Punkt in der Zukunft genommen und alles dazwischen zu queryen. TODO in andere Portale */

          // const today = new Date();
          // lastDatum.setDate(1);
          // lastDatum.setMonth(lastDatum.getMonth() + 1);
          // lastDatum.setHours(0, 0, 0, 0);
          // if (today.getMonth() === lastDate.getMonth() && today.getFullYear() === lastDate.getFullYear()) {
          //   lastDatum = new Date();
          // }

          pdfRes = await API.get('emilog', 'messBericht', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              isEmicon,
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }
        case 'parameter': {
          filename = `${serialNumber}_Parameterprotokoll.pdf`;

          const firstDatum = new Date(selectedDate.getTime());
          firstDatum.setHours(0, 0, 0, 0); // Sets to midnight
          
          let lastDatum = new Date(lastDate.getTime());
          lastDatum.setHours(0, 0, 0, 0); // Ensure this is set to midnight too
          
          if (firstDatum.getTime() === lastDatum.getTime()) {
            lastDatum = new Date(); // Set lastDatum to current date and time
          } 

          // const today = new Date();
          // lastDatum.setDate(1);
          // lastDatum.setMonth(lastDatum.getMonth() + 1);
          // lastDatum.setHours(0, 0, 0, 0);
          // if (today.getMonth() === lastDate.getMonth() && today.getFullYear() === lastDate.getFullYear()) {
          //   lastDatum = new Date();
          // }

          pdfRes = await API.get('emilog', 'parameterProtokoll', {
            response: true,
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
            queryStringParameters: {
              isEmicon,
              serialNumber,
              firstTimestamp: firstDatum.getTime(),
              lastTimestamp: lastDatum.getTime(),
            },
          });
          break;
        }

        default:
          break;
      }

      await downloadFile(pdfRes.data, filename);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setGenericError(true);
    }
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      setLastDate(new Date())
      return;
    }
    setLastDate(new Date())
    setType('');
    onClose();
  };

  function handleDateChange(event: Date) {
    setGenericError(false);
    if (event) {
      // Ensure the date is not in the future
      const now = new Date();
      if (event > now) {
        setSelectedDate(now);
        setTimeframeError(true);
        return;
      }

      setSelectedDate(event);
      if (type === 'year') {
        selectedDateRef.current.setMonth(0);
        setSelectedDate(selectedDateRef.current);
      }
      // Ensure end date is at least as late as the start date
      if (lastDate < event) {
        setLastDate(event);
      }
    }
  }

  function handleLastDateChange(event: Date) {
    if (event) {
      const now = new Date();
      if (event > now) {
        setLastDate(now);
        setTimeframeError(true);
        return;
      }
      // Ensure the end date is not before the start date
      if (event >= selectedDate) {
        setLastDate(event);
        setTimeframeError(false);
      } else {
        setTimeframeError(true);
      }
    }
  }

  useEffect(() => {
    if (type === 'mess') {
      setSelectedDate(minDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Bericht herunterladen</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Wählen Sie zuerst aus, welche Art eines Berichtes Sie generieren wollen.
              Daraufhin können Sie den gewünschten Zeitraum angeben.
            </DialogContentText>
            <Grid container spacing={2}>

              <Grid item xs={12} md={6} lg={4}>
                <InputLabel id="month-label">Berichtsart</InputLabel>
                <Select
                  labelId="month-label"
                  id="month-select"
                  value={type}
                  onChange={(event) => setType(event.target.value as unknown as string)}
                  autoWidth
                  disabled={loading}
                >
                  <MenuItem key="" value="" />
                  <MenuItem key="month" value="month">Monatsbericht</MenuItem>
                  <MenuItem key="year" value="year">Jahresbericht</MenuItem>
                  <MenuItem key="mess" value="mess">Messbericht</MenuItem>
                  {!isEmicon ? (
                    <MenuItem key="parameter" value="parameter">Parameterprotokoll</MenuItem>
                  ) : (
                    null
                  )}
                </Select>
              </Grid>

              {type === 'month' && (
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    disabled={loading}
                    cancelLabel="Abbrechen"
                    disableToolbar
                    autoOk
                    maxDate={lastDate || new Date()}
                    // variant="inline"
                    format="MM/yyyy"
                    margin="dense"
                    id="date-picker-inline"
                    label="Monat / Jahr"
                    value={selectedDate}
                    // onChange={(e) => handleDateChange(e)}
                    // @ts-ignore
                    onChange={handleDateChange}
                    invalidDateMessage="Kein korrektes Format"
                    openTo="year" // Open date picker to the year selection view
                    views={['year', 'month']} // Allow year, month, and date selection
                  />
                </Grid>
              )}
              {type === 'year' ? (
                <Grid item xs={12} md={6} lg={4}>
                  <KeyboardDatePicker
                    disabled={loading}
                    cancelLabel="Abbrechen"
                    disableToolbar
                    autoOk
                    maxDate={lastDate || new Date()}
                    // variant="inline"
                    format="yyyy"
                    margin="dense"
                    id="date-picker-inline"
                    label="Jahr"
                    value={selectedDate}
                    // onChange={(e) => handleDateChange(e)}
                    // @ts-ignore
                    onChange={handleDateChange}
                    invalidDateMessage="Kein korrektes Format"
                    openTo="year" // Open date picker to the year selection view
                    views={['year']} // Allow year, month, and date selection
                  />
                </Grid>
              ) : (type === 'mess' || type === 'parameter') ? (
                <>
                  <Grid item xs={12} md={6} lg={4}>
                    <KeyboardDatePicker
                      disabled={loading}
                      cancelLabel="Abbrechen"
                      disableToolbar
                      autoOk
                      maxDate={new Date()} // Max date cannot be in the future
                      format="dd/MM/yyyy"
                      margin="dense"
                      id="date-picker-inline-from"
                      label="Von"
                      value={selectedDate}
                      // @ts-ignore
                      onChange={handleDateChange}
                      invalidDateMessage="Kein korrektes Format"
                      openTo="year" // Open date picker to the year selection view
                      views={['year', 'month', 'date']} // Allow year, month, and date selection
                      maxDateMessage="Start des Zeitraums darf nicht nach dem Ende liegen"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <KeyboardDatePicker
                      disabled={loading}
                      cancelLabel="Abbrechen"
                      disableToolbar
                      autoOk
                      minDate={selectedDate} // Min date should not be before the selected start date
                      maxDate={new Date()} // Max date cannot be in the future
                      format="dd/MM/yyyy"
                      margin="dense"
                      id="date-picker-inline-to"
                      label="Bis"
                      value={lastDate}
                      // @ts-ignore
                      onChange={handleLastDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      invalidDateMessage="Kein korrektes Format"
                      openTo="year" // Open date picker to the year selection view
                      views={['year', 'month', 'date']} // Allow year, month, and date selection
                      minDateMessage="Ende des Zeitraums darf nicht vor dem Beginn liegen"
                      maxDateMessage="Datum kann nicht in der Zukunft liegen"
                    />
                  </Grid>
                </>
              ) : (
                null
              )}
              {genericError && (
                <Grid item>
                  <Typography color="error">Etwas ist schiefgelaufen</Typography>
                </Grid>
              )}
              {noData && (
                <Grid item>
                  <Typography color="error">Keine Daten vorhanden</Typography>
                </Grid>
              )}

            </Grid>
          </DialogContent>
          <DialogActions>
            {/* @ts-ignore */}
            <Button onClick={handleClose} disabled={loading}>Schließen</Button>
            <LoaderButton
              isLoading={loading}
              text="Download"
              fullWidth={false}
              variant="text"
              disabled={loading || (timeframeError && (type === 'mess' || type === 'parameter')) || dateIsInTheFuture || type === ''}
            />
          </DialogActions>
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>

  );
}

export default React.memo(ReportDialog);
