import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export default function FormInput({
  id, label, type, onChange, autoFocus,
  InputProps, helperText, error, ...props
}) {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id={id}
      label={label}
      name={id}
      autoFocus={autoFocus}
      onChange={onChange}
      type={type}
      InputProps={InputProps}
      helperText={helperText}
      error={error}
      {...props}
    />
  );
}

FormInput.defaultProps = {
  required: true,
  autoFocus: false,
  type: 'text',
  InputProps: null,
  helperText: '',
  error: false,
};
FormInput.propTypes = {
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  /* eslint-disable-next-line */
  InputProps: PropTypes.objectOf(PropTypes.object),
  helperText: PropTypes.string,
  error: PropTypes.bool,
};
