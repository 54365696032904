import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

interface DelteAttachmentDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  statusType: any;
  message: string
}

export default function StatusSnackbar({
  open, setOpen, statusType, message,
}: DelteAttachmentDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={7000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={statusType}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
