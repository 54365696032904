import React from 'react';
import { Amplify } from '@aws-amplify/core';

import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsConfig from './awsConfig';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: awsConfig.cognito.REGION,
    userPoolId: awsConfig.cognito.USER_POOL_ID,
    identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    endpoints: [
      {
        name: 'emilog',
        endpoint: awsConfig.apiGateway.URL,
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'transferLambda',
        endpoint: awsConfig.transferLambda.URL,
        service: 'lambda',
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'protocolLambda',
        endpoint: awsConfig.protocolLambda.URL,
        service: 'lambda',
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'csvLambda',
        endpoint: awsConfig.csvLambda.URL,
        service: 'lambda',
        region: awsConfig.apiGateway.REGION,
      },
      {
        name: 'resetDataLambda',
        endpoint: awsConfig.resetDataLambda.URL,
        service: 'lambda',
        region: awsConfig.apiGateway.REGION,
      },
    ],
  },
});

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: awsConfig.cognito.REGION,
  aws_pubsub_endpoint: 'wss://a2cg0yo8jbddsk-ats.iot.eu-central-1.amazonaws.com/mqtt', // aus AWS IoT Core
}));

// window.LOG_LEVEL = 'DEBUG';
const container = document.getElementById('root');
ReactDOM.createRoot(container!).render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
