import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';

import API from '@aws-amplify/api';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import LoaderButton from './LoaderButton';

interface CsvDialogProps {
  onClose: Function
  serialNumber: string
  open: boolean
  isEmicon?: boolean
}

function CsvDialog({
  onClose, serialNumber, open, isEmicon = false,
}: CsvDialogProps) {
  const [datumVon, setDatumVon] = useState<ParsableDate | null>(null);
  const [datumBis, setDatumBis] = useState<ParsableDate | null>(null);
  const [csvNoData, setCsvNoData] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCsvClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setCsvNoData(false);
    onClose();
  };

  const handleCsvSubmit = async () => {
    const filename = `EMI-${isEmicon ? 'CON' : 'LOG'}_${serialNumber}`;
    try {
      let res;
      setLoading(true);
      setCsvError(false);
      setCsvNoData(false);
      if (datumBis && datumVon) {
        res = await API.get('emilog', 'csv', {
          queryStringParameters: {
            serialNumber,
            datumVon: Math.round(Date.parse(datumVon as string) / 1000),
            datumBis: Math.round(Date.parse(datumBis as string) / 1000),
            timezone: new Date().getTimezoneOffset(),
            isEmicon,
          },
        });
      } else {
        res = await API.get('emilog', 'csv', {
          queryStringParameters: {
            serialNumber,
            timezone: new Date().getTimezoneOffset(),
            isEmicon,
          },
        });
      }
      setLoading(false);
      setCsvError(false);
      setCsvNoData(false);
      //  @ts-ignore
      if (navigator.msSaveBlob) { // IE 10+
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
        //  @ts-ignore
        navigator.msSaveBlob(blob, `${filename}.csv`);
      } else {
        const uri = `data:text/csv;charset=utf-8,${encodeURIComponent(res)}`; // Deprecated escape() durch encodeURIComponent() getauscht
        const link = document.createElement('a');
        link.href = uri;
        link.download = `${filename}.csv`;
        link.click();
      }
    } catch (err: any) {
      setLoading(false);
      console.log(err);
      if (err?.response?.status === 404) {
        setCsvNoData(true);
      } else {
        setCsvError(true);
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <Dialog open={open} onClose={handleCsvClose}>
        <DialogTitle>
          CSV-Download für Messgerät
          {' '}
          {serialNumber}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Geben sie optional einen gewünschten Zeitraum an. Ist kein Zeitraum angegeben,
            werden alle verfügbaren Daten heruntergeladen.
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                maxDate={datumBis || new Date()}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Von"
                value={datumVon}
                onChange={(e) => setDatumVon(e)}
                invalidDateMessage="Kein korrektes Format"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                minDate={datumVon}
                maxDate={new Date()}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Bis"
                value={datumBis}
                onChange={(e) => setDatumBis(e)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage="Kein korrektes Format"
              />
            </Grid>
            {csvError ? (
              <Grid item xs={12} md={12} lg={12}>
                <Typography color="error">Etwas ist schiefgelaufen</Typography>
              </Grid>
            ) : null}
            {csvNoData ? (
              <Grid item xs={12} md={12} lg={12}>
                <Typography color="error">Keine Daten gefunden</Typography>
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={handleCsvClose} disabled={loading}>Schließen</Button>
          <LoaderButton
            isLoading={loading}
            onClick={handleCsvSubmit}
            text="Download"
            fullWidth={false}
            variant="text"
          />
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}

export default CsvDialog;
