import { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Refresh from '@material-ui/icons/Refresh';
import { PubSub } from '@aws-amplify/pubsub';
import { Box, Switch, Theme, Typography } from '@material-ui/core';
import { API } from '@aws-amplify/api';
import useStateRef from 'react-usestateref';
import { MqttSubscription } from '../../../../types/Interfaces';
import { MqttResponseEmicon, EmiconShadow, ShadowMetadata } from '../../../../types/Shadow';
import { roundShadowValue } from '../../../utils/roundShadowValue';
import returnDefaultEmiconShadow from '../../../utils/returnDefaultEmiconShadow';

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },
  alignEnd: {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface ShadowUpdateButtonProps {
  serialNumber: string
  active: boolean
  setReported: Function
  setReportedTimestamp: Function
  setLoadingShadow: Function
  isSupportPage?: boolean
  setUpdateReportedShadow?: Function
  liveShadow?: boolean
  setLiveShadow?: Function
  shouldFetchShadow?: boolean
  setShouldFetchShadow?: Function
  useBlocker?: boolean
}


export default function ShadowUpdateButton({
  serialNumber,
  active,
  setReported,
  setReportedTimestamp,
  isSupportPage = false,
  setUpdateReportedShadow,
  setLoadingShadow,
  liveShadow,
  setLiveShadow,
  shouldFetchShadow,
  setShouldFetchShadow,
  useBlocker = true,
}: ShadowUpdateButtonProps) {

  /* Logik ist weitestgehend die gleiche wie in ShadowViewEmicon */

  const classes = useStyles();

  const subLive = useRef<MqttSubscription | null>(null);
  const subGet = useRef<MqttSubscription | null>(null);

  const [loadingLiveData, setLoadingLiveData, loadingLiveDataRef] = useStateRef(false);

  async function requestLiveValues() {
    PubSub.publish(`EMI-CON_${serialNumber}/EMI-CON/AWSDaten`, true);
    PubSub.publish(`$aws/things/EMI-CON_${serialNumber}/shadow/get`, {});
  }

  function checkShadowValue(shadow: any, key1: string, key2: string, defaultValue: undefined | 'NaN') {
    if (Object.hasOwn(shadow, key1)) {
      shadow[key1] = shadow[key1];
    } else if (Object.hasOwn(shadow, key2)) {
      shadow[key1] = shadow[key2];
    } else {
      shadow[key1] = defaultValue;
    }
  }

  async function fetchShadow() {
    try {
      const res = await API.get('emilog', 'getShadow', {
        queryStringParameters: {
          isEmicon: true,
          serialNumber,
        },
      }) as MqttResponseEmicon;

      if (res.state.reported !== undefined && res.state.reported !== null) {
        checkShadowValue(res.state.reported, 'status', 'sStatus', 'NaN');
        checkShadowValue(res.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
        checkShadowValue(res.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
        checkShadowValue(res.state.reported, 'sVersion', 'version', 'NaN');
      }

      setLoadingShadow(false);

      /* Werte aus dem Shadow direkt auf 2 Nachkommastellen runden */

      for (const key in res.state.reported) {
        if (Object.prototype.hasOwnProperty.call(res.state.reported, key)) {
          // Cast the key as a key of EmiconShadow
          const typedKey = key as keyof EmiconShadow;
          const value = res.state.reported[typedKey];

          if (typeof value === 'number') {
            // Safely assign the rounded value
            res.state.reported[typedKey] = Number(roundShadowValue(value, 2)) as any; // Cast to 'any' to bypass assignment issue
          }

        }
      }

      const defaultEmiconShadow = returnDefaultEmiconShadow();

      if (res.state.reported.sSeriennummer === '' || res.state.reported.sSeriennummer === 'NaN') {
        res.state.reported.sSeriennummer = serialNumber as string; // Aktuelle Seriennummer überschreiben wenn noch nicht vorhanden oder NaN
      }

      if (res.state.reported.Var_RelO2 === undefined) {
        res.state.reported.Var_RelO2 = defaultEmiconShadow.Var_RelO2; // Aktuelle Bezugssauerstoff überschreiben wenn noch nicht vorhanden oder NaN
      }

      setReported((prevState: Partial<EmiconShadow>) => ({
        ...defaultEmiconShadow,
        ...prevState,
        ...res.state.reported,
      }));


      if (setUpdateReportedShadow && isSupportPage) {
        setUpdateReportedShadow((prevState: Partial<EmiconShadow>) => ({
          ...defaultEmiconShadow,
          ...prevState,
          ...res.state.reported,
        }));
      }

      setReportedTimestamp(
        res.metadata.reported?.status?.timestamp ??
        res.metadata.reported?.sStatus?.timestamp ??
        0
      );
    } catch (error: any) {
      console.log(error);
      setLoadingShadow(false);
      setReported(null);
    }
  }

  function mapMqttResponse(value: MqttResponseEmicon) {
    if (value.state.reported !== undefined && value.state.reported !== null) {
      checkShadowValue(value.state.reported, 'status', 'sStatus', 'NaN');
      checkShadowValue(value.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
      checkShadowValue(value.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
      checkShadowValue(value.state.reported, 'sVersion', 'version', 'NaN');
    }

    /* Werte aus dem Shadow direkt auf 2 Nachkommastellen runden */

    for (const key in value.state.reported) {
      if (Object.prototype.hasOwnProperty.call(value.state.reported, key)) {
        // Cast the key as a key of EmiconShadow
        const typedKey = key as keyof EmiconShadow;
        const val = value.state.reported[typedKey];

        if (typeof val === 'number') {
          // Safely assign the rounded value
          value.state.reported[typedKey] = roundShadowValue(val, 2) as any; // Cast to 'any' to bypass assignment issue
        }
      }
    }

    setReported((prevState: Partial<EmiconShadow>) => ({
      ...prevState,
      ...value.state.reported,
    }));

    setReportedTimestamp(
      value.metadata.reported?.status?.timestamp ??
      value.metadata.reported?.sStatus?.timestamp ??
      0
    );
  }

  useEffect(() => {
    if (subLive.current) subLive.current!.unsubscribe();
    if (subGet.current) subGet.current!.unsubscribe();
    setReported({});
    if (active && serialNumber) {
      setLoadingShadow(true);
      fetchShadow();
      subLive.current = PubSub.subscribe(`$aws/things/EMI-CON_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value);
        },
        error: ((err) => {
          console.log(err);
        }),
        complete: () => {
          console.log('Subscription completed Update');
        },
      });
    }
    return function cleanUp() {
      if (subLive.current) subLive.current.unsubscribe();
      if (subGet.current) subGet.current.unsubscribe();
    };
  }, [active, serialNumber]);

  useEffect(() => {
    if (useBlocker === true) {
      return
    }
    if (liveShadow === true) {
      return
    }
    if (active && serialNumber) {
      // console.log("Shadow wird gefetcht - ShadowUpdateButton");
      setLoadingShadow(true);
      fetchShadow();
    }
  }, [shouldFetchShadow, liveShadow]);

  return (
    <Box className={classes.alignEnd} >

      {setLiveShadow &&
        <>
          <Typography variant="h5">Livewerte</Typography>
          <Switch
            color="primary"
            checked={liveShadow}
            onClick={() => setLiveShadow((prevVal: boolean) => !prevVal)}
            disabled={!active}
          />
        </>
      }
      <Button
        disabled={loadingLiveData}
        onClick={requestLiveValues}
        className={loadingLiveData ? classes.fetchLiveDataButton : undefined}
        color="primary"
        variant="outlined"
        startIcon={<Refresh />}
      >
        Update
      </Button>
    </Box>

  );
}
