import React, {
  useState, useEffect, useContext,
} from 'react';

import API from '@aws-amplify/api';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';

import DeviceContext from '../../context/DeviceContext';

const useStyles = makeStyles((theme) => ({
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(2),
  },
  infoText: {
    padding: 4,
    paddingLeft: theme.spacing(2),
  },
  boldFont: {
    fontWeight: 900,
  },
}));

interface Klassierung {
  KlassierungNOx: {
    s1: number
    s2: number
    s3: number
    s4: number
    s5: number
  }
  KlassierungCO: {
    s1: number
    s2: number
    s3: number
    s4: number
    s5: number
  }
  KlassierungTemp: {
    s1: number
    s2: number
    s3: number
    s4: number
    s5: number
  }
}

function KlassierungTable() {
  const classes = useStyles();

  const {
    currentDevice,
  } = useContext(DeviceContext);

  const [data, setData] = useState<Klassierung | undefined>(undefined);

  useEffect(() => {
    async function fetch() {
      try {
        setData(undefined);
        if (typeof currentDevice?.MAC !== 'undefined') {
          const res = await API.get('emilog', 'getKlassierung', { queryStringParameters: { serialNumber: currentDevice.MAC } });
          setData(res);
        }
      } catch (err) {
        console.log(err);
        // setNoData(true);
      }
    }
    fetch();
  }, [currentDevice]);

  return (
    <Paper className={classes.tablePaper}>
      <Table
        size="medium"
        aria-label="emilog-tabelle"
      >
        <TableHead>
          <TableRow>
            <TableCell>Klasse</TableCell>
            <TableCell align="right">NOx</TableCell>
            <TableCell align="right">CO</TableCell>
            <TableCell align="right">Temperatur</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>S1</TableCell>
            <TableCell align="right">{data?.KlassierungNOx?.s1 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungCO?.s1 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungTemp?.s1 ?? '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>S2</TableCell>
            <TableCell align="right">{data?.KlassierungNOx?.s2 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungCO?.s2 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungTemp?.s2 ?? '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>S3</TableCell>
            <TableCell align="right">{data?.KlassierungNOx?.s3 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungCO?.s3 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungTemp?.s3 ?? '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>S4</TableCell>
            <TableCell align="right">{data?.KlassierungNOx?.s4 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungCO?.s4 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungTemp?.s4 ?? '-'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>S5</TableCell>
            <TableCell align="right">{data?.KlassierungNOx?.s5 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungCO?.s5 ?? '-'}</TableCell>
            <TableCell align="right">{data?.KlassierungTemp?.s5 ?? '-'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ marginTop: 8, marginBottom: 8 }}>

        <div className={classes.infoText}>

          <Typography display="inline" className={classes.boldFont}>
            S1
          </Typography>
          <Typography variant="body2" display="inline">
            {' '}
            Gültiger TMW: Einhaltung Grenzwert
          </Typography>
        </div>
        <div className={classes.infoText}>

          <Typography display="inline" className={classes.boldFont}>
            S2
          </Typography>
          <Typography variant="body2" display="inline">
            {' '}
            Gültiger TMW: Keine Einhaltung Grenzwert / Einhaltung Toleranz
          </Typography>
        </div>
        <div className={classes.infoText}>

          <Typography display="inline" className={classes.boldFont}>
            S3
          </Typography>
          <Typography variant="body2" display="inline">
            {' '}
            Ungültiger TMW: Keine Einhaltung Grenzwert / Keine Einhaltung Toleranz
          </Typography>
        </div>
        <div className={classes.infoText}>

          <Typography display="inline" className={classes.boldFont}>
            S4
          </Typography>
          <Typography variant="body2" display="inline">
            {' '}
            Kein TMW: Zu geringe Datenlage (# HMW &lt; 6)
          </Typography>
        </div>
        <div className={classes.infoText}>

          <Typography display="inline" className={classes.boldFont}>
            S5
          </Typography>
          <Typography variant="body2" display="inline">
            {' '}
            Gültiger TMW: Aus Ersatzwerten gebildet
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

export default React.memo(KlassierungTable);

/*
zeitraum
grenzwerte

*/
