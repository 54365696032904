/* eslint-disable no-console */

import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Button, Chip, Switch, useMediaQuery } from '@material-ui/core';

import LoaderButton from './LoaderButton';

import ProtocolDialog from './ProtocolDialog';

import RebootButton from './Setup/RebootButton';
import SelectSearch from './SelectSearch';
import DeviceContext from '../context/DeviceContext';
import BerichteButton from './Setup/BerichteButton';
import EmiconContext from '../../emicon/context/EmiconContext';
import CsvButton from './CsvButton';
import ProtocolDialogEmicon from '../../emicon/components/Support/ProtocolDialogEmicon';
import { Device, Emicon } from '../../../types/Device';
import handleAnlagenStatusNew from '../../utils/handleAnlagenstatus';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  header: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 6,
    },
    marginBottom: theme.spacing(1),
  },
  selectWrapper: {
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  loader: {
    position: 'absolute',
    left: 302,
    top: 7,
    [theme.breakpoints.down('xs')]: {
      margin: '8px 10px',
      top: -2,
      left: 175,
    },
  },

  buttonWrapper: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      marginTop: -2,
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: -2,
    },
  },

  controlWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },

  buttonInGrid: {
    // padding: '0px 16opx', //TODO? What the fuck is this?
    padding: '0px 16px',
  },

  shadowInputButton: {
    fontSize: '16px',
    margin: '2px',
    height: '40px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputDarkButton: {
    background: '#014a69',
    color: 'white !important'
  },

  chipColor: {
    background: '#38b6f1',
    color: 'black',
  },

  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

}));

interface SetupHeaderProps {
  hideButtons?: boolean
  children?: React.ReactElement[] | null
  showBetreiber?: boolean
  isEmicon?: boolean
  hasBearbeitung?: boolean
  isMotorlast?: boolean
  setIsMotorlast?: Function
}

export default function SetupHeader({
  hideButtons = false, children = null, showBetreiber = false, isEmicon = false, hasBearbeitung = false, isMotorlast, setIsMotorlast = () => { },
}: SetupHeaderProps) {

  const {
    currentDevice, loading, setCurrentDevice, fetchDevices
    // @ts-ignore
  } = useContext(isEmicon ? EmiconContext : DeviceContext);

  async function submitAnlagenstatus() {
    const newState = await handleAnlagenStatusNew(currentDevice, isEmicon);

    if (newState?.AnlagenStatus) {
      if (isEmicon) {
        setCurrentDevice((prevState: Emicon) => ({ // Direkt auf aktueller Seite updaten
          ...prevState,
          ...newState, 
        }));
        fetchDevices(); // Neuen State aus db laden
      } else {
        setCurrentDevice((prevState: Device) => ({ // Direkt auf aktueller Seite updaten
          ...prevState,
          ...newState, // Spread the properties of newState directly
        }));
        fetchDevices(); // Neuen State aus db laden
      }
    }
  }

  const classes = useStyles();

  const mobile = useMediaQuery('(max-width:600px)');

  const [protocolOpen, setProtocolOpen] = useState(false);
  const [protocolOpenEmicon, setProtocolOpenEmicon] = useState(false);


  function openProtocolDialog(isEmicon: boolean) {
    if (isEmicon) {
      setProtocolOpenEmicon(true)
    } else {
      setProtocolOpen(true)
    }
  }

  const renderProtocolButton = () => (
    <div className={classes.buttonWrapper}>
      <LoaderButton
        isLoading={false} // Öffnet nur Dialog
        text="↓ Protokoll"
        disabled={typeof currentDevice?.serialNumber === 'undefined' || loading}
        onClick={() => openProtocolDialog(isEmicon)}
        noMargin
        fullWidth
      />
    </div>
  );

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentDevice.serialNumber]);

  const renderHeader = () => (

    <Grid container spacing={2} justifyContent={mobile ? 'space-between' : 'flex-start'} alignItems="center">
      <Grid item xs="auto" sm="auto" md="auto" lg="auto">
        <Typography variant="h5" display="inline">
          EMI-
          {isEmicon ? 'CON' : 'LOG'}
        </Typography>
      </Grid>
      <Grid item xs={12} sm="auto" md="auto" lg="auto">
        <SelectSearch loading={loading} isEmicon={isEmicon} />
      </Grid>
      {showBetreiber ? (
        <Grid item>
          <Typography variant="h6" display="inline">
            {currentDevice.Betreiber}
          </Typography>
        </Grid>
      ) : (
        null
      )}
      {!hideButtons ? (
        <>
          <Grid item className={classes.buttonInGrid} xs={6} sm={6} md="auto" lg="auto">
            <CsvButton serialNumber={currentDevice?.serialNumber} isEmicon={isEmicon} />
          </Grid>
          <Grid item className={classes.buttonInGrid} xs={6} sm={6} md="auto" lg="auto">
            <BerichteButton serialNumber={currentDevice?.serialNumber} isEmicon={isEmicon} />
          </Grid>

          {isEmicon ? (
            <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
              {renderProtocolButton()}
            </Grid>
          ) : (
            <>
              <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
                {renderProtocolButton()}
              </Grid>
              <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
                <RebootButton serialNumber={currentDevice ? currentDevice.serialNumber : ''} active={typeof currentDevice?.serialNumber !== 'undefined'} />
              </Grid>

            </>
          )}

          {/* Bearbeitungstatus Buttons*/}

          {hasBearbeitung ? (
            <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  disabled={!currentDevice?.serialNumber}
                  onClick={submitAnlagenstatus}
                >
                  {currentDevice?.AnlagenStatus === 'In_Bearbeitung' ? 'Bearbeitung Abschließen' : 'Bearbeitung Aktivieren'}
                </Button>
              </div>
            </Grid>
          ) : (
            null
          )}

          {/* Bearbeitungsanzeige */}

          {currentDevice?.AnlagenStatus === 'In_Bearbeitung' ? (
            <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
              <Chip
                className={classes.chipColor}
                label={'STATUS: IN BEARBEITUNG'}
              />
            </Grid>
          ) : (
            null
          )}


          {!isEmicon ? (
            <Grid item className={classes.buttonInGrid} xs={12} sm={6} md="auto" lg="auto">
              <Box className={classes.switchWrapper}>
                {/* <Switch
                  color="primary"
                  checked={isMotorlast}
                  onChange={() => setIsMotorlast((prevValue: boolean) => !prevValue)} // Pass a function reference
                />
                <Chip
                  label={isMotorlast ? 'Motorlastsignal wird genutzt' : 'Motorlastsignal wird nicht genutzt'}
                /> */}
                <Chip
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: 8 }}>
                        MOTORLASTSIGNAL
                      </span>
                      <Switch
                        color="primary"
                        checked={isMotorlast}
                        onChange={() => setIsMotorlast((prevValue: boolean) => !prevValue)} // Pass a function reference
                      />
                    </div>
                  }
                />
              </Box>
            </Grid>
          ) : (
            null
          )}

        </>
      ) : (
        children
      )}
    </Grid>
  );

  return (

    <div className={classes.header}>
      {renderHeader()}
      <ProtocolDialog
        serialNumber={currentDevice?.serialNumber}
        open={protocolOpen}
        onClose={() => setProtocolOpen(false)}
      />
      <ProtocolDialogEmicon
        isEmicon
        serialNumber={currentDevice?.serialNumber}
        open={protocolOpenEmicon}
        onClose={() => setProtocolOpenEmicon(false)}
      />
    </div>

  );
}
