import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';

// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Refresh from '@material-ui/icons/Refresh';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import { PubSub } from '@aws-amplify/pubsub';
// import cloneDeep from 'lodash.clonedeep';
import { Theme } from '@material-ui/core';
import { API } from '@aws-amplify/api';
import { saveAs } from 'file-saver';

import useStateRef from 'react-usestateref';
import LoaderButton from '../../../emilog/components/LoaderButton';
import { MqttSubscription } from '../../../../types/Interfaces';
import { MqttResponseEmicon, EmiconShadow, ShadowMetadata } from '../../../../types/Shadow';
import { roundShadowValue } from '../../../utils/roundShadowValue';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryHeader: {
    fontWeight: 900,
  },
  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  '@keyframes loadingBlink': {
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },

  shadowInput: {
    fontSize: '13px',
    margin: '2px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputSm: {
    width: '100px',
  },

  shadowInputXSm: {
    width: '115px',
  },

  shadowInputMd: {
    width: '150px',
  },

  shadowInputLg: {
    width: '175px',
  },

  shadowInputXl: {
    width: '200px',
  },

  shadowInputXxl: {
    width: '250px',
  },

  shadowInputDark: {
    background: '#014a69',
    color: 'white !important'
  },

}));

async function untilConditionIsTrue(conditionFunction: () => boolean) {
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else setTimeout(() => poll(resolve), 100);
  };

  return new Promise(poll);
}

interface ShadowViewProps {
  serialNumber: string
  active: boolean
  reported: Partial<EmiconShadow> | null
  setReported: Function
  handleMenuOpen: Function
  setReportedTimestamp: Function
  loadingShadow: boolean
  setLoadingShadow: Function
}

export default function ShadowViewEmicon({
  serialNumber, active, reported, setReported, handleMenuOpen, setReportedTimestamp, loadingShadow, setLoadingShadow
}: ShadowViewProps) {
  const classes = useStyles();

  const subLive = useRef<MqttSubscription | null>(null);
  const subGet = useRef<MqttSubscription | null>(null);

  // const [loading, setLoading] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  const [loadingLiveData, setLoadingLiveData, loadingLiveDataRef] = useStateRef(false);

  async function requestLiveValues() {
    PubSub.publish(`EMI-CON_${serialNumber}/EMI-CON/AWSDaten`, true);
    PubSub.publish(`$aws/things/EMI-CON_${serialNumber}/shadow/get`, {});
  }

  function checkShadowValue(shadow: any, key1: string, key2: string, defaultValue: undefined | 'NaN') {
    if (Object.hasOwn(shadow, key1)) {
      shadow[key1] = shadow[key1];
    } else if (Object.hasOwn(shadow, key2)) {
      shadow[key1] = shadow[key2];
    } else {
      shadow[key1] = defaultValue;
    }
  }

  async function fetchShadow() {
    try {
      const res = await API.get('emilog', 'getShadow', {
        queryStringParameters: {
          isEmicon: true,
          serialNumber,
        },
      }) as MqttResponseEmicon;

      if (res.state.reported !== undefined && res.state.reported !== null) {
        checkShadowValue(res.state.reported, 'status', 'sStatus', 'NaN');
        checkShadowValue(res.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
        checkShadowValue(res.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
        checkShadowValue(res.state.reported, 'sVersion', 'version', 'NaN');
      }

      setLoadingShadow(false);

      /* Wird hier nicht benötigt, da die rundung direkt im return teil vollzogen wird */

      // for (const key in res.state.reported) {
      //   if (Object.prototype.hasOwnProperty.call(res.state.reported, key)) {
      //     // Cast the key as a key of EmiconShadow
      //     const typedKey = key as keyof EmiconShadow;
      //     const value = res.state.reported[typedKey];

      //     console.log(typedKey); // Log the key
      //     console.log(value);     // Log the value

      //     if (typeof value === 'number') {
      //       // Safely assign the rounded value
      //       res.state.reported[typedKey] = roundShadowValue(value, 2) as any; // Cast to 'any' to bypass assignment issue
      //     }
      //   }
      // }

      setReported((prevState: Partial<EmiconShadow>) => ({
        ...prevState,
        ...res.state.reported,
      }));
      setReportedTimestamp(
        res.metadata.reported?.status?.timestamp ??
        res.metadata.reported?.sStatus?.timestamp ??
        0
      );
    } catch (error: any) {
      console.log(error);
      setLoadingShadow(false);
      setReported(null);
    }
  }

  function mapMqttResponse(value: MqttResponseEmicon) {
    if (value.state.reported !== undefined && value.state.reported !== null) {
      checkShadowValue(value.state.reported, 'status', 'sStatus', 'NaN');
      checkShadowValue(value.state.reported, 'Var_RelO2', 'o2_bezug', undefined);
      checkShadowValue(value.state.reported, 'sSeriennummer', 'seriennummer', 'NaN'); // not sure about this one...
      checkShadowValue(value.state.reported, 'sVersion', 'version', 'NaN');
    }
    setReported((prevState: Partial<EmiconShadow>) => ({
      ...prevState,
      ...value.state.reported,
    }));
    setReportedTimestamp(
      value.metadata.reported?.sStatus?.timestamp ??
      value.metadata.reported?.status?.timestamp ??
      0
    );
  }

  useEffect(() => {
    if (subLive.current) subLive.current!.unsubscribe();
    if (subGet.current) subGet.current!.unsubscribe();
    setReported({});

    if (active && serialNumber) {
      setLoadingShadow(true);
      fetchShadow();
      subLive.current = PubSub.subscribe(`$aws/things/EMI-CON_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          mapMqttResponse(value);
        },
        error: ((err) => {
          console.log(err);
        }),
        complete: () => {
          console.log('Subscription completed Update');
        },
      });
    }
    return function cleanUp() {
      if (subLive.current) subLive.current.unsubscribe();
      if (subGet.current) subGet.current.unsubscribe();
    };
  }, [active, serialNumber]);

  async function onPdfDownload(event: any) {
    event.preventDefault();
    setPdfLoading(true);
    try {
      // requestLiveValues();
      await untilConditionIsTrue(() => loadingLiveDataRef.current === false);
      const res = await API.get('emilog', 'getShadowPdf', {
        response: true,
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
        queryStringParameters: {
          serialNumber,
          timezone: new Date().getTimezoneOffset(),
          // isEmicon: true,
        },
      });
      const fileName = `${serialNumber}_Shadow.pdf`;
      saveAs(res.data, fileName);

      setPdfLoading(false);
    } catch (err: any) {
      console.error(err);
      setPdfLoading(false);
    }
  }

  return (
    <Card className={classes.card} style={{ maxHeight: 1000 }}>

      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Shadow</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
            <Box>
              {/* <LoaderButton   // TODO muss in API aufgenommen werden oder hier entfernt werden. API unterstützt isEmicon nicht.
                variant="outlined"
                isLoading={pdfLoading}
                text="↓ PDF"
                onClick={onPdfDownload}
              /> */}
              <Button
                disabled={loadingLiveData}
                onClick={requestLiveValues}
                className={loadingLiveData ? classes.fetchLiveDataButton : undefined}
                color="primary"
                variant="outlined"
                style={{ marginLeft: 16 }}
                startIcon={<Refresh />}
              >
                Update
              </Button>
            </Box>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      {serialNumber && !loadingShadow && reported !== null ? (
        // <>
        <Box maxHeight='660px'>
          {/* Basis-Werte */}
          <Box margin="16px 8px 16px 8px">
            {/* SERIENNUMMER */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="Seriennummer"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={reported.sSeriennummer ?? 'nicht verfügbar'}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* LEISTUNG MOTOR */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="EI Leistung Motor"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={roundShadowValue(reported.Var_EnPel, 1)}
                endAdornment={<InputAdornment position="end">kW</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* MOTORLAST */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="Einschaltbedingung Motorlast"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={roundShadowValue(reported.Var_EnLoad_Min, 1)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* DOSIERVOLUMEN */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="Maximales Dosiervolumen"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={roundShadowValue(reported.Var_V_UWS_Max, 1)}
                endAdornment={<InputAdornment position="end">l/h</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* HARNSTOFFANTEIL */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="Harnstoffanteil in HWL"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={roundShadowValue(reported.Var_MassFrac_Urea, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* NOX-ZIELWERT */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
                value="NOx-Zielwert"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXl}`}
                value={roundShadowValue(reported.Var_NOx_Target_mgO2, 1)}
                endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
          </Box>
          <Divider />

          {/* NOX-Werte */}
          <Box margin="16px 8px 16px 8px">
            <Box>
              <OutlinedInput
                disabled
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value=""
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="Ist (ppm | %)"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
                value="Ist Kalibriert (ppm | %)"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="Verstärkung"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXSm}`}
                value="Offset (ppm | %)"
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* NOX-ein */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="NOx ein"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported?.Var_NOx_In_ppm, 1)}
                aria-describedby="outlined-weight-helper-text" // Yo hier
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_NOx_In_ppmc, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported.Var_M_NOx_In, 3)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
                value={roundShadowValue(reported.Var_B_NOx_In, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* NOX-aus */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="NOx aus"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported?.Var_NOx_Out_ppm, 1)}
                aria-describedby="outlined-weight-helper-text" // Yo hier könnte NOx_Out_ppm_c eher so aussehen Var_NOx_Out_ppmc
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue((reported.NOxOutppmc ?? reported.Var_NOx_Out_ppmc), 1)} // Neu wie in Support / Alt / Ausfallwert
                aria-describedby="outlined-weight-helper-text" // Yo hier
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported.Var_M_NOx_Out, 3)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
                value={roundShadowValue(reported.Var_B_NOx_Out, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* O2-ein */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="O2 ein"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported?.Var_O2_In ?? reported?.Var_O2_In_c, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_O2_In_c, 1)} // Gleicher Wert wie vorheriges Feld? -Y Nur wenn alt (wahrscheinlich?)
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported.Var_M_O2_In, 3)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
                value={roundShadowValue(reported.Var_B_O2_In, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* O2-aus */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputSm}`}
                value="O2 aus"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported?.Var_O2_Out ?? reported.Var_O2_In_c, 1)} // Gleicher Wert wie bei O2 Ein?
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_O2_Out_c, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
                value={roundShadowValue(reported.Var_M_O2_Out, 3)}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputXSm}`}
                value={roundShadowValue(reported.Var_B_O2_Out, 1)}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* <Box>
              <OutlinedInput
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              />
              <OutlinedInput
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
              />
              <OutlinedInput
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              />
              <OutlinedInput
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputSm}`}
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXSm}`}
                value={reported.Var_M_CO ? 'CO vorhanden' : 'CO nicht vorhanden'} // TODO!!!
                aria-describedby="outlined-weight-helper-text"
              />
            </Box> */}
          </Box>

          <Divider />

          {/* Druckluft-Werte */}
          <Box margin="16px 8px 16px 8px">
            <Box>
              <OutlinedInput
                disabled
                style={{ visibility: 'hidden' }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value=""
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
                value="Parameter Werte"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputMd}`}
                value="Standard Werte"
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* Druckluft-Minimum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="Druckluft Minimum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pLuftdruckMin, 1)}
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported?.pLuftdruckMinDef ?? 0.5, 1) ?? '0.5'} // Throws N/A currently
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* Druckluft-Maximum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="Druckluft Maximum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pLuftdruckMax, 1)}
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pLuftdruckMaxDef ?? 3.5, 1)}
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* HWL Druck Minimum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="HWL Druck Minimum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pHWLDruckMin, 1)}
                endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pHWLDruckMinDef ?? 10.0, 1)}
                endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* HWL Druck Maximum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="HWL Druck Maximum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pHWLDruckMax, 1)}
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pHWLDruckMaxDef ?? 5.0, 1)}
                endAdornment={<InputAdornment position="end">bar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* Abgas Temperatur Minimum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="Abgas Temperatur Minimum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pTempAbgasMin, 1)}
                endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pTempAbgasMinDef ?? 250.0, 1)}
                endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* Abgas Temperatur Maximum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="Abgas Temperatur Maximum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pTempAbgasMax, 1)}
                endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pTempAbgasMaxDef ?? 550.0, 1)}
                endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* HWL Füllstand Minimum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="HWL Füllstand Minimum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pNiveauMin, 1)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pNiveauMinDef ?? 0, 1)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* HWL Füllstand Warnung */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="HWL Füllstand Warnung"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pNiveauWarn, 1)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pNiveauWarnDef ?? 10.0, 1)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
            {/* Differenzdruck Maximum */}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="Differenzdruck Maximum"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pDiffdruckMax, 1)}
                endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.pDiffdruckMaxDef ?? 45.0, 1)}
                endAdornment={<InputAdornment position="end">mbar</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* NOx In Warnung*/}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="NOx In Warnung"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_NoxIn_warn, 1)}
                endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_NoxIn_warnDef ?? 500.0, 1)}
                endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>

            {/* NOx in Alarm*/}
            <Box>
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXl}`}
                value="NOx In Alarm"
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value={roundShadowValue(reported.Var_NOxIn_alarm, 1)}
                endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
              <OutlinedInput
                disabled
                inputProps={{ style: { paddingLeft: 8 } }}
                className={`${classes.shadowInput} ${classes.shadowInputMd}`}
                value="500.0"
                endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
              />
            </Box>
          </Box>



        </Box>
        // </>
      ) : reported === null ? (
        <Box margin="16px 8px 16px 8px">
          <Typography>Beim Auslesen des Shadows ist ein Fehler aufgetreten!</Typography>
        </Box>
      ) : (
        <Box margin="16px 8px 16px 8px">
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={220} />
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={220} />
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={220} />
        </Box>

      )}
    </Card>
  );
}
