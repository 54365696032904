import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import DAC from './DAC';
import ResetStunden from './ResetStunden';
import ResetWerte from './ResetDaten';
import Transfer from './Transfer';
import DeleteDevice from './DeleteDevice';
import Wartung from './Wartung';

import SetupHeader from '../SetupHeader';

import Messbericht from './Messbericht';
import { useRouting } from '../../../utils/useRouting';
import ServiceGeplant from './ServiceGeplant';
import EmiconMesstermin from '../../../emicon/components/Einstellungen/EmiconMesstermin';
import API from '@aws-amplify/api';
import WartungAbgeschlossen from './WartungAbgeschlossen';
import WartungGeplant from './WartungGeplant';
import ServiceAbgeschlossen from './ServiceAbgeschlossen';
import EmiconVertragsArten from '../../../emicon/components/Einstellungen/EmiconVertragsArten';
import EmiconVertragsEnde from '../../../emicon/components/Einstellungen/EmiconVertragsEnde';
import EmiconVertragsKommentar from '../../../emicon/components/Einstellungen/EmiconVertragsKommentar';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  content: {
    marginTop: theme.spacing(1),
    // flex: 1,
  },
  container: {
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90vw',
    },
  },
  doubleCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default function Einstellungen() {
  const {
    currentDevice, setCurrentDevice, fetchDevices
  } = useRouting('Service', 'emilog');

  const active = typeof currentDevice?.MAC !== 'undefined';
  const classes = useStyles();

  const [hasWartungPlanned, setHasWartungPlanned] = useState<boolean | null>(null);
  const [lastWartungPlannedDate, setLastWartungPlannedDate] = useState<number | null>(null);

  const [hasServicePlanned, setHasServicePlanned] = useState<boolean | null>(null);
  const [lastServicePlannedDate, setLastServicePlannedDate] = useState<number | null>(null);

  // TODO: API Call zusamemn legen und beides aufeinmal returnen

  async function checkHasActiveWartung() {
    if (currentDevice.serialNumber === null || currentDevice.serialNumber === undefined) {
      return;
    }
    const result = await API.post('emilog', 'hasServicePlanned', {
      body: {
        serialNumber: currentDevice.serialNumber,
        isEmicon: false,
        isWartung: true // ACHTUNG
      },
    });
    setHasWartungPlanned(result.hasWartungPlanned ?? false);
    setLastWartungPlannedDate(result.lastWartungPlannedDate ?? 0);
  }

  async function checkHasActiveService() {
    if (currentDevice.serialNumber === null || currentDevice.serialNumber === undefined) {
      return;
    }
    const result = await API.post('emilog', 'hasServicePlanned', {
      body: {
        serialNumber: currentDevice.serialNumber,
        isEmicon: false,
      },
    });
    setHasServicePlanned(result.hasServicePlanned ?? false);
    setLastServicePlannedDate(result.lastServicePlannedDate ?? 0);
  }

  useEffect(() => {
    checkHasActiveWartung();
    checkHasActiveService();
  }, [currentDevice.serialNumber, fetchDevices]); // Experimentel

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader hasBearbeitung={true} />
      <Grid container spacing={2} className={classes.content} justifyContent="center">

        <Grid item lg={6} className={classes.expandingCard}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <WartungGeplant
                hasWartungPlanned={hasWartungPlanned}
                lastWartungPlannedDate={lastWartungPlannedDate}
              />
            </Grid>

            <Grid item xs={12}>
              <ServiceGeplant hasServicePlanned={hasServicePlanned} lastServicePlannedDate={lastServicePlannedDate} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12} className={classes.expandingCard}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <WartungAbgeschlossen
                hasWartungPlanned={hasWartungPlanned}
                lastWartungPlannedDate={lastWartungPlannedDate}
                serialNumber={currentDevice?.MAC ?? ''}
                active={active}
              />
            </Grid>
            <Grid item xs={12}>
              <ServiceAbgeschlossen
                hasServicePlanned={hasServicePlanned}
                lastServicePlannedDate={lastServicePlannedDate}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12} className={classes.expandingCard}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <ResetStunden
                active={active}
                serialNumber={currentDevice?.MAC}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12} className={classes.expandingCard}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} lg={12}>
              <Transfer
                serialNumber={currentDevice?.MAC}
                active={active}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <EmiconMesstermin
            serialNumber={currentDevice?.MAC ?? ''}
            isEmicon={false}
            currentDevice={currentDevice}
            setCurrentDevice={setCurrentDevice}
            fetchDevices={fetchDevices}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <DAC />
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} lg={12}>
              <ResetWerte />
            </Grid>
            <Grid item xs={12} lg={12} style={{ alignSelf: 'flex-end' }}>
              <DeleteDevice />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Messbericht />
        </Grid>

        <Grid item xs={12} lg={6}>
          <EmiconVertragsArten
            serialNumber={currentDevice?.MAC ?? ''}
            isEmicon={false}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <EmiconVertragsEnde
            serialNumber={currentDevice?.MAC ?? ''}
            isEmicon={false}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <EmiconVertragsKommentar
            serialNumber={currentDevice?.MAC ?? ''}
            isEmicon={false}
            rows={14}
          />
        </Grid>

      </Grid>
    </Container>
  );
}
