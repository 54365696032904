/* eslint-disable no-console */
// noinspection JSIgnoredPromiseFromCall

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import API from '@aws-amplify/api';
import { Clear as ClearIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { red, green } from '@material-ui/core/colors';

import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 2,
    overflowY: 'auto',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
    '&:hover': {
      color: red[800],
    },
  },
  delete: {
    color: red[500],
  },
  input: {
    width: '50%',
  },
  checkIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: green[600],
    },
  },
  clearIcon: {
    margin: 4,
    cursor: 'pointer',
    '&:hover': {
      color: red[500],
    },
  },
}));

interface NewUser {
  Email: string
}

function AddCalUser() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<NewUser[]>([]);
  const [newMail, setNewMail] = useState('');
  const [adding, setAdding] = useState(false);
  const [serachFilter, setSerachFilter] = useState<string>('');
  const [filteredUser, setFilteredUser] = useState<NewUser[]>([]);

  async function fetch() {
    const res: NewUser[] = await API.get('emilog', 'listAllowed', {});
    setLoading(false);
    setUser(res);
  }

  async function handleDelete(email: string) {
    try {
      await API.post('emilog', 'deleteAllowed', { body: { email } });
      await fetch();
    } catch (err) {
      console.error(err);
    }
  }

  async function handleAdd() {
    try {
      await API.post('emilog', 'addAllowed', { body: { email: newMail } });
      await fetch();
      setAdding(false);
    } catch (err) {
      console.error(err);
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSerachFilter(searchValue);

    const filtered = user.filter((user) =>
      user.Email.toLowerCase().includes(searchValue)
    );

    setFilteredUser(filtered);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Berechtigte E-Mail Adressen"
      />
      <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
        <div style={{ margin: '0px 0px 16px 16px' }}>
          <TextField
            label="Suche"
            onChange={handleSearch}
            value={serachFilter}
          />
        </div>
        {adding ? (
          <>
            <TextField
              className={classes.input}
              value={newMail}
              onChange={(e) => setNewMail(e.target.value)}
              type="email"
            />
            <Tooltip title="Absenden">
              <CheckIcon className={classes.checkIcon} fontSize="small" onClick={handleAdd} />
            </Tooltip>
            <Tooltip title="Abbrechen">
              <ClearIcon className={classes.clearIcon} fontSize="small" onClick={() => setAdding(false)} />
            </Tooltip>
          </>
        ) : (
          <Button color="primary" onClick={() => setAdding(true)}>+ Email-Adresse hinzufügen...</Button>
        )}
      </Box>
      <Divider />
      <CardContent className={classes.content}>
        {loading && (
          <>
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
            <Skeleton style={{ marginBottom: 10 }} variant="text" />
          </>
        )}

        {filteredUser !== null && filteredUser.length > 0 ? (
          filteredUser.map(({ Email }) => (
            <div key={Email} className={classes.wrapper}>
              <Typography>{Email}</Typography>
              <Tooltip title="Entfernen">
                <IconButton size="small" style={{ padding: 0 }} onClick={() => handleDelete(Email)}>
                  <ClearIcon className={classes.delete} />
                </IconButton>
              </Tooltip>
            </div>
          ))
        ) : user !== null && user.length > 0 ? (
          user.map(({ Email }) => (
            <div key={Email} className={classes.wrapper}>
              <Typography>{Email}</Typography>
              <Tooltip title="Entfernen">
                <IconButton size="small" style={{ padding: 0 }} onClick={() => handleDelete(Email)}>
                  <ClearIcon className={classes.delete} />
                </IconButton>
              </Tooltip>
            </div>
          ))
        ) : null}

      </CardContent>
    </Card>

  );
}

export default AddCalUser;
