import { useState } from 'react';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SetupHeader from '../../../emilog/components/SetupHeader';
import { useRouting } from '../../../utils/useRouting';
import { EmiconShadow } from '../../../../types/Shadow';
import DataCardEmicon from './DataCardEmicon';
import ShadowUpdateButton from './ShadowUpdateButton';
import ShadowGraphic from '../Visual/ShadowGraphic';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
  },
  container: {
    width: '85vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },

}));

export default function EmiconSettings() {

  /* Setup Emi-Con ->  Emi-Con Übersicht */
  const classes = useStyles();
  const { currentDevice } = useRouting('Setup', true);
  const [reported, setReported] = useState<Partial<EmiconShadow> | null>({});
  const [reportedTimestamp, setReportedTimestamp] = useState<number | null>(null);
  const [loadingShadow, setLoadingShadow] = useState(false);

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader isEmicon hasBearbeitung={true} />

      <Grid container spacing={4} style={{ marginTop: 0 }} alignContent="flex-start">
        <Grid item xs={12} lg={6} className={classes.expandingCard}>
          <DataCardEmicon
            serialNumber={currentDevice?.serialNumber}
            active={typeof currentDevice?.serialNumber !== 'undefined'}
            isEmicon={true}
            reported={reported}
            setReported={setReported}
            setReportedTimestamp={setReportedTimestamp}
            device={currentDevice}
            loadingShadow={loadingShadow}
            setLoadingShadow={setLoadingShadow}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ShadowUpdateButton
            serialNumber={currentDevice?.serialNumber}
            active={typeof currentDevice?.serialNumber !== 'undefined'}
            setReported={setReported}
            setReportedTimestamp={setReportedTimestamp}
            setLoadingShadow={setLoadingShadow}
          />
          <ShadowGraphic reported={reported} reportedTimestamp={reportedTimestamp} />
        </Grid>
      </Grid>

    </Container>
  );
}
