import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, CssBaseline, Card, Typography,
} from '@material-ui/core';
import emilogBackground from '../../../assets/emilog-hintergrund.jpg';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      background: `url(${emilogBackground}) no-repeat center center fixed`,
      backgroundSize: 'cover',
      backgroundPositionY: '100%',
    },
    body: {
      background: 'none',
    },
  },
  card: {
    marginTop: theme.spacing(8),
    borderRadius: 8,
  },
  paper: {
    padding: 20,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Error() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card className={classes.card}>
        <div className={classes.paper}>

          <Typography variant="h4">
            Error
          </Typography>
          <Typography>
            Bei der Anmeldung ist etwas schiefgelaufen. Bitte kontaktieren sie Emission-Partner.
          </Typography>
        </div>
      </Card>
    </Container>
  );
}

export default Error;
