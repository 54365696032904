import { MqttResponse } from '../../types/Shadow';
import checkDefined from './checkDefined';

export function getVersionNumber(value: MqttResponse) {
  if (checkDefined(value?.state?.reported?.version) === true) {
    const splitString = (value.state.reported.version as string).split(' ');
    return splitString[0];
  }
  return null;
}

export function getVersionTimestamp(value: MqttResponse) {
  if (checkDefined(value?.state?.reported?.version) === true) {
    const string = (value.state.reported.version as string).split(' ');
    if (string.length < 2) return 0;
    const date = string[1].split('.');
    const timestamp = Date.parse(`${date[1]}/${date[0]}/${date[2]}`);
    return timestamp;
  }
  return null;
}
