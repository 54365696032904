import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import API from '@aws-amplify/api';
import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import { onlyValidNumbers } from '../../../utils/onlyValidNumbers';
import DeviceContext from '../../context/DeviceContext';
import { Device } from '../../../../types/Device';

const useStyles = makeStyles(() => ({
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
}));

function TempSettings() {
  const { currentDevice, setCurrentDevice, fetchDevices } = useContext(DeviceContext);

  const active = currentDevice.serialNumber !== undefined;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [T_min, setT_min] = useState<number | string | undefined>(undefined);
  const [T_max, setT_max] = useState<number | string | undefined>(undefined);
  const [T_alarm, setT_alarm] = useState<number | string | undefined>(undefined);

  async function submitData() {
    try {
      setLoading(true);
      setSuccess(false);
      const body = {
        serialNumber: currentDevice.MAC,
        fields: {
          T_min: (T_min === undefined || T_min === '') ? null : T_min,
          T_max: (T_max === undefined || T_max === '') ? null : T_max,
          T_alarm: (T_alarm === undefined || T_alarm === '') ? null : T_alarm,
        },
      };
      await API.post('emilog', 'updateKoeffs', { body });

      setCurrentDevice((prevState: Device) => ({
        ...prevState,
        T_min,
        T_max,
        T_alarm,
      }));
      await fetchDevices();
      setSuccess(true);
    } catch (err: any) {
      console.log(error);
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    setSuccess(false);
  }, [currentDevice.serialNumber]);

  useEffect(() => {
    setT_min(currentDevice.T_min);
    setT_max(currentDevice.T_max);
    setT_alarm(currentDevice.T_alarm);
  }, [currentDevice]);

  return (
    <Card>
      <CardHeader
        title="Temperaturüberwachung"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={6}>

            <TextField
              value={T_min ?? ''}
              disabled={!active}
              onChange={(e) => setT_min(onlyValidNumbers(e.target.value))}
              size="small"
              label="T_min"
              variant="outlined"
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment disableTypography className={classes.unit} position="end">°C</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />

          </Grid>
          <Grid item lg={6}>
            <TextField
              value={T_max ?? ''}
              disabled={!active}
              onChange={(e) => setT_max(onlyValidNumbers(e.target.value))}
              size="small"
              label="T_max"
              variant="outlined"
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment disableTypography className={classes.unit} position="end">°C</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              value={T_alarm ?? ''}
              disabled={!active}
              onChange={(e) => setT_alarm(onlyValidNumbers(e.target.value))}
              size="small"
              label="T_alarm"
              variant="outlined"
              InputProps={{
                endAdornment:
                  // eslint-disable-next-line react/jsx-indent
                  <InputAdornment disableTypography className={classes.unit} position="end">°C</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton onClick={submitData} isLoading={loading} text="Absenden" disabled={!active} />
      </CardActions>
    </Card>
  );
}

export default TempSettings;
