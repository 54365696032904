import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import API from '@aws-amplify/api';
import EmiconContext from '../../context/EmiconContext';

const useStyles = makeStyles((theme) => ({
  // cardContent: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  // },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: 'none', // Hide the file input element
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  spacer: { // Dient zusammen mit ner Box/Dic als Füllmaterial über z.B. der CardActions Komponente. In tandem mit min-height aus card Klasse.
    flexGrow: 1, // Fills the space above the date picker
  },
}));

function EmiconLostDataUpload() {

  const classes = useStyles();

  const { currentDevice } = useContext(EmiconContext);

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<string>('');
  const [notificationSuccess, setNotificationSuccess] = useState<string>('');

  // Function to detect and format the date correctly
  // const formatDateToYMD = (dateStr: string) => {
  //   const parts = dateStr.split('.');
  //   if (parts.length === 3) {
  //     const [first, second, third] = parts;
  //     // Check if the date is in yyyy.mm.dd format by seeing if the first part is 4 digits
  //     if (first.length === 4) {
  //       return dateStr; // Already yyyy.mm.dd, so return as is
  //     } else if (third.length === 4) {
  //       // Convert dd.mm.yyyy to yyyy.mm.dd
  //       return `${third}.${second}.${first}`;
  //     }
  //   }
  //   // If it's neither, return as is (handle unexpected formats gracefully)
  //   return dateStr;
  // };

  function fixTimeOldStructure(oldData: any) {
    const data = structuredClone(oldData);
    for (let i = 1; i < data.length; ++i) {
      const vorletztesElement: number = data[i].length - 2;
      const letztesElement = vorletztesElement + 1;
      const date = new Date(`${data[i][vorletztesElement]} ${data[i][letztesElement]}`);
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      data[i][vorletztesElement] = unixTimestamp;
      data[i].splice(letztesElement, 1); // Entfernen des nicht benötigen Datum Teils
    }
    return data;
  }

  function fixTime(oldData: any, hasCo: boolean, isMid2024: boolean) {
    const data = oldData; // Safety first!

    data[0].splice(data[0][0], 2); // "NOx-In5% [mg/Nm^3]" und "O2-In [%]" entfernen. Nur im neuen Format von 2024 im Alten Format entfällt sonst CO, Splice damit aus Datum und Zeit nur Zeit wird

    if (!hasCo) data[0][data[0].length - 2] = "CO"; // "Datum" durch CO ersetzen. Nur im neuen Format von 2024 im Alten Format entfällt sonst CO, Splice damit aus Datum und Zeit nur Zeit wird

    if (isMid2024) { // Motorlast und Volumenstrom entfernen
      // Calculate the length of the array
      let len = data[0].length;
      // Calculate the starting index for splice
      let startIndex = len - 4;
      data[0].splice(startIndex, 2); // Aktuell Motorlast und Volumen aus Aktueller CSV im August 2024 rausschneiden
    }

    for (let i = 1; i < data.length; ++i) {

      data[i].splice(0, 2); // Werte für "NOx-In5% [mg/Nm^3]" und "O2-In [%]" entfernen

      if (isMid2024) {  // Motorlast und Volumenstrom entfernen
        // Calculate the length of the array
        let len = data[i].length;
        // Calculate the starting index for splice
        let startIndex = len - 4;
        data[i].splice(startIndex, 2); // Aktuell Motorlast und Volumen aus Aktueller CSV im August 2024 rausschneiden
      }

      const vorletztesElement: number = data[i].length - 2;
      const letztesElement: number = vorletztesElement + 1;
      const date = new Date(`${data[i][vorletztesElement]} ${data[i][letztesElement]}`); // Datum und Zeit zu Zeit zusammen fassen
      // Apply format check and conversion -> Eikos Conversion tool von DynamoDB CSV download gibt das falsche Datums Format wieder (DD.MM.YYYY nicht YYYY.MM.DD)
      // const formattedDate = formatDateToYMD(data[i][vorletztesElement]);
      // const date = new Date(`${formattedDate} ${data[i][letztesElement]}`); // Datum und Zeit zu Zeit zusammen fassen
      const unixTimestamp = Math.floor(date.getTime() / 1000);

      if (!hasCo) {
        data[i][vorletztesElement] = "0"; // Datum wurde durch CO ersetzt und auf den default 0.0 gesetzt
      } else {
        data[i].splice(vorletztesElement, 1); // Entfernen des nicht benötigen Datum Teils, dadurch wird CO zum 5 Element
      }
      data[i][letztesElement] = unixTimestamp;
    }

    // const newOrder = ['Temperatur [°C]', 'O2-Out [%]', 'Differenzdruck [mBar]', 'NOx-Out5% [mg/Nm^3]', 'CO bzw. Var_CO', 'Zeit'];
    // temperatur, o2, druck, nox, co, zeit
    const newOrder = [data[0][3], data[0][1], data[0][2], data[0][0], data[0][4], data[0][5]];

    // Find the index of each header field in the original header
    const originalOrder = data[0];

    const fieldIndices = newOrder.map(field => originalOrder.indexOf(field));

    // Rearrange each row based on the new order of header fields
    const rearrangedArr = data.map((row: any) => fieldIndices.map(index => row[index]));

    return rearrangedArr;
  }

  function transformCSVData(csvData: string): string[][] {
    // Split the CSV data into rows
    const rows = csvData.split('\n');

    // Remove empty rows
    const nonEmptyRows = rows.filter(row => row.trim() !== '');

    // console.log({ nonEmptyRows });

    // Split each row into columns, handling quotes and trailing special characters
    const rowData = nonEmptyRows
      .map(row => {
        // Replace all double quotes with an empty string
        const cleanedRow = row.replace(/"/g, '');

        // Split the cleaned row into columns
        let columns = cleanedRow.split(';').map(column => column.trim());

        // Remove any trailing special characters like '\r' from each column
        columns = columns.map(column => column.replace(/\r$/, ''));

        // Remove empty strings at the end of the row
        while (columns[columns.length - 1] === '') {
          columns.pop();
        }

        return columns;
      })
      // Filter out any rows that are now completely empty
      .filter(columns => columns.length > 0);

    // console.log({ rowData });

    // CSV file muss noch unterschieden werden. Neues format hat kein CO, wird zu default 0. Header neues Format muss zum alten umgewandelt werden für ide Emicon Tabelle.

    const oldFormatHeader = [
      "Temperatur",
      "O2",
      "Druck",
      "NOx",
      "CO",
      "Zeit"
    ];

    const newFormatHeader = [
      "NOx-In5% [mg/Nm^3]",
      "O2-In [%]",
      "NOx-Out5% [mg/Nm^3]", // NOx
      "O2-Out [%]", // O2
      "Differenzdruck [mBar]", // Druck
      "Temperatur [�C]", // Temperatur
      "Datum",
      "Zeit" // Zeit
    ];

    const newFormatHeaderExtended = [
      "NOx-In5% [mg/Nm^3]",
      "O2-In [%]",
      "NOx-Out5% [mg/Nm^3]", // NOx
      "O2-Out [%]", // O2
      "Differenzdruck [mBar]", // Druck
      "Temperatur [�C]", // Temperatur
      "Var_CO", // Wenn eines Tages Vorhanden
      "Datum",
      "Zeit" // Zeit
    ];

    const formatMid2024Header = [
      "NOx-In5% [mg/Nm^3]",
      "O2-In [%]",
      "NOx-Out5% [mg/Nm^3]", // NOx
      "O2-Out [%]", // O2
      "Differenzdruck [mBar]", // Druck
      "Temperatur [�C]", // Temperatur
      "Motorlast [%]",
      "Volumenstrom [L/h]",
      "Datum",
      "Zeit" // Zeit
    ];

    let fixedRowData;

    if (JSON.stringify(rowData[0]) === JSON.stringify(formatMid2024Header)) {
      console.log('Neues Format mit Motorlast und Volumenstrom')
      fixedRowData = fixTime(structuredClone(rowData), false, true);

    } else if (JSON.stringify(rowData[0]) === JSON.stringify(newFormatHeaderExtended)) {
      console.log('Neues Format mit CO')
      fixedRowData = fixTime(structuredClone(rowData), true, false);

    } else if (JSON.stringify(rowData[0]) === JSON.stringify(newFormatHeader)) {
      console.log('Neues Format')
      fixedRowData = fixTime(structuredClone(rowData), false, false);

    } else if (JSON.stringify(rowData[0]) === JSON.stringify(oldFormatHeader)) {
      console.log('Altes Format')
      fixedRowData = fixTimeOldStructure(rowData);
    } else {
      console.log('Format Stimmt nicht überein')
      fixedRowData = [];
    }

    return fixedRowData;
  }

  function parseCSVFile(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        try {
          const csv = event.target.result as string;

          const transformedCsc = transformCSVData(csv);
          // Check if CSV data is in the expected format
          if (transformedCsc.length === 0 || transformedCsc[0].length === 0) {
            reject(new Error('CSV Daten sind Leer oder ungültig'));
            setNotification('Datei Format ist nicht gültig'); // Notify user if file is not a CSV
          } else {
            resolve(transformedCsc);
          }
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  }

  async function handleSubmit(file: File) {
    try {
      setLoading(true);
      // Check if the file is a CSV file
      if (file.type !== 'text/csv') {
        setNotificationSuccess('');
        setNotification('Datei nicht im CSV Format'); // Notify user if file is not a CSV
        setLoading(false);
        return;
      }
      setNotification('');
      setNotificationSuccess('');
      const data = await parseCSVFile(file);

      // console.log(currentDevice.serialNumber)
      // console.log({ data })
      // setLoading(false);
      // return

      // api call mit data und serial number
      const result = await API.post('emilog', 'emicon/csvHmwUpload', {
        body: {
          serialNumber: currentDevice.serialNumber,
          csvDatenArray: data,
        },
      });

      setNotificationSuccess(result.message)
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (err) {
      setLoading(false);
      setNotification('Fehler beim Hochladen der Datei');
      console.error(err);
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target.files?.[0];
    if (!file) return;
    handleSubmit(file);
    event.target.value = '';
  };

  return (
    <Card className={classes.cardMargin}>
      <CardHeader title="SPS Daten Upload" />
      <Divider />
      <CardContent className={classes.cardContent}>
        {notificationSuccess === "" ? (<Typography style={{ fontSize: '16px' }}>Hochladen der HMW Aufzeichnungen der SPS</Typography>) : (null)}
        {notification && <Typography style={{ fontSize: '16px' }} color="error">{notification}</Typography>}
        {notificationSuccess && <Typography style={{ fontSize: '16px', color: "green" }}>{notificationSuccess}</Typography>}
        <Button
          variant="contained"
          color="primary"
          component="label"
          disabled={loading || !currentDevice.serialNumber}
        >
          {'Absenden'}
          <input
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Button>
      </CardContent>
      {loading ? (
        <LinearProgress />
      ) : (
        null
      )}
    </Card>

  );
}

export default EmiconLostDataUpload;
