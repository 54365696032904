import React, { useState, useEffect, useContext } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import API from '@aws-amplify/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AuthContext from '../../context/AuthContext';
import DeviceContext from '../../context/DeviceContext';

import LoaderButton from '../LoaderButton';

const useStyles = makeStyles(() => ({
  commentCell: {
    width: '40%',
  },
  cellHeader: {
    fontWeight: 900,
  },

}));

export default function CommentDialog({
  onClose, open, oldDetails, newDetails, onSubmit,
}) {
  CommentDialog.propTypes = {
    onClose: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    /* eslint-disable-next-line */
    oldDetails: propTypes.objectOf(propTypes.any).isRequired,
    /* eslint-disable-next-line */
    newDetails: propTypes.objectOf(propTypes.any).isRequired,
  };
  const { currentUser } = useContext(AuthContext);
  const { fetchDevices } = useContext(DeviceContext);
  const classes = useStyles();

  // const [loading, setLoading] = useState(false);
  const [changes, setChanges] = useState([]);
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChanges([]);
    Object.keys(newDetails).forEach((key) => {
      if (['M_NOx', 'B_NOx', 'M_CO', 'M_O2', 'B_O2'].includes(key)) {
        setChanges((prevState) => ([
          ...prevState,
          {
            key,
            oldValue: oldDetails[key],
            newValue: newDetails[key],
          },
        ]));
      }
    });
  }, [open]);

  async function handleSubmit(event) {
    event.preventDefault();
    changes.forEach((change) => {
      // eslint-disable-next-line no-param-reassign
      change.anmerkung = comments[`${change.key}`];
    });
    setLoading(true);
    try {
      await API.post('emilog', 'logCalProtocol', {
        body: {
          serialNumber: oldDetails.MAC,
          user: currentUser.user.attributes.email,
          institut: 'EmissionPartner',
          changes,
        },
      });
      await API.post('emilog', 'updateKoeffs', {
        body: {
          serialNumber: oldDetails.MAC,
          fields: {
            M_NOx: (newDetails.M_NOx === undefined || newDetails.M_NOx === '') ? null : newDetails.M_NOx,
            B_NOx: (newDetails.B_NOx === undefined || newDetails.B_NOx === '') ? null : newDetails.B_NOx,
            M_CO: (newDetails.M_CO === undefined || newDetails.M_CO === '') ? null : newDetails.M_CO,
            M_O2: (newDetails.M_O2 === undefined || newDetails.M_O2 === '') ? null : newDetails.M_O2,
            B_O2: (newDetails.B_O2 === undefined || newDetails.B_O2 === '') ? null : newDetails.B_O2,
          },
        },
      });
      setLoading(false);
      fetchDevices();
      onSubmit(true);
    } catch (err) {
      onSubmit(false);
    }
  }

  function onCommentChange(event, change) {
    const { value } = event.target;
    setComments((prevState) => ({ ...prevState, [change.key]: value }));
  }

  const renderO2Rows = () => {
    if (changes.length < 1) return null;
    const filteredChanges = changes.filter(({ key }) => key.includes('O2'));
    if (filteredChanges.length < 1) return null;
    const head = (
      <TableRow key="o2">
        <TableCell align="center" colSpan={4} className={classes.cellHeader}>O₂-Sensor</TableCell>
      </TableRow>
    );
    const rows = filteredChanges.map((change) => {
      const oldValue = change.key === 'B_O2' ? `${change.oldValue}` : change.oldValue;
      const newValue = change.key === 'B_O2' ? `${change.newValue}` : change.newValue;
      return (
        <TableRow key={change.key}>
          <TableCell component="th" scope="row">
            {change.key}
          </TableCell>
          <TableCell align="right">{oldValue}</TableCell>
          <TableCell align="right">{newValue}</TableCell>
          <TableCell>
            <TextField
              multiline
              fullWidth
              onChange={(event) => onCommentChange(event, change)}
              value={comments[`${change.key}`] || ''}
            />
          </TableCell>

        </TableRow>

      );
    });
    return [head, rows];
  };

  const renderNOxRows = () => {
    if (changes.length < 1) return null;
    const filteredChanges = changes.filter(({ key }) => key.includes('NOx'));
    if (filteredChanges.length < 1) return null;
    const head = (
      <TableRow key="nox">
        <TableCell align="center" colSpan={4} className={classes.cellHeader}>NOₓ-Sensor</TableCell>
      </TableRow>
    );
    const rows = filteredChanges.map((change) => {
      const oldValue = change.key === 'B_NOx' ? `${change.oldValue}` : change.oldValue;
      const newValue = change.key === 'B_NOx' ? `${change.newValue}` : change.newValue;
      return (
        <TableRow key={change.key}>
          <TableCell component="th" scope="row">
            {change.key}
          </TableCell>
          <TableCell align="right">{oldValue}</TableCell>
          <TableCell align="right">{newValue}</TableCell>
          <TableCell>
            <TextField
              multiline
              fullWidth
              onChange={(event) => onCommentChange(event, change)}
              value={comments[`${change.key}`] || ''}
            />
          </TableCell>

        </TableRow>

      );
    });
    return [head, rows];
  };

  const renderCoRows = () => {
    if (changes.length < 1) return null;
    const filteredChanges = changes.filter(({ key }) => key.includes('CO'));
    if (filteredChanges.length < 1) return null;
    const head = (
      <TableRow key="o2">
        <TableCell align="center" colSpan={4} className={classes.cellHeader}>CO-Sensor</TableCell>
      </TableRow>
    );
    const rows = filteredChanges.map((change) => (
      <TableRow key={change.key}>
        <TableCell component="th" scope="row">
          {change.key}
        </TableCell>
        <TableCell align="right">{change.oldValue}</TableCell>
        <TableCell align="right">{change.newValue}</TableCell>
        <TableCell>
          <TextField
            multiline
            fullWidth
            onChange={(event) => onCommentChange(event, change)}
            value={comments[`${change.key}`] || ''}
          />
        </TableCell>

      </TableRow>
    ));
    return [head, rows];
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Änderungen kommentieren
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Koeffizient</TableCell>
                <TableCell align="right">Vorher</TableCell>
                <TableCell align="right">Nachher</TableCell>
                <TableCell className={classes.commentCell}>Anmerkung</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderNOxRows()}
              {renderCoRows()}
              {renderO2Rows()}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <LoaderButton
          isLoading={loading}
          onClick={handleSubmit}
          text="Absenden"
          fullWidth={false}
          variant="text"
        />
      </DialogActions>
    </Dialog>
  );
}
