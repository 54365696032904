import React, { useEffect } from 'react';

import EmiconTable from './EmiconTable';

export default function Dashboard() {
  useEffect(() => {
    document.title = 'Übersicht - EMI-CON Adminportal';
  }, []);
  return (
    <EmiconTable />
  );
}
