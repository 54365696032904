import React, { useEffect } from 'react';

import ViewUserTable from './EmiViewUserTable';

export default function Nutzer() {
  useEffect(() => {
    document.title = 'EMI-VIEW Nutzer - EMI-LOG Adminportal';
  }, []);
  return (
    <ViewUserTable />
  );
}
