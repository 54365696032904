import { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { API } from '@aws-amplify/api';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import convertDate from '../../../utils/convertDate';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import capitalize from '../../../utils/capitalize';
import AuthContext from '../../../emilog/context/AuthContext';
import { Emicon } from '../../../../types/Device';
import { DeviceWithShadow } from '../../../../types/Device';


const useStyles = makeStyles((theme) => ({
  cardContent: { // Füllt Content nach unten in tandem mit der min-height aus der card klasse. Aktuell nur auf Service Seite. 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
}));

interface EmiconMessterminProps {
  serialNumber: string,
  isEmicon: boolean
  currentDevice: Emicon | DeviceWithShadow
  setCurrentDevice: Function
  fetchDevices: Function
}

// TODO In Emi View (Anischt) und Emi Cal (Edit)

export default function EmiconMesstermin({ serialNumber, isEmicon = false, currentDevice, setCurrentDevice, fetchDevices }: EmiconMessterminProps) {
  const classes = useStyles();
  // const { currentUser } = useContext(AuthContext);
  // const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  // const author = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const [messtermin_Alt, setMesstermin_Alt] = useState<number | null>(null);
  const [messtermin, setMesstermin] = useState<number | null>(null);
  const [newDate, setNewDate] = useState<Date | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);


  async function handleSubmit() {
    try {
      const messterminNeu = newDate ? Math.round(newDate.getTime() / 1000) : null;
      if (messterminNeu === null || messterminNeu === undefined) return;
      setLoading(true);
      setError(false);
      setSuccess(false);
      let fields = {};

      if (messtermin === null || messtermin === undefined) {
        fields = {
          Messtermin: messterminNeu,
        }
      } else {
        fields = {
          Messtermin: messterminNeu,
          Messtermin_Alt: messtermin,
        }
      }


      await API.post('emilog', 'updateInfos', {
        body: {
          serialNumber: serialNumber,
          isEmicon,
          fields: fields,
        },
      });

      setCurrentDevice((prevState: Emicon) => ({
        ...prevState,
        Messtermin: messterminNeu,
        Messtermin_Alt: messtermin,
      }));
      
      fetchDevices();
      setMesstermin(messterminNeu);
      setMesstermin_Alt(messtermin);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (!serialNumber || serialNumber === undefined || serialNumber === null || serialNumber === '') return;
    setMesstermin_Alt(currentDevice?.Messtermin_Alt ?? null);
    setMesstermin(currentDevice?.Messtermin ?? null);
  }, [serialNumber]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Messtermin"
      />
      <Divider />
      <CardContent className={classes.cardContent}>

        <Grid container>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                value={newDate}
                label="Neuer Messtermin"
                className={classes.datePicker}
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                onChange={(e) => setNewDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
                disabled={serialNumber === undefined}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {messtermin_Alt ? (
            <Grid item xs={12}>
              <Typography>
                Letzter Messtermin: {convertDate(messtermin_Alt)}
              </Typography>
            </Grid>
          ) : (
            null
          )
          }

          {messtermin ? (
            <Grid item xs={12}>
              <Typography>
                Aktueller Messtermin: {convertDate(messtermin)}
              </Typography>
            </Grid>
          ) : (
            null
          )
          }

        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton isLoading={loading} text="Messtermin aktualisieren" onClick={handleSubmit} disabled={serialNumber === undefined || !newDate} />
      </CardActions>
    </Card>

  );
}
