/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import DeviceContext from '../context/DeviceContext';
import { simpleSort, getSorting } from '../../utils/sortTable';
import EmiconContext from '../../emicon/context/EmiconContext';

const useStyles = makeStyles((theme) => ({
  macSelect: {
    width: 240,
    marginTop: -2,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  selectTextfield: {
    fontSize: '1.5rem',
  },
  selectOption: {
    minHeight: 48,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    paddingTop: 6,
    boxSizing: 'border-box',
    outline: '0',
    WebkitTapHighlightColor: 'transparent',
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected,
    },
    '&[devices-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected,
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
  },
  groupLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loader: {
    position: 'absolute',
    right: 32,
    top: 8,
  },
}));

export default function SelectSearch({ loading, isEmicon = false }: { loading: boolean, isEmicon: boolean }) {
  const {
    devices, currentDevice, setCurrentDevice,
    // @ts-ignore
  } = useContext(isEmicon ? EmiconContext : DeviceContext);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const path = `${isEmicon ? 'emicon/' : ''}${location.pathname.split('/')[isEmicon ? 2 : 1]}`;

  const [data, setData] = useState(devices);

  useEffect(() => {
    setData(simpleSort(devices, getSorting('asc', 'Betreiber')));
  }, [devices]);

  return (
    <div className={classes.wrapper}>

      <Autocomplete
        value={currentDevice}
        onChange={(event, newValue) => {
          if (newValue !== null) {
            setCurrentDevice(newValue);
            history.push(`/${path}/${newValue.serialNumber}`);
          } else {
            setCurrentDevice({});
            history.push(`/${path}`);
          }
        }}
        options={data || []}
        groupBy={(option) => option?.Betreiber ?? ''}
        getOptionLabel={(option) => {
          if (option.serialNumber && option?.Betreiber) {
            return `${option.serialNumber} - ${option?.Betreiber}`;
          }
          return (option.serialNumber ? option.serialNumber : '');
        }}
        renderOption={(option) => (
          <span className={classes.selectOption}>
            {option.serialNumber}
          </span>
        )}
        getOptionSelected={
          (option, value) => (value?.serialNumber === option?.serialNumber)
        }
        className={classes.macSelect}
        style={{ width: isEmicon ? 300 : 240 }}
        renderInput={(params) => {
          // eslint-disable-next-line no-param-reassign
          params.InputProps = { ...params.InputProps, className: classes.selectTextfield };
          // @ts-ignore
          params.inputProps.value = params.inputProps.value.split(' ')[0];
          return (
            <TextField
              {...params}
              variant="filled"
            />
          );
        }}
        classes={{
          groupLabel: classes.groupLabel,
        }}
      />
      {loading && (
        <CircularProgress className={classes.loader} size={22} />
      )}
    </div>

  );
}
