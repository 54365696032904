import React from 'react';
import { Scatter } from 'react-chartjs-2';
import cloneDeep from 'lodash.clonedeep';

const blue = 'rgba(0, 127, 172, 1)';
const green = 'rgba(82, 174, 50, 1)';
const red = 'rgba(150,0,0, 1)';

interface ChartProps {
  chartData: Object[]
  type: string
  limits: Object[]
  fullSize?: boolean
  showHmw?: boolean
  days: number
}

function Chart({
  chartData, type, limits, fullSize = false, showHmw = false, days,
}: ChartProps) {
  const data = cloneDeep(chartData);
  const limitData = cloneDeep(limits);

  let pointSize: number;
  let pointHitSize: number;
  let unitStepSize: number;

  if (showHmw) {
    if (days <= 60) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 3;
    } else if (days <= 120) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 5;
    } else if (days <= 270) {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 7;
    } else {
      pointSize = 1;
      pointHitSize = 4;
      unitStepSize = 14;
    }
  } else if (days <= 60) {
    pointSize = fullSize ? 5 : 3;
    pointHitSize = fullSize ? 7 : 5;
    unitStepSize = fullSize ? 3 : 5;
  } else if (days <= 120) {
    pointSize = fullSize ? 3 : 2;
    pointHitSize = fullSize ? 5 : 3;
    unitStepSize = fullSize ? 5 : 7;
  } else if (days <= 270) {
    pointSize = fullSize ? 2 : 1;
    pointHitSize = fullSize ? 4 : 2;
    unitStepSize = fullSize ? 7 : 14;
  } else {
    pointSize = fullSize ? 2 : 1;
    pointHitSize = fullSize ? 3 : 2;
    unitStepSize = fullSize ? 14 : 28;
  }

  let color: string;
  let label: string;
  let limitLabel: string;
  let yAxisLabel: string;

  switch (type) {
    case 'co':
      color = blue;
      label = (fullSize && showHmw) ? 'HMW CO' : 'TMW CO';
      limitLabel = 'Grenzwert CO';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
    case 'nox':
      color = green;
      label = (fullSize && showHmw) ? 'HMW NOx' : 'TMW NOx';
      limitLabel = 'Grenzwert NOx';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
    case 'temp':
      color = red;
      label = (fullSize && showHmw) ? 'HMW Temperatur' : 'TMW Temperatur';
      limitLabel = 'Grenzwert Temperatur';
      yAxisLabel = 'Temperatur in °C';
      break;
    default:
      color = blue;
      label = (fullSize && showHmw) ? 'HMW CO' : 'TMW CO';
      limitLabel = 'Grenzwert CO';
      yAxisLabel = 'Konzentration in mg/Nm³ (trocken, 5% Sauerstoffgehalt)';
      break;
  }

  const dataSets = {
    datasets: [
      {
        label,
        pointBackgroundColor: color,
        backgroundColor: color,
        pointRadius: pointSize,
        pointHitRadius: pointHitSize,
        type: 'scatter',
        data,
      },
      {
        label: limitLabel,
        type: 'line',
        fill: false,
        borderColor: color,
        borderWidth: 1,
        borderDash: [8, 4],
        pointStyle: 'line',
        data: limitData,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: 'top',
      labels: {
        usePointStyle: true,
        fontSize: 12,
        boxWidth: 6,
      },
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Datum',
        },
        bounds: 'ticks',
        type: 'time',
        ticks: {
          // min: Date.now() - (24 * 60 * 6000 * 30),
          max: Date.now(),
        },
        time: {
          autoSkip: false,
          unit: 'day',
          unitStepSize,
          tooltipFormat: showHmw ? 'HH:mm DD.MM' : 'DD.MM',
          displayFormats: {
            day: 'DD.MM.YYYY',
          },
        },
        position: 'bottom',
      }],
      yAxes: [{
        ticks: {
          // max: limits[1] !== undefined ? limits[1].y + 100 : 1000,
          min: 0,
          stepSize: 100,
        },
        scaleLabel: {
          display: true,
          labelString: yAxisLabel,
        },
        gridLines: {
          display: false,
        },
      }],
    },
  };
  return (
    // @ts-ignore
    <Scatter
      data={dataSets}
      options={options}
      // width={600}
      height={550}
    />
  );
}

export default React.memo(Chart);
