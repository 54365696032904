import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Emicon } from '../../../../types/Device';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import convertDate from '../../../utils/convertDate';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
  },
  container: {
    width: '95vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  paper: {
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 16,
  },
  gridContainer: {
    [theme.breakpoints.up('lg')]: {
      borderRight: '1px solid lightgray',
    },
  },
  // cardActions: { // OLD
  //   justifyContent: 'flex-end',
  //   paddingRight: 16,
  // },
  unit: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '0.95rem',
  },

  // cardHeader: { // OLD
  //   [theme.breakpoints.down('sm')]: {
  //     flexWrap: 'wrap',
  //   },
  // },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  // card: { // OLD
  //   overflowY: 'auto',
  //   overflowX: 'hidden',
  //   flex: 1,
  // },

  // cardContent: { // OLD
  //   maxHeight: '660px'
  // },

  content: {
    marginTop: theme.spacing(1),
    flex: 1,
  },

  mb: {
    marginBottom: '8px',
  },



  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
  textFieldGroß: {
    flexGrow: 1,
    minHeight: 200, // Sets a minimum height
    maxHeight: '100%', // Allows it to grow and fill space
  },
}));

interface ServiceInfoEmiconProps {
  device: Emicon
  handleMenuOpen: Function
}

export default function ServiceInfoEmicon({ handleMenuOpen, device }: ServiceInfoEmiconProps) {
  const classes = useStyles();

  return (
    <Card
      className={classes.card}
    >
      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Service Informationen</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} className={classes.content} justifyContent="center" >
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Vertragsart"
              variant="outlined"
              fullWidth
              type="text"
              value={device?.Servicevertrag ?? 'Kein Vertrag'}
              className={classes.mb}
            />
          </Grid>
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Service Vertragsende"
              variant="outlined"
              fullWidth
              type="text"
              value={device && device.ServiceVertragsende && device.ServiceVertragsende > 0 ? convertDate(device?.ServiceVertragsende) : 'Kein Ablaufdatum hinterlegt'}
              className={classes.mb}
            />
          </Grid>
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Geplanter Service"
              variant="outlined"
              fullWidth
              type="text"
              value={device && device.ServiceGeplant && device.ServiceGeplant > 0 ? convertDate(device?.ServiceGeplant) : 'Keine Daten'}
              className={classes.mb}
            />
          </Grid>
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Wartungsdatum"
              variant="outlined"
              fullWidth
              type="text"
              value={device && device.WartungGeplant && device.WartungGeplant > 0 ? convertDate(device?.WartungGeplant) : 'Keine Daten'}
              className={classes.mb}
            />
          </Grid>
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Messbericht Ablaufdatum"
              variant="outlined"
              fullWidth
              type="text"
              value={device && device.Messbericht_bis && device.Messbericht_bis > 0 ? convertDate(device?.Messbericht_bis) : 'Kein Ablaufdatum hinterlegt'}
              className={classes.mb}
            />
          </Grid>
          <Grid item lg={6} >
            <TextField
              disabled
              size="small"
              label="Messtermin"
              variant="outlined"
              fullWidth
              type="text"
              value={device && device.Messtermin && device.Messtermin > 0 ? convertDate(device?.Messtermin) : 'Keine Daten'}
              className={classes.mb}
            />
          </Grid>
          <TextField
            disabled
            className={classes.textFieldGroß}
            size="medium"
            label="Sonderkonditionen"
            variant="outlined"
            fullWidth
            type="text"
            multiline
            value={device?.ServiceVertragKondition ?? 'Keine Konditionen hinterlegt'}
          />
        </Grid>
      </CardContent >
    </Card >
  );
}
