/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useState,
} from 'react';
import API from '@aws-amplify/api';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EmiconGrenzwerte from './EmiconGrenzwerte';
import EmiconKalibrierwerte from './EmiconKalibrierwerte';
import EmiconParameterwerte from './EmiconParameterwerte';
import EmiconSimulierwerte from './EmiconSimulierwerte';
// import EmiconBerichtDeckblattInfo from './EmiconBerichtDeckblattInfo';
// import EmiconAnlagenInfo from './EmiconAnlagenInfo';
import { EmiconShadow } from '../../../../types/Shadow';
import { makeStyles } from '@material-ui/styles';
import { Emicon } from '../../../../types/Device';

const useStyles = makeStyles(() => ({
  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    // maxHeight: 697, // TODO: anpassen ggf'80vh',
  },
}));

interface DataCardProps {
  serialNumber: string
  active: boolean
  isEmicon?: boolean
  device: Emicon
  updateReportedShadow: Partial<EmiconShadow> | null
  setUpdateReportedShadow: Function
  liveShadow?: boolean
  setShouldFetchShadow?: Function
  useBlocker?: boolean
  setUseBlocker?: Function
}

export default function DataCard({ serialNumber,
  active,
  isEmicon = false,
  device,
  updateReportedShadow,
  setUpdateReportedShadow,
  liveShadow,
  setShouldFetchShadow,
  useBlocker,
  setUseBlocker
}: DataCardProps) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<string>('');
  const [notificationSuccess, setNotificationSuccess] = useState<string>('');
  const [shouldUpdateShadow, setShouldUpdateShadow] = useState<boolean>(false);

  const [mode, setMode] = useState(0);
  const handleMenuOpen = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (_event: any, index: number) => {
    setMode(index);
    setMenuAnchor(null);
  };

  function castNumber(reported: any) {
    for (const objkey in reported) {
      //if (keys.includes(objkey)) {
      const value = reported[objkey];
      if (typeof value === 'string' && /^[+-]?(\d+.?\d*|.\d+)$/.test(value)) {
        reported[objkey] = Number(value);
      }
      // reported[objkey] = Number(reported[objkey]);
      //}
    }
    return reported;
  }

  async function updateShadow() {
    setLoading(true);
    setNotification('');
    setNotificationSuccess('');
    const castedReported = castNumber(updateReportedShadow);
    if (castedReported === undefined || castedReported === null || Object.keys(castedReported).length === 0) {
      setLoading(false);
      return
    }
    // console.log({ castedReported })
    try {
      // Make the API call with the transformed object
      const result = await API.post('emilog', 'emicon/updateEmiconShadow', {
        body: {
          serialNumber,
          isEmicon: true,
          shadowUpdate: castedReported,
        },
      });
      setLoading(false);
      if (result.statusCode === 500) {
        setNotification(result.message);
      } else {
        setNotificationSuccess(result.message);
        setTimeout(() => {
          if (setShouldFetchShadow) {
            setShouldFetchShadow((prevVal: boolean) => !prevVal)
          }
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setNotification('Aktualisierung der Shadow Parameter fehlgeschlagen!');
      console.error('Error updating shadow:', error);
    }
  }

  function updateReported(event: React.ChangeEvent<{ value: unknown }>, key: keyof EmiconShadow) {
    const newValue = event.target.value as number | string; // Adjust type if necessary
    setUpdateReportedShadow((prevState: EmiconShadow) => ({
      ...prevState,
      [key]: newValue
    }));
  }

  function updateReportedSwitch(val: boolean, key: keyof EmiconShadow) {
    setUpdateReportedShadow((prevState: EmiconShadow) => ({
      ...prevState,
      [key]: val
    }));
    setShouldUpdateShadow((prevVal: boolean) => !prevVal);
  }

  function updateReportedOnBlur(value: number, key: keyof EmiconShadow) {
    setUpdateReportedShadow((prevState: EmiconShadow) => ({
      ...prevState,
      [key]: value
    }));
  }

  // Handle blur to enforce min value to be 0
  function handleBlurForceZero(key: keyof EmiconShadow) {
    if (updateReportedShadow && Object.prototype.hasOwnProperty.call(updateReportedShadow, key)) {
      const value = Number(updateReportedShadow[key]); // Get the current value and ensure it's a number
      // Ensure value is a number
      if (!isNaN(value)) { // Check if the value is a valid number
        if (value < 0) {
          updateReportedOnBlur(0, key); // Set to min if below
        }
      }
    }
  };

  useEffect(() => {
    if (useBlocker === true && setUseBlocker) {
      setUseBlocker(false)
      return
    }
    if (active && serialNumber) {
      // console.log("Shadow wird geupdatet ----- shouldUpdateShadow");
      updateShadow();
    }
  }, [shouldUpdateShadow]);

  // console.log(updateReportedShadow)

  return (
    <>
      <Card className={classes.card} style={{ maxHeight: isEmicon ? 905.75 : 697 }}>
        {mode === 0 ? (
          <EmiconGrenzwerte serialNumber={serialNumber} active={active} handleMenuOpen={handleMenuOpen}
            updateReportedShadow={updateReportedShadow} updateShadow={updateShadow} updateReported={updateReported} setUpdateReportedShadow={setUpdateReportedShadow}
            loading={loading} notification={notification} notificationSuccess={notificationSuccess} handleBlurForceZero={handleBlurForceZero}
            liveShadow={liveShadow} />
        ) : mode === 1 ? (
          <EmiconKalibrierwerte serialNumber={serialNumber} active={active} handleMenuOpen={handleMenuOpen}
            updateReportedShadow={updateReportedShadow} updateShadow={updateShadow} updateReported={updateReported}
            loading={loading} notification={notification} notificationSuccess={notificationSuccess} handleBlurForceZero={handleBlurForceZero}
            liveShadow={liveShadow} updateReportedSwitch={updateReportedSwitch} />
        ) : mode === 2 ? (
          <EmiconParameterwerte serialNumber={serialNumber} active={active} handleMenuOpen={handleMenuOpen}
            updateReportedShadow={updateReportedShadow} updateShadow={updateShadow} updateReported={updateReported}
            loading={loading} notification={notification} notificationSuccess={notificationSuccess} handleBlurForceZero={handleBlurForceZero}
            liveShadow={liveShadow} />
        ) : mode === 3 ? (
          <EmiconSimulierwerte serialNumber={serialNumber} active={active} handleMenuOpen={handleMenuOpen}
            updateReportedShadow={updateReportedShadow} updateShadow={updateShadow} updateReported={updateReported} setUpdateReportedShadow={setUpdateReportedShadow}
            loading={loading} notification={notification} notificationSuccess={notificationSuccess} handleBlurForceZero={handleBlurForceZero}
            liveShadow={liveShadow} />
        )
          // : mode === 4 ? (
          //   <EmiconAnlagenInfo
          //     isViewOnly={false}
          //     device={device}
          //     handleMenuOpen={handleMenuOpen}
          //   />
          // ) : mode === 5 ? (
          //   <EmiconBerichtDeckblattInfo
          //     isViewOnly={false}
          //     handleMenuOpen={handleMenuOpen}
          //     device={device}
          //     isEmicon={true}
          //   />
          // ) 
          : (
            null
          )}
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem
          key={0}
          selected={mode === 0}
          onClick={(event) => handleMenuItemClick(event, 0)}
        >
          Standardwerte
        </MenuItem>
        <MenuItem
          key={1}
          selected={mode === 1}
          onClick={(event) => handleMenuItemClick(event, 1)}
        >
          Skalenwerte
        </MenuItem>
        <MenuItem
          key={2}
          selected={mode === 2}
          onClick={(event) => handleMenuItemClick(event, 2)}
        >
          Parameterwerte
        </MenuItem>
        <MenuItem
          key={3}
          selected={mode === 3}
          onClick={(event) => handleMenuItemClick(event, 3)}
        >
          Simulationswerte
        </MenuItem>
        {/* <MenuItem
          key={4}
          selected={mode === 4}
          onClick={(event) => handleMenuItemClick(event, 4)}
        >
          Anlageninfos
        </MenuItem>
        <MenuItem
          key={5}
          selected={mode === 5}
          onClick={(event) => handleMenuItemClick(event, 5)}
        >
          Bericht Deckblatt
        </MenuItem> */}
      </Menu>
    </>
  );
}
