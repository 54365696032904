/* eslint-disable react/prop-types */
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { RouteProps } from '../../emilog/components/EmilogRoute';
import AuthContext from '../../emilog/context/AuthContext';
import { EmiconDeviceProvider } from '../context/EmiconContext';
import MainLayout from './EmiconLayout';

export default function PrivateRoute({ component: RouteComponent, path, exact = true }: { component: React.FC<RouteComponentProps & RouteProps>, path: string, exact: boolean }) {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser?.loggedIn !== undefined) setLoading(false);
  }, [currentUser]);

  return (
    loading ? (
      <div />
    ) : (
      <Route
        path={path}
        exact={exact}
        render={(routeProps: RouteComponentProps) => (!currentUser?.loggedIn ? (
          <Redirect to="/login" />
        ) : (
          <EmiconDeviceProvider>
            <MainLayout>
              <RouteComponent {...routeProps} isEmicon />
            </MainLayout>
          </EmiconDeviceProvider>
        )
        )}
      />
    )
  );
}
