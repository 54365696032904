/* eslint-disable no-console */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import useStateRef from 'react-usestateref';

import LoaderButton from '../LoaderButton';
import Feedback from '../Feedback';
import { getVersionTimestamp } from '../../../utils/trimVersion';
import DeviceContext from '../../context/DeviceContext';
import { MqttSubscription } from '../../../../types/Interfaces';

const compatibleVersionTimestamp = new Date('2021/04/15').getTime();

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16, // anders lösen, in Einstellungen
    },
  },
}));

function ResetWerte() {
  const classes = useStyles();

  const { currentDevice } = useContext(DeviceContext);
  const { serialNumber } = currentDevice;
  const active = currentDevice.serialNumber !== undefined;

  const [activated, setActivated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading, loadingRef] = useStateRef(false);
  const [error, setError] = useState(false);

  const [resetOk, setResetOk] = useState(false);

  const [offline, setOffline] = useState(true);
  const [rightVersion, setRightVersion] = useState(false);
  const totalHours = useRef(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const subGet = useRef<MqttSubscription | null>(null);
  const subLive = useRef<MqttSubscription | null>(null);

  function unSub() {
    subLive.current?.unsubscribe();
    subGet.current?.unsubscribe();
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActivated(event.target.checked);
    totalHours.current = 0;
    setOffline(true);
    setRightVersion(false);
    if (event.target.checked) {
      subGet.current = PubSub.subscribe(`$aws/things/EMI-LOG_${serialNumber}/shadow/get/accepted`).subscribe({
        next: ({ value }) => {
          if (value?.state?.reported?.status === 'offline') {
            setOffline(true);
          } else {
            setOffline(false);
          }

          if ((getVersionTimestamp(value) as number) > compatibleVersionTimestamp) {
            setRightVersion(true);
          } else {
            setRightVersion(false);
          }
          totalHours.current = value.state.reported.betriebsstunden
            + value.state.reported.ausfallstunden_gesamt;
        },
      });
      subLive.current = PubSub.subscribe(`$aws/things/EMI-LOG_${serialNumber}/shadow/update/accepted`).subscribe({
        next: ({ value }) => {
          const { reported } = value.state;
          if (reported?.status === 'offline') {
            setOffline(true);
          } else {
            setOffline(false);
          }
          if (
            reported.betriebsstunden + reported.ausfallstunden_gesamt < totalHours.current
            && loadingRef.current
          ) {
            setResetOk(true);
          }
        },
        error: (err) => console.error(err),
      });
      setTimeout(() => {
        PubSub.publish(`$aws/things/EMI-LOG_${serialNumber}/shadow/get`, {});
      }, 1000);
    } else {
      unSub();
    }
  };

  const initReset = async () => {
    setLoading(true);
    setError(false);
    try {
      await PubSub.publish(
        `$aws/things/EMI-LOG_${serialNumber}/shadow/update`,
        {
          state: {
            desired: {
              'reset-daten': true,
            },
          },
        },
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => function cleanUp() {
    unSub();
  }, []);

  function handleOpen() {
    setDialogOpen(true);
  }

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setDialogOpen(false);
  };

  useEffect(() => {
    const reset = async () => {
      try {
        setResetOk(false);
        const res = await API.post('resetDataLambda', '/', { body: { serialNumber } });
        if (res.statusCode !== 200) {
          setError(true);
          setLoading(false);
          console.error(res);
          return;
        }
        setSuccess(true);
        unSub();
        setLoading(false);
        setTimeout(() => {
          setActivated(false);
          handleClose(null, '');
        }, 2000);
      } catch (err) {
        setError(true);
        setLoading(false);
        console.error(err);
      }
    };

    if (resetOk) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOk]);

  useEffect(() => {
    setActivated(false);
    setOffline(true);
    setRightVersion(false);
  }, [serialNumber]);

  function renderTitle() {
    return (
      <span className={classes.titleWrapper}>
        <span>Reset Daten</span>
        <Feedback
          error={error}
          success={success}
          successText="Daten erfolgreich gelöscht"
        />
        <Feedback
          error={!rightVersion && !offline && activated}
          errorText="Softwareversion unterstützt die Funktion nicht"
          style={{ flex: '1 0', fontSize: '0.7rem' }}
        />
        <Feedback
          error={offline && active && activated}
          errorText="EMI-LOG ist offline"
          style={{ flex: '1 0', fontSize: '0.7rem' }}
        />
      </span>
    );
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={(
            renderTitle()
          )}
          action={(
            <Switch
              color="primary"
              checked={activated}
              onChange={handleChange}
              disabled={!active}
            />
          )}
        />
        <Divider />
        <CardActions>
          <LoaderButton
            isLoading={false}
            text="Stunden und Messwerte löschen"
            disabled={!activated || !active || offline || !rightVersion}
            onClick={handleOpen}
            fullWidth
          />
        </CardActions>
      </Card>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          Daten der EMI-LOG
          {' '}
          {serialNumber}
          {' '}
          löschen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sind Sie sich sicher, dass Sie die Daten der EMI-LOG entfernen wollen?
            Dies löscht die die aufgezeichneten Betriebs- und Ausfallstunden und alle HMW.
          </DialogContentText>

          <Feedback
            error={error}
            success={success}
            successText="Daten erfolgreich gelöscht"
            style={{ marginLeft: 0 }}
          />
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={handleClose} disabled={loading}>Abbrechen</Button>
          <LoaderButton
            isLoading={loading}
            onClick={initReset}
            text="Bestätigen"
            fullWidth={false}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResetWerte;
