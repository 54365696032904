import React, { useEffect, useContext } from 'react';
import DeviceContext from '../../context/DeviceContext';

import DashboardTable from './DashboardTable';

export default function Dashboard() {
  const { setCurrentDevice } = useContext(DeviceContext);
  useEffect(() => {
    document.title = 'Übersicht - EMI-LOG Adminportal';
    setCurrentDevice({});
  }, []);

  return (
    <DashboardTable />
  );
}
