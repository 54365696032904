import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import API from '@aws-amplify/api';
import convertTimestamp from '../../../utils/convertTimestamp';

interface ProtocolViewProps {
  handleMenuOpen: Function
  serialNumber: string
  isEmicon?: boolean
}

interface ProtocolEntry {
  timestamp: number
  message: string
}

export default function ProtocolView({ handleMenuOpen, serialNumber, isEmicon = false }: ProtocolViewProps) {
  const [data, setData] = useState<ProtocolEntry[]>([]);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setLoading(true);
      const res = await API.get('emilog', 'protocol', {
        queryStringParameters: {
          serialNumber,
          amount: 250,
          format: 'array',
          isEmicon
        },
      });
      setLoading(false);
      setData(res);
    } catch (err) {
      console.log(err);
    }
  }, [serialNumber]);

  useEffect(() => {
    setData([]);
    if (typeof serialNumber !== 'undefined') {
      fetch();
    }
  }, [serialNumber, fetch]);

  const renderCardTitle = () => (
    <>
      {/* @ts-ignore */}
      <ButtonBase onClick={handleMenuOpen}>
        <Typography variant="h5">Protokoll</Typography>
        <KeyboardArrowDownIcon />
      </ButtonBase>
    </>
  );

  const renderRefreshButton = () => (
    <Button variant="outlined" onClick={fetch}>
      <RefreshIcon />
    </Button>
  );

  return (
    <>
      <CardHeader
        title={(renderCardTitle())}
        action={
          renderRefreshButton()
        }
      />
      <Divider />
      <CardContent>
        {loading === true ? (
          <>
            <Skeleton key="1" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="2" style={{ marginBottom: 10 }} variant="text" />
            <Skeleton key="4" style={{ marginBottom: 10 }} variant="text" />
          </>
        ) : (
          <Grid container spacing={1}>
            <>
              <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                Zeit
              </Grid>
              <Grid item xs={8} style={{ fontWeight: 'bold' }}>
                Meldung
              </Grid>
            </>
            {data.map((entry) => (
              <React.Fragment key={`${entry.timestamp}-${entry.message}`}>
                <Grid item xs={4}>
                  {convertTimestamp(entry.timestamp)}
                </Grid>
                <Grid item xs={8}>
                  {entry.message}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}

      </CardContent>
    </>

  );
}
