import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
import { EmiconShadow } from '../../../../types/Shadow';
import { CardContent, FormControl, LinearProgress, MenuItem, Select, Switch, Theme } from '@material-ui/core';
// import { API } from '@aws-amplify/api';
// import { Gasart } from '../../../../types/Interfaces';
// import useStateRef from 'react-usestateref';
// import LoaderButton from '../../../emilog/components/LoaderButton';
// import EmiconModBus from './EmiconModBus';

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  categoryHeader: {
    fontWeight: 900,
  },

  loaderContainer: {
    textAlign: 'center',
    marginTop: 64,
  },

  unit: {
    color: 'rgba(0, 0, 0, 0.4)',
  },

  card: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },

  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },

  '@keyframes loadingBlink': {
    // from: { opacity: 1 },
    // to: { opacity: 0 },
    '50%': {
      opacity: 0.1,
    },
  },

  fetchLiveDataButton: {
    animationName: '$loadingBlink',
    animationDuration: '2s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  },

  shadowInput: {
    fontSize: '13px',
    margin: '2px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputForm: {
    fontSize: '13px',
    height: '30px',
    '&.Mui-disabled': {
      color: 'black',
    },
    '&.MuiOutlinedInput-input': {
      padding: '0px !important',
    },
  },

  shadowInputSm: {
    width: '100px',
  },

  shadowInputXSm: {
    width: '115px',
  },

  shadowInputMd: {
    width: '150px',
  },

  shadowInputLg: {
    width: '175px',
  },

  shadowInputXl: {
    width: '200px',
  },

  shadowInputXxl: {
    width: '250px',
  },

  shadowInputDark: {
    background: '#014a69',
    color: 'white !important'
  },

  dropdown: {
    width: 150,
    marginTop: -8,
    [theme.breakpoints.up('sm')]: {
      marginBottom: -8,
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      fontSize: '13px',
      margin: '2px',
      height: '30px',
      paddingLeft: '8px !important',
      '&.Mui-disabled': {
        color: 'black',
      },
    },
  },

  select: {
    paddingLeft: 8,
  },

  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
    paddingLeft: '0px', // Linker Text unter Input Elementen
  },

  cardContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',  // Align items to the right
    alignItems: 'center',
    paddingRight: '8px', // Default Root von Card ist 16px. Margin Container von 8px + efault 16px = 24px. 8px hier um wieder auf 16px zu kommen.
  },

  boxCenter: {
    display: 'flex',
    justifyContent: 'space-between',  // Align items to the right
    alignItems: 'center',
  }

}));

interface EmiconGrenzwerteProps {
  serialNumber: string
  active: boolean
  handleMenuOpen: Function
  updateReportedShadow: Partial<EmiconShadow> | null
  updateShadow: Function
  updateReported: Function
  loading: boolean
  notification: string
  notificationSuccess: string
  handleBlurForceZero: Function
  setUpdateReportedShadow: Function
  liveShadow?: boolean
}

export default function EmiconGrenzwerte({
  serialNumber,
  active,
  handleMenuOpen,
  updateReportedShadow,
  updateShadow,
  updateReported,
  loading,
  notification,
  notificationSuccess,
  handleBlurForceZero,
  setUpdateReportedShadow,
  liveShadow
}: EmiconGrenzwerteProps) {

  const classes = useStyles();

  const ShadowGasart = updateReportedShadow?.sGasArt || '';

  const [gasArten, setGasarten] = useState<string[]>(['Erdgas', 'Klaergas', 'Biogas', 'Grubengas', 'Deponiegas', 'Holzgas', 'Diesel']);

  // Ensure ShadowGasart is unique (no duplicate if it exists in predefinedGasarten)
  useEffect(() => {
    if (ShadowGasart && !gasArten.includes(ShadowGasart)) {
      setGasarten([ShadowGasart, ...gasArten]);
    } else {
      setGasarten(gasArten); // Use predefined if ShadowGasart is empty or already exists
    }
  }, [ShadowGasart, gasArten]);

  const handleSwitch = (key: keyof EmiconShadow) => {
    setUpdateReportedShadow((prevState: EmiconShadow) => ({
      ...prevState,
      [key]: typeof prevState[key] === 'boolean' ? !prevState[key] : prevState[key]
    }));
  };

  return (
    <Card className={classes.card} style={{ maxHeight: 1000, minHeight: "100%" }}>

      <CardHeader
        title={(
          <div className={classes.cardHeader}>
            {/* @ts-ignore */}
            <ButtonBase onClick={handleMenuOpen}>
              <Typography variant="h5">Standardwerte</Typography>
              <KeyboardArrowDownIcon />
            </ButtonBase>
          </div>
        )}
        style={{ position: 'relative' }}
      />

      <Divider />

      {serialNumber && active && updateReportedShadow && Object.keys(updateReportedShadow).length > 0 ? (
        <Box margin="16px 8px 16px 8px" maxHeight={660}>

          {/* SERIENNUMMER */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Seriennummer"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.sSeriennummer ?? 'Unbekannt'}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'sSeriennummer')}
            />
          </Box>

          {/* LEISTUNG MOTOR */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="EI Leistung Motor"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_EnPel}
              endAdornment={<InputAdornment position="end">kW</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_EnPel')}
              onBlur={() => handleBlurForceZero('Var_EnPel')}
            />
          </Box>

          {/* MOTORLAST-MIN */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Einschaltbedingung Motorlast"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_EnLoad_Min}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_EnLoad_Min')}
              onBlur={() => handleBlurForceZero('Var_EnLoad_Min')}
            />
          </Box>

          {/* DOSIERVOLUMEN */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Maximales Dosiervolumen"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_V_UWS_Max}
              endAdornment={<InputAdornment position="end">l/h</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_V_UWS_Max')}
              onBlur={() => handleBlurForceZero('Var_V_UWS_Max')}
            />
          </Box>

          {/* HARNSTOFFANTEIL */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Harnstoffanteil in HWL"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_MassFrac_Urea}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_MassFrac_Urea')}
              onBlur={() => handleBlurForceZero('Var_MassFrac_Urea')}
            />
          </Box>

          {/* NOX-ZIELWERT */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="NOx-Zielwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_NOx_Target_mgO2}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_NOx_Target_mgO2')}
              onBlur={() => handleBlurForceZero('Var_NOx_Target_mgO2')}
            />
          </Box>

          {/* GASART */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Gasart"
              aria-describedby="outlined-weight-helper-text"
            />
            <FormControl
              variant="outlined"
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={updateReportedShadow.sGasArt || ""}
                onChange={(e) => updateReported(e, 'sGasArt')}
                disabled={liveShadow}
                className={`${classes.shadowInputForm} ${classes.shadowInputXxl}`}
              >
                {gasArten.map((gasart, index) => (
                  <MenuItem key={index} value={gasart}>
                    {gasart}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* BEZUG-SAUERSTOFF */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Bezugs Sauerstoff"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_RelO2}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_RelO2')}
              onBlur={() => handleBlurForceZero('Var_RelO2')}
            />
          </Box>

          {/* NOX-WARN */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="NOx-Warnwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_nox_warn}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_nox_warn')}
              onBlur={() => handleBlurForceZero('Var_nox_warn')}
            />
          </Box>

          {/* NOX-ALARM */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="NOx-Alarmwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_nox_alarm}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_nox_alarm')}
              onBlur={() => handleBlurForceZero('Var_nox_alarm')}
            />
          </Box>

          {/* CO-WARN */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="CO-Warnwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_co_warn}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_co_warn')}
              onBlur={() => handleBlurForceZero('Var_co_warn')}
            />
          </Box>

          {/* CO-ALARM */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="CO-Alarmwert"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_co_alarm}
              endAdornment={<InputAdornment position="end">mg/Nm³</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_co_alarm')}
              onBlur={() => handleBlurForceZero('Var_co_alarm')}
            />
          </Box>

          {/* TEMP-MIN */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Temperatur-Minimum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_t_min}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_t_min')}
              onBlur={() => handleBlurForceZero('Var_t_min')}
            />
          </Box>

          {/* TEMP-MAX */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Temperatur-Maximum"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_t_max}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_t_max')}
              onBlur={() => handleBlurForceZero('Var_t_max')}
            />
          </Box>

          {/* TEMP-Alarm */}
          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Temperatur-Alarm"
              aria-describedby="outlined-weight-helper-text"
            />
            <OutlinedInput
              disabled={liveShadow}
              inputProps={{ style: { paddingLeft: 8 }, type: 'number' }}
              className={`${classes.shadowInput} ${classes.shadowInputXxl}`}
              value={updateReportedShadow.Var_t_alarm}
              endAdornment={<InputAdornment position="end">°C</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              onChange={(e) => updateReported(e, 'Var_t_alarm')}
              onBlur={() => handleBlurForceZero('Var_t_alarm')}
            />
          </Box>

          <Box>
            <OutlinedInput
              disabled
              inputProps={{ style: { paddingLeft: 8 } }}
              className={`${classes.shadowInput} ${classes.shadowInputDark} ${classes.shadowInputXxl}`}
              value="Parameter über Modbus"
              aria-describedby="outlined-weight-helper-text"
            />
            <Switch
              color="primary"
              checked={updateReportedShadow.Parametrierung_Modbus_aktivieren}
              onChange={() => handleSwitch('Parametrierung_Modbus_aktivieren')}
              disabled={!active}
            />
          </Box>

          <CardContent className={notification === '' && notificationSuccess === '' ? classes.cardContentRight : classes.cardContent}>
            {notification && <Typography style={{ fontSize: '16px' }} color="error">{notification}</Typography>}
            {notificationSuccess && <Typography style={{ fontSize: '16px', color: "green" }}>{notificationSuccess}</Typography>}
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={!active || !serialNumber || liveShadow}
              onClick={() => updateShadow()}
            >
              Absenden
            </Button>
          </CardContent>
          {loading ? (
            <LinearProgress />
          ) : (
            null
          )}
        </Box>
      ) : (
        <Box margin="16px 8px 16px 8px">
          <Skeleton style={{ transform: 'scale(1, 1)', marginBottom: 10 }} height={660} />
        </Box>
      )
      }
    </Card >
  );
}
