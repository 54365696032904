import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../types/Interfaces';
import EmiconContext, { EmiconContextProps } from '../emicon/context/EmiconContext';
import DeviceContext, { ContextProps } from '../emilog/context/DeviceContext';

export function useRouting(site: string, isEmicon: boolean): EmiconContextProps;
export function useRouting(site: string, isEmicon: string): ContextProps;

export function useRouting(site: string, isEmicon?: any): EmiconContextProps | ContextProps {
  const context = isEmicon === true ? EmiconContext : DeviceContext;
  const {
    currentDevice, devices, setCurrentDevice, loading, fetchDevices, setLoading,
    // @ts-ignore
  } = useContext<EmiconContextProps | ContextProps>(context);

  const { serialNumber: routeSerial } = useParams<PathParams>();
  useEffect(() => {
    document.title = `${site} - EMI-${isEmicon === true ? 'CON' : 'LOG'} Adminportal`;
    if (routeSerial === undefined) {
      setCurrentDevice({});
      return;
    }
    devices.forEach((device: any) => {
      if (device.serialNumber === routeSerial) {
        setCurrentDevice(device);
        document.title = `${site} ${routeSerial} - EMI-${isEmicon === true ? 'CON' : 'LOG'} Adminportal`;
      }
    });
  }, [routeSerial, devices, setCurrentDevice, site, isEmicon]);
  return {
    currentDevice, devices, loading, setCurrentDevice, fetchDevices, setLoading,
  };
}
