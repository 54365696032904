import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import API from '@aws-amplify/api';
import LoaderButton from './LoaderButton';

interface ProtocolDialogProps {
  onClose: Function
  serialNumber: string
  open: boolean
}

function ProtocolDialog({
  onClose, serialNumber, open,
}: ProtocolDialogProps) {
  const [amount, setAmount] = useState(undefined);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (_event: any, reason: string) => {
    if (reason === 'backdropClick' && loading) {
      return;
    }
    setNoData(false);
    onClose();
  };

  const handleSubmit = async () => {
    const filename = `EMI-LOG_${serialNumber}-Protokoll`;
    try {
      let res;
      setLoading(true);
      setError(false);
      setNoData(false);
      if (typeof amount === 'undefined' || amount === '') {
        res = await API.get('emilog', 'protocol', { // war vorher protocolLambda
          queryStringParameters: {
            serialNumber,
            timezone: new Date().getTimezoneOffset(),
          },
        });
      } else {
        res = await API.get('emilog', 'protocol', {
          queryStringParameters: {
            serialNumber,
            amount,
            timezone: new Date().getTimezoneOffset(),
          },
        });
      }
      const data = res;
      setLoading(false);
      setError(false);
      setNoData(false);
      // @ts-ignore
      if (navigator.msSaveBlob) { // IE 10+
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        // @ts-ignore
        navigator.msSaveBlob(blob, `${filename}.csv`);
      } else {
        const uri = `data:text/csv;charset=utf-8,${escape(data)}`;
        const link = document.createElement('a');
        link.href = uri;
        link.download = `${filename}.csv`;
        link.click();
      }
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === 404) {
        setNoData(true);
      } else {
        setError(true);
      }
    }
  };

  function validNumber(input: any) {
    return input.replace(/[^0-9]/g, '');
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Protokoll-Download für EMI-LOG
        {' '}
        {serialNumber}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Geben sie optional eine gewünschte Anzahl an Einträgen an. Ist keine Anzahl angegeben,
          werden alle verfügbaren Daten heruntergeladen.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Anzahl Einträge"
              value={amount !== undefined ? amount : ''}
              onChange={(event) => setAmount(validNumber(event.target.value))}
            />
          </Grid>
          {error ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography color="error">Etwas ist schiefgelaufen</Typography>
            </Grid>
          ) : null}
          {noData ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography color="error">Keine Daten gefunden</Typography>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* @ts-ignore */}
        <Button onClick={handleClose} disabled={loading}>Abbrechen</Button>
        <LoaderButton
          isLoading={loading}
          onClick={handleSubmit}
          text="Download"
          fullWidth={false}
          variant="text"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ProtocolDialog;
