export function roundShadowValueNoString(value: number | undefined | null, decimals: number): number | null {
  if (value === undefined || value === null || Number.isNaN(value)) {
    return null;
  }

  switch (decimals) {
    case 0:
      return Math.round(value);
    case 1:
      return Number((Math.round(value * 10) / 10).toFixed(decimals));
    case 2:
      return Number((Math.round(value * 100) / 100).toFixed(decimals));
    case 3:
      return Number((Math.round(value * 1000) / 1000).toFixed(decimals));
    default:
      return value;
  }
}
