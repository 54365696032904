import { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { API } from '@aws-amplify/api';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import convertDate from '../../../utils/convertDate';
import Feedback from '../../../emilog/components/Feedback';
import LoaderButton from '../../../emilog/components/LoaderButton';
import capitalize from '../../../utils/capitalize';
import AuthContext from '../../../emilog/context/AuthContext';

const timeframe = 320 * 24 * 60 * 60;

const useStyles = makeStyles((theme) => ({
  // cardContent: {
  //   flex: 1,
  // },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Make children elements stretch to fill the width
    flexGrow: 1, // Allows cardContent to expand
  },
  datePicker: {
    marginTop: 0,
    width: '100%',
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    // fontWeight: 600,
  },
  cardActions: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  // cardMargin: { // Nur bei Karten die Standalone sind, also nicht in nem Dropdown auswählbar
  //   marginBottom: theme.spacing(2),
  // },
}));

interface EmiconWartungProps {
  serialNumber: string,
  hasWartungPlanned: boolean | null
  lastWartungPlannedDate: number | null
  fetchDevices: Function
}

export default function EmiconWartung({ serialNumber, hasWartungPlanned, lastWartungPlannedDate, fetchDevices }: EmiconWartungProps) {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [firstName, lastName] = currentUser?.user.attributes.email.split('@')[0].split('.') ?? ['', ''];
  const author = `${capitalize(firstName)} ${capitalize(lastName)}`;
  const [onlyOneEntry, setOnlyOneEntry] = useState(false);
  const [letzteWartung, setLetzteWartung] = useState<number | undefined>(undefined);
  const [notificationDate, setNotificationDate] = useState<number | undefined | null>(null);
  const [newDate, setNewDate] = useState<MaterialUiPickersDate | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      setError(false);
      setSuccess(false);

      const timestamp = newDate ? Math.round(newDate.getTime() / 1000) : undefined;

      await API.post('emilog', 'addWartung', {
        body: {
          serialNumber,
          timestamp: timestamp ?? null,
        },
      });

      /* Schließt Wartung ab, indem der Timestamp auf 0 gesetzt wird */
      if (hasWartungPlanned) {
        await API.post('emilog', 'updateInfos', {
          body: {
            serialNumber: serialNumber,
            isEmicon: true, // ACHTUNG
            fields: {
              WartungGeplant: 0, // Aktuelle Wartung
              WartungGeplant_Alt: lastWartungPlannedDate, // Vorherige Wartung
              letzteWartung: timestamp, // Abschluss der vorherigen bzw. noch aktuellen wartung Wartung
            },
          },
        });

        let message = '';

        if (lastWartungPlannedDate) {
          message = `Wartung abgeschlossen (${ timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin der Wartung: ${convertDate(lastWartungPlannedDate)}`;
        } else {
          message = `Wartung abgeschlossen (${ timestamp !== undefined ? convertDate(timestamp) : 'Kein Gültiges Datum'}). Zieltermin nicht verfügbar`;
        }

        await API.post('emilog', 'addLogbookEntry', {
          body: {
            serialNumber: serialNumber,
            author,
            messageContent: message,
            WartungAbgeschlossen: true,
          },
        });
      }

      fetchDevices();
      setOnlyOneEntry(false);
      setLetzteWartung(timestamp);
      setNotificationDate(undefined);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  }


  useEffect(() => {
    async function fetchData() {
      try {
        const res = await API.get('emilog', 'getWartungen', { queryStringParameters: { serialNumber } }); // TODO in CRON Job Lambda
        setOnlyOneEntry(res.singleEntry ?? false);
        setLetzteWartung(res.Zeit);
        setNotificationDate(res.Meldung);
      } catch (error: any) {
        setOnlyOneEntry(false);
        setLetzteWartung(undefined);
        setNotificationDate(null);
      }
    }
    setOnlyOneEntry(false);
    setLetzteWartung(undefined);
    setNotificationDate(null);
    if (serialNumber) {
      fetchData();
    }
  }, [serialNumber]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Wartung abschließen"
      />
      <Divider />
      <CardContent className={classes.cardContent}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                value={newDate}
                label="Durchgeführte Wartung"
                className={classes.datePicker}
                disableToolbar
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                onChange={(e) => setNewDate(e)}
                invalidDateMessage="Kein korrektes Format"
                inputVariant="outlined"
                disabled={serialNumber === undefined}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Letzte Wartung:

              {letzteWartung && (
                <>
                  {' '}
                  {convertDate(letzteWartung)}
                  {' '}
                  {onlyOneEntry && '(Instandsetzung)'}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Benachrichtigung versendet:

              {notificationDate ? (
                ` Ja (am ${convertDate(notificationDate)})`
              ) : notificationDate === null ? (
                ''
              ) : (
                ` Nein (fällig am ${convertDate(letzteWartung! + timeframe)})`
              )}
            </Typography>
          </Grid>

          {(hasWartungPlanned && hasWartungPlanned === true) && (
            <Grid item xs={12}>
              <Typography>
                Aktuell geplante Wartung bis zum: {lastWartungPlannedDate !== null ? convertDate(lastWartungPlannedDate) : ''}
              </Typography>
            </Grid>
          )}

        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Feedback error={error} success={success} />
        <LoaderButton isLoading={loading} text="Wartung abschließen" onClick={handleSubmit} disabled={serialNumber === undefined || !newDate || hasWartungPlanned === false} />
      </CardActions>
    </Card>

  );
}

// TODO: Disabled
