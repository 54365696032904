import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Search as SearchIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import AddDeviceDialog from '../../../emilog/components/Dashboard/AddDeviceDialog';
import SearchContext from '../../../emilog/context/SearchContext';
import { Device } from '../../../../types/Device';
import EmiconContext from '../../context/EmiconContext';
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { AvailableColumns } from '../../../../types/Interfaces';


const useToolbarStyles = makeStyles((theme) => ({
  toolbarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 52,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  filterMenu: {
    padding: theme.spacing(2),
    maxWidth: '50vh',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  checkboxLabel: {
    fontSize: '1rem',
  },
  filterIcon: {
    padding: 4,
  },
  addEmilog: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  leftControls: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}));

interface EmiconTableToolbarProps {
  devices: Device[]
  handleColumnChange: Function
  columns: AvailableColumns
}

export default function EmiconTableToolbar({
  devices, handleColumnChange, columns,
}: EmiconTableToolbarProps) {
  const classes = useToolbarStyles();
  const { filter, setFilter, filter2, setFilter2 } = useContext(SearchContext);
  const { fetchDevices } = useContext(EmiconContext);

  const [open, setOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState(null);


  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    fetchDevices();
    handleClose();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    handleColumnChange(event.target);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleSearch2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter2(event.target.value);
  };

  const openFilterMenu = (event: any) => {
    setMenuAnchor(event.currentTarget);
  };

  function renderLabel(value: string) {
    switch (value) {
      case 'status':
        return 'Status';
      case 'AnlagenStatus':
        return 'Bearbeitungsstatus';
      case 'ServiceGeplant':
        return 'Service geplant';
      case 'WartungGeplant':
        return 'Wartung geplant';
      case 'letzterService':
        return 'Letzter durchgeführter Service';
      case 'letzteWartung':
        return 'Letzte durchgeführte Wartung';
      case 'Messtermin_Alt':
        return 'Messtermin (alt)';
      case 'ausfallstundenPercent':
        return 'Ausfallstunden (Jahr/% - Klassierung)';
      case 'ausfalltagePercent':
        return 'Ausfalltage (Jahr/% - Klassierung)';
      case 'Ausfallstunden':
        return 'Ausfallstunden (Jahr - Klassierung)';
      case 'DaysWithoutData':
        return 'Tage ohne Daten (60)';
      case 'SoftwareVersion':
        return 'Software Version';
      case 'ServiceVertragsende':
        return 'Ende Servicevertrag';
      case 'ausfallstunden':
        return 'Ausfallstunden';
      default:
        return value;
    }
  }

  return (
    <Toolbar className={classes.toolbarRoot}>
      <div className={classes.leftControls}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <SearchIcon />
          </Grid>
          <Grid item>
            <TextField
              label="Suche"
              onChange={handleSearch}
              value={filter}
            />
          </Grid>


          {filter !== '' ? (
            <Grid item>
              <TextField
                label="Erweiterte Suche"
                onChange={handleSearch2}
                value={filter2}
              />
            </Grid>
          ) : (
            null
          )}

          <Grid item>
            <IconButton
              onClick={openFilterMenu}
              className={classes.filterIcon}
            >
              <FilterListIcon />
            </IconButton>
          </Grid>

        </Grid>
      </div>
      <div className={classes.addEmilog}>
        <div style={{ display: 'inline-block' }}>
          <Typography component="span" color="inherit">EMI-CON hinzufügen</Typography>
          <Tooltip title="EMI-CON hinzufügen">
            <IconButton aria-label="hinzufügen" onClick={handleOpen}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <AddDeviceDialog
        open={open}
        onClose={handleClose}
        rows={devices}
        onSubmit={handleSubmit}
        isEmicon
      />
      <Menu
        id="columnMenu"
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        classes={{
          paper: classes.filterMenu,
        }}
      >
        <FormControl>
          <FormLabel component="legend">Spalten</FormLabel>
          <FormGroup className={classes.checkboxGroup}>
            {Object.keys(columns).map((column) => (
              <FormControlLabel
                key={column}
                classes={{
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox size="small" checked={columns[column]} name={column} onChange={handleChange} />
                }
                label={renderLabel(column)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Menu>
    </Toolbar>
  );
}
