import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SetupHeader from '../../../emilog/components/SetupHeader';
import { useRouting } from '../../../utils/useRouting';
import ServiceGeplant from './EmiconServiceGeplant';
import EmiconWartung from './EmiconWartung';
import EmiconMessbericht from './EmiconMessbericht';
import { Box } from '@material-ui/core';
import EmiconServiceAbgeschlossen from './EmiconServiceAbgeschlossen';
import API from '@aws-amplify/api';
import { Emicon } from '../../../../types/Device';
import EmiconWartungGeplant from './EmiconWartungGeplant';
import { useEffect, useState } from 'react';
import EmiconMesstermin from './EmiconMesstermin';
import EmiconVertragsArten from './EmiconVertragsArten';
import EmiconVertragsEnde from './EmiconVertragsEnde';
import EmiconVertragsKommentar from './EmiconVertragsKommentar';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFilledInput-input': {
      padding: '6px 20px 6px 8px',
    },
    '.MuiCardHeader-action': {
      margin: 0,
    },
  },
  content: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  // container: {
  //   width: '80vw',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   [theme.breakpoints.down('md')]: {
  //     padding: 0,
  //     width: '90vw',
  //   },
  // },
  container: {
    width: '85vw',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  // doubleCard: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  // },
  expandingCard: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default function EmiconEinstellungen() {

  /* Service */

  const { currentDevice, setCurrentDevice, fetchDevices } = useRouting('Service', true); // TODO? Evtl. currentDevice an Komponenten rutnerreichen

  const classes = useStyles();

  const [hasWartungPlanned, setHasWartungPlanned] = useState<boolean | null>(null);
  const [lastWartungPlannedDate, setLastWartungPlannedDate] = useState<number | null>(null);

  const [hasServicePlanned, setHasServicePlanned] = useState<boolean | null>(null);
  const [lastServicePlannedDate, setLastServicePlannedDate] = useState<number | null>(null);

  async function checkHasActiveWartung() {
    if (currentDevice.serialNumber === null || currentDevice.serialNumber === undefined) {
      return;
    }
    const result = await API.post('emilog', 'hasServicePlanned', {
      body: {
        serialNumber: currentDevice.serialNumber,
        isEmicon: true,
        isWartung: true // ACHTUNG
      },
    });
    setHasWartungPlanned(result.hasWartungPlanned ?? false);
    setLastWartungPlannedDate(result.lastWartungPlannedDate ?? 0);
  }

  async function checkHasActiveService() {
    if (currentDevice.serialNumber === null || currentDevice.serialNumber === undefined) {
      return;
    }
    const result = await API.post('emilog', 'hasServicePlanned', {
      body: {
        serialNumber: currentDevice.serialNumber,
        isEmicon: true,
      },
    });
    setHasServicePlanned(result.hasServicePlanned ?? false);
    setLastServicePlannedDate(result.lastServicePlannedDate ?? 0);
  }

  useEffect(() => {
    checkHasActiveWartung();
    checkHasActiveService();
  }, [currentDevice.serialNumber]);

  return (
    <Container className={classes.container} maxWidth="xl">
      <SetupHeader isEmicon hasBearbeitung={true} />
      <Grid container spacing={2} className={classes.content} justifyContent="center">

        <Grid item lg={6} className={classes.expandingCard}>

          <Grid container spacing={2} justifyContent="space-between">

            <Grid item xs={6}>
              <EmiconWartungGeplant hasWartungPlanned={hasWartungPlanned} lastWartungPlannedDate={lastWartungPlannedDate} />
            </Grid>

            <Grid item xs={6}>
              <EmiconWartung serialNumber={currentDevice.serialNumber} hasWartungPlanned={hasWartungPlanned} lastWartungPlannedDate={lastWartungPlannedDate} fetchDevices={fetchDevices} />
            </Grid>

            <Grid item xs={6}>
              <ServiceGeplant hasServicePlanned={hasServicePlanned} lastServicePlannedDate={lastServicePlannedDate} />
            </Grid>

            <Grid item xs={6}>
              <EmiconServiceAbgeschlossen serialNumber={currentDevice.serialNumber} hasServicePlanned={hasServicePlanned} lastServicePlannedDate={lastServicePlannedDate} />
            </Grid>

            <Grid item xs={6}>
              <EmiconMessbericht />
            </Grid>

            <Grid item xs={6}>
              <EmiconMesstermin
                serialNumber={currentDevice.serialNumber}
                isEmicon={true}
                currentDevice={currentDevice}
                setCurrentDevice={setCurrentDevice}
                fetchDevices={fetchDevices}
              />
            </Grid>


          </Grid>

        </Grid>



        <Grid item lg={6} className={classes.expandingCard}>

          <Grid container spacing={2} justifyContent="space-between">


            <Grid item xs={6}>
              <EmiconVertragsArten serialNumber={currentDevice.serialNumber} isEmicon={true} />
            </Grid>

            <Grid item xs={6}>
              <EmiconVertragsEnde serialNumber={currentDevice.serialNumber} isEmicon={true} />
            </Grid>

            <Grid item xs={12} >
              <EmiconVertragsKommentar serialNumber={currentDevice.serialNumber} isEmicon={true} />
            </Grid>

          </Grid>


        </Grid>

      </Grid>
    </Container >
  );
}
