function convertTimestamp(timestamp?: number | null, showSeconds = false) {
  if (timestamp === null || timestamp === undefined) return '';
  const time = new Date(timestamp * 1000).toLocaleDateString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: showSeconds ? '2-digit' : undefined,
  });
  return time;
}

export default convertTimestamp;
