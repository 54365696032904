import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const headCells = [
  {
    id: 'email', numeric: false, label: 'E-Mail',
  },
  {
    id: 'name', numeric: false, label: 'Name',
  },
  {
    id: 'institut', numeric: false, label: 'Institut',
  },
];

interface CalUserTableHeadProps {
  onRequestSort: Function
  order: SortingOrder
  orderBy: string
}

export default function CalUserTableHead({
  order, orderBy, onRequestSort,
}: CalUserTableHeadProps) {
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
