import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { AvailableColumns } from '../../../../types/Interfaces';
// import isEqual from 'react-fast-compare';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface HeadCell {
  id: string
  numeric: boolean
  label: string
}

const headCells: HeadCell[] = [
  {
    id: 'serialNumber', numeric: false, label: 'Seriennummer',
  },
  {
    id: 'Motorennummer', numeric: false, label: 'Motorennummer',
  },
  {
    id: 'Betreiber', numeric: false, label: 'Betreiber',
  },
  {
    id: 'Adresse', numeric: false, label: 'Adresse',
  },
  {
    id: 'Auftrag', numeric: false, label: 'Auftrag',
  },
  {
    id: 'Gasart', numeric: false, label: 'Gasart',
  },
  {
    id: 'status', numeric: false, label: 'Status',
  },
  {
    id: 'Servicevertrag', numeric: false, label: 'Servicevertrag',
  },
  {
    id: 'ServiceVertragsende', numeric: false, label: 'Ende Servicevertrag',
  },
  {
    id: 'AnlagenStatus', numeric: false, label: 'Bearbeitungsstatus', // Key, Anzeigename
  },
  {
    id: 'ServiceGeplant', numeric: false, label: 'Service geplant',
  },
  {
    id: 'WartungGeplant', numeric: false, label: 'Wartung geplant',
  },
  {
    id: 'letzterService', numeric: false, label: 'Letzter durchgeführter Service',
  },
  {
    id: 'letzteWartung', numeric: false, label: 'Letzte durchgeführte Wartung',
  },
  {
    id: 'Messtermin', numeric: false, label: 'Messtermin',
  },
  {
    id: 'Messtermin_Alt', numeric: false, label: 'Messtermin alt',
  },
  {
    id: 'ausfalltagePercent', numeric: false, label: 'Ausfalltage (Jahr/% - Klassierung)',
  },
  {
    id: 'ausfallstundenPercent', numeric: false, label: 'Ausfallstunden (Jahr/% - Klassierung)',
  },
  {
    id: 'ausfallstunden', numeric: false, label: 'Ausfallstunden (Jahr - Klassierung)',
  },
  {
    id: 'DaysWithoutData', numeric: false, label: 'Tage ohne Daten (60)',
  },
  {
    id: 'SoftwareVersion', numeric: false, label: 'Software Version',
  },
];

interface EmiconTableHeadProps {
  onRequestSort: Function
  order: 'asc' | 'desc'
  orderBy: string
  columns: AvailableColumns
}

export default function EmiconTableHead({
  onRequestSort, order, orderBy, columns,
}: EmiconTableHeadProps) {
  const classes = useStyles();

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          (columns[headCell.id] || headCell.id === 'serialNumber') ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            null
          )
        ))}
      </TableRow>
    </TableHead>
  );
}
